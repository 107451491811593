import React from 'react';

const Missions = () => {
  return (
    <div>
      <h1>This is the Missions page</h1>
    </div>
  );
};

export default Missions;
