import React, { useState } from 'react';
import {
  useIdeasListQuery,
  useIdeasSubmitMutation,
  useIdeasVoteMutation,
  useIdeasCommentMutation,
  useIdeasCommentsListQuery,
  useIdeasAdminActionMutation,
  useIdeasDeleteCommentMutation
} from '../../api/game';

import Response from '../../shared-components/response';
import { RoleGuard, ROLES } from '../../components/RoleGuard';

const IdeaModal = ({ idea, onClose, onVote, refetchIdeas }) => {
  const [comment, setComment] = useState('');
  const [postComment] = useIdeasCommentMutation();
  const [voteIdea] = useIdeasVoteMutation();
  const [adminAction] = useIdeasAdminActionMutation();
  const [deleteComment] = useIdeasDeleteCommentMutation();
  const {
    data: comments,
    isLoading,
    refetch,
  } = useIdeasCommentsListQuery(idea.uuid);
  const [userVoted, setUserVoted] = useState(false);
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  const handleAdminAction = async (action) => {
    try {
      const response = await adminAction({ 
        ideaId: idea.uuid, 
        action 
      }).unwrap();
      
      setAlertData({ 
        message: response.message, 
        type: 'success' 
      });

      // Refetch all idea lists
      refetchIdeas();
      
      // Close modal after short delay to show response
      setTimeout(() => {
        onClose();
      }, 1500);
      
    } catch (error) {
      setAlertData({
        message: error.data?.error || `Failed to ${action} idea`,
        type: 'error'
      });
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const response = await deleteComment({ commentId }).unwrap();
      setAlertData({ message: response.message, type: response.type });
      refetch();
    } catch (error) {
      setAlertData({
        message: error.data?.error || 'Failed to delete comment',
        type: 'error'
      });
    }
  };

  const handleCommentSubmit = async () => {
    try {
      await postComment({ ideaId: idea.uuid, content: comment });
      setComment('');
      refetch();
    } catch (error) {
      console.error('Error posting comment:', error);
    }
  };

  const handleVote = async vote => {
    if (!userVoted) {
      try {
        const response = await voteIdea({ ideaId: idea.uuid, vote }).unwrap();
        setUserVoted(true);
        onVote(idea.uuid, vote);
        setAlertData({ message: response.message, type: response.type });
      } catch (error) {
        console.error('Error voting:', error);
        setAlertData({
          message:
            error.data?.error || 'Failed to record vote. Please try again.',
          type: 'error',
        });
      }
    }
  };

  return (
    <div
      className="modal"
      style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header d-flex justify-content-between align-items-center">
            <h4 className="modal-title">{idea.title}</h4>
            <button type="button" className="btn-close ms-auto" onClick={onClose}>
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>{idea.content}</p>
            <Response message={alertData.message} type={alertData.type} />
            <div className="mt-3 row">
              <div className="col-6">
                <button
                  onClick={() => handleVote('yes')}
                  className="btn btn-success w-100 btn-lg"
                  disabled={userVoted}
                >
                  Vote Yes
                </button>
              </div>
              <div className="col-6">
                <button
                  onClick={() => handleVote('no')}
                  className="btn btn-danger w-100 btn-lg"
                  disabled={userVoted}
                >
                  Vote No
                </button>
              </div>
            </div>
            
            <h5 className="mt-4">Comments</h5>
            {isLoading ? (
              <p>Loading comments...</p>
            ) : (
              comments && 
              Object.values(comments)
                .filter(comment => comment.uuid)
                .map(comment => (
                  <div key={comment.uuid} className="card mt-2">
                    <div className="card-body">
                      <p>{comment.comment}</p>
                      <div className="d-flex justify-content-between align-items-center">
                        <small>By {comment.commenter.username} on {new Date(comment.createdAt).toLocaleString()}</small>
                        <RoleGuard roles={[ROLES.Admin, ROLES.Editor]}>
                          <button 
                            onClick={() => handleDeleteComment(comment.uuid)} 
                            className="btn btn-sm btn-danger"
                          >
                            Delete
                          </button>
                        </RoleGuard>
                      </div>
                    </div>
                  </div>
                ))
            )}
            <div className="mt-4">
              <textarea
                className="form-control"
                value={comment}
                onChange={e => setComment(e.target.value)}
                placeholder="Write a comment..."
              />
              <button
                className="btn btn-primary mt-2 w-100"
                onClick={handleCommentSubmit}
              >
                Post Comment
              </button>
            </div>

            <RoleGuard roles={[ROLES.Admin, ROLES.Editor]}>
              <div className="admin-controls mt-4 d-flex justify-content-center">
                <button onClick={() => handleAdminAction('delete')} className="btn btn-danger mx-2">
                  Delete
                </button>
                <button onClick={() => handleAdminAction('decline')} className="btn btn-warning mx-2">
                  Decline
                </button>
                <button onClick={() => handleAdminAction('progress')} className="btn btn-info mx-2">
                  Progress
                </button>
                <button onClick={() => handleAdminAction('made')} className="btn btn-success mx-2">
                  Mark Made
                </button>
              </div>
            </RoleGuard>
          </div>
        </div>
      </div>
    </div>
  );
};

const IdeaList = ({ status, onIdeaClick }) => {
  const { data: ideas, isLoading } = useIdeasListQuery(status);
  const ideaArray = ideas ? Object.values(ideas).filter(item => item.uuid) : [];

  if (isLoading) return <p>Loading...</p>;

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Title</th>
          <th>Author</th>
          <th>Votes</th>
        </tr>
      </thead>
      <tbody>
        {ideaArray.map(idea => (
          <tr
            key={idea.uuid}
            onClick={() => onIdeaClick(idea)}
            style={{ cursor: 'pointer' }}
          >
            <td>{idea.title}</td>
            <td>{idea.author.username}</td>
            <td>
              <span style={{ color: 'green' }}>{idea.yes_votes}</span> /{' '}
              <span style={{ color: 'red' }}>{idea.no_votes}</span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};


const SubmitIdea = ({ setAlertData, refetchIdeas  }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [submitIdea] = useIdeasSubmitMutation();

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      await submitIdea({ title, content }).unwrap();
      setAlertData({
        message: 'Idea submitted successfully!',
        type: 'success',
      });
      setTitle('');
      setContent('');
      refetchIdeas(); // Add this line to refresh the lists

    } catch (error) {
      setAlertData({
        message: 'Failed to submit idea. Please try again.',
        type: 'error',
      });
    }
  };

  return (
<div className="row justify-content-center">
  <div className="col-md-6">
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Submit an Idea</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={title}
              onChange={e => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="content">Idea:</label>
            <textarea
              className="form-control"
              id="content"
              value={content}
              onChange={e => setContent(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Post Idea
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

  );
};

const IdeaCentre = () => {
  const [activeTab, setActiveTab] = useState('pending');
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [selectedIdea, setSelectedIdea] = useState(null);
  const [voteIdea] = useIdeasVoteMutation();
  const [commentIdea] = useIdeasCommentMutation();

  // Add refetch queries for each status
  const { refetch: refetchPending } = useIdeasListQuery('0');
  const { refetch: refetchMade } = useIdeasListQuery('1');
  const { refetch: refetchProgress } = useIdeasListQuery('2');
  const { refetch: refetchDeclined } = useIdeasListQuery('3');

  const refetchIdeas = () => {
    refetchPending();
    refetchMade();
    refetchProgress();
    refetchDeclined();
  };

  const handleTabClick = tabName => {
    setActiveTab(tabName);
    setAlertData({ message: '', type: '' });
  };

  const handleIdeaClick = idea => {
    setSelectedIdea(idea);
  };

  const handleVote = async (ideaId, vote) => {
    await voteIdea({ ideaId, vote });
  };

  const handleComment = async (ideaId, content) => {
    await commentIdea({ ideaId, content });
  };

  return (
    <div>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a
            href="#pending-tab"
            onClick={() => handleTabClick('pending')}
            className={`nav-link ${activeTab === 'pending' ? 'active' : ''}`}
          >
            <span className="d-sm-none">Pending</span>
            <span className="d-sm-block d-none">Pending</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#made-tab"
            onClick={() => handleTabClick('made')}
            className={`nav-link ${activeTab === 'made' ? 'active' : ''}`}
          >
            <span className="d-sm-none">Made</span>
            <span className="d-sm-block d-none">Made</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#progress-tab"
            onClick={() => handleTabClick('progress')}
            className={`nav-link ${activeTab === 'progress' ? 'active' : ''}`}
          >
            <span className="d-sm-none">In Progress</span>
            <span className="d-sm-block d-none">In Progress</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#declined-tab"
            onClick={() => handleTabClick('declined')}
            className={`nav-link ${activeTab === 'declined' ? 'active' : ''}`}
          >
            <span className="d-sm-none">Declined</span>
            <span className="d-sm-block d-none">Declined</span>
          </a>
        </li>
      </ul>
      <div className="tab-content panel rounded-0 p-3 m-0">
        <div
          className={`tab-pane fade ${activeTab === 'pending' ? 'active show' : ''}`}
          id="pending-tab"
        >
          <IdeaList status="0" onIdeaClick={handleIdeaClick} />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'made' ? 'active show' : ''}`}
          id="made-tab"
        >
          <IdeaList status="1" onIdeaClick={handleIdeaClick} />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'progress' ? 'active show' : ''}`}
          id="progress-tab"
        >
          <IdeaList status="2" onIdeaClick={handleIdeaClick} />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'declined' ? 'active show' : ''}`}
          id="declined-tab"
        >
          <IdeaList status="3" onIdeaClick={handleIdeaClick} />
        </div>
      </div>
      <div className="mt-4">
        <Response message={alertData.message} type={alertData.type} />
        <SubmitIdea setAlertData={setAlertData} refetchIdeas={refetchIdeas} />

</div>
      {selectedIdea && (
        <IdeaModal
          idea={selectedIdea}
          onClose={() => setSelectedIdea(null)}
          onVote={handleVote}
          onComment={handleComment}
          refetchIdeas={refetchIdeas}
        />
      )}
    </div>
  );
};
export default IdeaCentre;
