import React, { useState } from 'react';
import {
  useBoatTheftListQuery,
  useBoatTheftCommitMutation,
} from '../../api/game';
import Response from '../../shared-components/response';
import { useGlobalState } from '../..//auth/globalState';

const BoatTheft = () => {
  const { data, isLoading, error } = useBoatTheftListQuery();
  const crimes = data ? 
    Object.values(data)
      .filter(item => item.uuid)
      .sort((a, b) => a.tier - b.tier) 
    : [];
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [selectedCrime, setSelectedCrime] = useState(null);
  const [commitBoatTheft] = useBoatTheftCommitMutation();
  const { updateTimer } = useGlobalState();

  const handleBoatTheftSelection = crimeUuid => {
    setSelectedCrime(crimeUuid);
  };

  const commitBta = async crime => {
    try {
      const response = await commitBoatTheft({ uuid: crime.uuid }).unwrap();
      setAlertData({ message: response.message, type: response.type });
      if (response.time) {
        updateTimer('bta', response.time);
      }
    } catch (error) {
      setAlertData({ message: 'An error occurred', type: 'error' });
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="row">
      <div className="col-lg-4"></div>
      <div className="col-lg-4">
        <Response message={alertData.message} type={alertData.type} />

        <div className="card">
          <div className="card-body">
            <h3 className="card-title text-center">Boat Theft</h3>
            <h5 className="card-subtitle mb-2 text-muted text-center">
              Select a boat to steal
            </h5>
            <div className="d-flex flex-column align-items-center">
              {crimes.map(crime => (
                <div key={crime.uuid} className="mb-3 position-relative">
                  <img
                    src={`/assets/img/boatTheft/${crime.imageTitle}.png`}
                    alt={crime.description}
                    onClick={() => handleBoatTheftSelection(crime.uuid)}
                    onDoubleClick={() => commitBta(crime)}
                    className={`clickable-image ${
                      selectedCrime === crime.uuid ? 'border-4 border-blue-500' : ''
                    } ${!crime.unlocked ? 'opacity-50' : ''}`}
                  />
                  {!crime.unlocked && (
                    <div className="position-absolute top-0 end-0 badge bg-danger">
                      Locked - Requires {(crime.tier - 1) * 30} successful thefts
                    </div>
                  )}
                  <span className="position-absolute top-0 start-0 badge bg-info">
                    {crime.minimumRank}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4"></div>
    </div>
  );
};

export default BoatTheft;
