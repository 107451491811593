import React from 'react';
import { Outlet } from 'react-router-dom';

import App from '../app.jsx';
import RequireAuth from '../auth/RequireAuth.js';
import { useGlobalState } from '../auth/globalState';
import Select from '../pages/auth/select';

//game routes
import Login from '../pages/auth/login.js';
import Register from '../pages/auth/register.js';
import ExtraError from '../pages/extra-error.js';
import Userprofile from '../pages/general/userProfile.js';
import Prison from '../pages/crime/prison.jsx';
import Dashboard from '../pages/general/dashboard.js';
import Travel from '../pages/general/travel.js';
import Finances from '../pages/general/finances.js';
import Directory from '../pages/general/directory.js';
import Vehicles from '../pages/marketplace/vehicles.js';
import BlackMarket from '../pages/marketplace/blackMarket.js';
import BoatTheft from '../pages/crime/boat_theft.jsx';
import AdvancedCrimes from '../pages/crime/advcrimes.jsx';
import Chase from '../pages/crime/chase.jsx';
import Crimes from '../pages/crime/crimes.jsx';
import Extortion from '../pages/crime/extortion.jsx';
import CarTheftPage from '../pages/crime/gta.jsx';
import Drugs from '../pages/crime/drugs.jsx';
import IdeaCentre from '../pages/messaging/ideaCentre.js';
import Forums from '../pages/messaging/forums.js';
import Inbox from '../pages/messaging/inbox.js';
import EditProfilePage from '../pages/misc/edit-profile.js';
import BarPage from '../pages/marketplace/bar.js';
import Properties from '../pages/properties/properties.js';
import Roulette from '../pages/properties/roulette.js';
import Slots from '../pages/properties/slots.js';
import Boxing from '../pages/general/boxing.js';
import Scratchcard from '../pages/properties/scratchcards.js';
import Dice from '../pages/properties/dice.js';
import Keno from '../pages/properties/keno.js';
import HeadsTails from '../pages/properties/headsTails.js';
import Lottery from '../pages/properties/lottery.js';
import PointsPage from '../pages/marketplace/points.js';
import Kill from '../pages/kill/kill.js';
import BulletFactory from '../pages/properties/bulletFactory.js';
import KevlarFactory from '../pages/properties/kevlarFactory.js';
import LifeInsurance from '../pages/marketplace/lifeInsurance.js';
import Family from '../pages/family/family.js';
import Missions from '../pages/crime/missions.jsx';
import OrganisedCrime from '../pages/crime/organisedCrime.jsx';
import Syndicates from '../pages/crime/syndicates.jsx';
import Encounters from '../pages/crime/encounters.jsx';
import Foundry from '../pages/kill/foundry.js';
import RussianRoulette from '../pages/kill/russianRoulette.js';
import Refinery from '../pages/marketplace/refinery.js';
import Items from '../pages/general/items.js';
import Beta from '../pages/general/beta.js';
import AdminPanel from '../pages/admin/panel.jsx';
import FamilyProfile from '../pages/family/familyProfile.js';


const CharacterCheck = ({ children }) => {
  const { globalState } = useGlobalState();

  if (globalState.character_uuid === null) {
    return <Select />;
  }

  return children;
};

const AppRoute = [
  {
    path: '*',
    element: <App />,
    children: [
      { path: '', element: <Login /> },
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      {
        path: 'game',
        element: <RequireAuth />,
        children: [
          {
            path: '',
            element: (
              <CharacterCheck>
                <Outlet />
              </CharacterCheck>
            ),
            children: [
              {
                path: 'general/*',
                element: <Outlet />,
                children: [
                  { path: 'beta', element: <Beta /> },
                  { path: 'dashboard', element: <Dashboard /> },
                  { path: 'travel', element: <Travel /> },
                  { path: 'finance', element: <Finances /> },
                  { path: 'player-directory', element: <Directory /> },
                  { path: 'items', element: <Items /> },
                  { path: 'properties', element: <Properties /> },
                  { path: 'userprofile/:username', element: <Userprofile /> },
                ],
              },
              {
                path: 'crime/*',
                element: <Outlet />,
                children: [
                  { path: 'bta', element: <BoatTheft /> },
                  { path: 'adv-crime', element: <AdvancedCrimes /> },
                  { path: 'chase', element: <Chase /> },
                  { path: 'crimes', element: <Crimes /> },
                  { path: 'extortion', element: <Extortion /> },
                  { path: 'gta', element: <CarTheftPage /> },
                  { path: 'drugs', element: <Drugs /> },
                  { path: 'prison', element: <Prison /> },
                  { path: 'encounters', element: <Encounters /> },
                  { path: 'syndicates', element: <Syndicates /> },
                  { path: 'missions', element: <Missions /> },
                  { path: 'organised-crime', element: <OrganisedCrime /> },
                  { path: '*', element: <ExtraError /> },
                ],
              },
              {
                path: 'messaging/*',
                element: <Outlet />,
                children: [
                  { path: 'idea-centre', element: <IdeaCentre /> },
                  { path: 'main-forum', element: <Forums /> },
                  { path: 'inbox', element: <Inbox /> },
                ],
              },
              {
                path: 'marketplace/*',
                element: <Outlet />,
                children: [
                  { path: 'black-market', element: <BlackMarket /> },
                  { path: 'vehicles', element: <Vehicles /> },
                  { path: 'bar', element: <BarPage /> },
                  { path: 'points', element: <PointsPage /> },
                  { path: 'life-insurance', element: <LifeInsurance /> },
                  { path: 'refinery', element: <Refinery /> },
                ],
              },
              {
                path: 'misc/*',
                element: <Outlet />,
                children: [
                  { path: 'profile-settings', element: <EditProfilePage /> },
                ],
              },
              {
                path: 'kill/*',
                element: <Outlet />,
                children: [
                  { path: 'kill', element: <Kill /> },
                  { path: 'bullet-factory', element: <BulletFactory /> },
                  { path: 'kevlar-factory', element: <KevlarFactory /> },
                  { path: 'foundry', element: <Foundry /> },
                  { path: 'russian-roulette', element: <RussianRoulette /> },
                ],
              },
              {
                path: 'family/*',
                element: <Outlet />,
                children: [
                  { path: 'family', element: <Family /> },
                  { path: 'famprofile/:family', element: <FamilyProfile /> },
                ],
                
              },
              {
                path: 'casino/*',
                element: <Outlet />,
                children: [
                  { path: 'roulette', element: <Roulette /> },
                  { path: 'slots', element: <Slots /> },
                  { path: 'boxing', element: <Boxing /> },
                  { path: 'scratchcards', element: <Scratchcard /> },
                  { path: 'lottery', element: <Lottery /> },
                  { path: 'dice', element: <Dice /> },
                  { path: 'heads-or-tails', element: <HeadsTails /> },
                  { path: 'keno', element: <Keno /> },
                ],
              },
              {
                path: 'admin/*',
                element: <Outlet />,
                children: [
                  { path: 'panel', element: <AdminPanel /> },
                ],
              },
            ],
          },
          { path: 'select', element: <Select /> },
          { path: '*', element: <ExtraError /> },
        ],
      },
      { path: '*', element: <Login /> },
    ],
  },
];

export default AppRoute;
