import React from 'react';

const YouAreInPrison = () => {
  return (
    <div
      className="prison-container"
      style={{ textAlign: 'center', padding: '20px' }}
    >
      <h2>You are currently in Prison</h2>
      <p>You cannot access this feature while incarcerated.</p>
    </div>
  );
};

export default YouAreInPrison;
