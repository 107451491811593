import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  usePropertyOwnershipCheckMutation,
  usePropertyDetailsQuery,
  usePlayRouletteMutation,
} from '../../api/game';
import PropertyPanel from './propertyPanel';
import Response from '../../shared-components/response';
import { formatNumber } from '../../shared-components/numberFormat';

const Roulette = () => {
  const { data: propertyDetails, isLoading, refetch } = usePropertyDetailsQuery('roulette');
  const [checkOwnership, { data: ownershipData }] = usePropertyOwnershipCheckMutation();
  const [bets, setBets] = useState({});
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [playRoulette, { data: rouletteResult }] = usePlayRouletteMutation();
  const [rltResult, setRltResult] = useState(null);

  const handlePropertyUpdate = async () => {
    await refetch();
    await checkOwnership({ property: 'roulette' });
  };

  const handleSubmit = async () => {
    const filteredBets = Object.fromEntries(
      Object.entries(bets).filter(([_, value]) => value !== '')
    );

    try {
      const result = await playRoulette({ bets: filteredBets }).unwrap();
      setRltResult(result);
      setAlertData({ message: result.message, type: result.type });
    } catch (error) {
      console.error('Error playing roulette:', error);
      setAlertData({ message: 'Error playing roulette', type: 'danger' });
    }
  };

  const rouletteNumbers = [
    [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36],
    [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35],
    [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34],
  ];

  const handleBetChange = (bet, value) => {
    setBets(prevBets => ({ ...prevBets, [bet]: value }));
  };

  useEffect(() => {
    checkOwnership({ property: 'roulette' });
  }, [checkOwnership]);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div>
      <Response
        message={alertData.message}
        type={alertData.type}
        rouletteResult={rouletteResult?.result}
      />
      <div className="tab-content panel rounded-0 p-3 m-0">
        {ownershipData?.isOwner ? (
          <PropertyPanel 
            property="roulette" 
            onPropertyUpdate={handlePropertyUpdate}
          />
        ) : propertyDetails.owner === null ? (
          <div>
            <p>
              Nobody owns this property. You can pick it up at the 
              <Link to="/game/general/properties">locations page</Link>.
            </p>
          </div>
        ) : (
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">Roulette</h3>
              <button onClick={handleSubmit} className="btn btn-primary mb-3">
                Place Bets
              </button>
              <div className="row">
                <div className="col-md-8">
                  <div className="roulette-grid d-flex mb-3">
                    {rouletteNumbers.map((column, columnIndex) => (
                      <div key={columnIndex} className="roulette-column mr-1">
                        {column.map(number => (
                          <input
                            key={number}
                            type="number"
                            className="form-control form-control-sm mb-1"
                            style={{ width: '105px' }}
                            value={bets[number] || ''}
                            onChange={e =>
                              handleBetChange(number, e.target.value)
                            }
                            placeholder={number}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                  <div className="additional-bets">
                    <div className="d-flex">
                      {[
                        ['1-12', '13-24', '25-36'],
                        ['1-18', '19-36', 'Red'],
                        ['Black', 'Odd', 'Even'],
                      ].map((column, columnIndex) => (
                        <div key={columnIndex} className="roulette-column mr-1">
                          {column.map(bet => (
                            <input
                              key={bet}
                              type="number"
                              className="form-control form-control-sm mb-1"
                              style={{ width: '105px' }}
                              placeholder={bet}
                              onChange={e =>
                                handleBetChange(bet, e.target.value)
                              }
                            />
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="roulette-info mt-3">
                <p>
                  Owned by:
                  {propertyDetails.owner ? (
                    <Link
                      to={`/game/general/userprofile/${propertyDetails.owner}`}
                    >
                      {propertyDetails.owner}
                    </Link>
                  ) : (
                    'None'
                  )}
                </p>
                <p>Max bet: {formatNumber(propertyDetails.maxBet)}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Roulette;