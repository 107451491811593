import React, { useState } from 'react';
import {
  useDrugsListQuery,
  useDrugsTransactionMutation,
  useSmuggleBoatsListQuery,
  useSmuggleCommitMutation,
} from '../../api/game';
import Response from '../../shared-components/response';
import './drugs.css';

const crewOptions = [
  { id: 1, title: 'Grizzled Old Men', price: 100000 },
  { id: 2, title: 'Unemployed Sailors', price: 125000 },
  { id: 3, title: 'Regular Sailors', price: 175000 },
  { id: 4, title: 'Ex-Navy', price: 300000 },
  { id: 5, title: 'Corrupt Navy', price: 400000 }
];



const Drugs = () => {
  const [activeTab, setActiveTab] = useState('drugs');
  const { data: drugsData, isLoading, isError, refetch } = useDrugsListQuery();
  const drugs = drugsData?.drugs ? Object.values(drugsData.drugs).filter(drug => drug.uuid) : [];
  const capacity = drugsData?.capacity || 0;  const [drugTransaction] = useDrugsTransactionMutation();
  const [startSmuggling] = useSmuggleCommitMutation();


  const { data: userBoatsData, isLoading: isLoadingBoats } = useSmuggleBoatsListQuery();
  const userBoats = userBoatsData?.boats ? Object.values(userBoatsData.boats).filter(boat => boat.uuid) : [];
  const locations = userBoatsData?.locations ? Object.values(userBoatsData.locations).filter(location => location.uuid) : [];
  const activeSmuggling = userBoatsData?.activeSmuggling;

  const [inputValues, setInputValues] = useState({});
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [selectedBoat, setSelectedBoat] = useState('');
  const [selectedCrew, setSelectedCrew] = useState('');
  const [selectedDrug, setSelectedDrug] = useState('');
  // Add new state for selected location
  const [selectedLocation, setSelectedLocation] = useState('');

  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };

  const handleInputChange = (drugUuid, value) => {
    // Calculate total from other input boxes
    const otherInputsTotal = Object.entries(inputValues)
      .filter(([uuid, _]) => uuid !== drugUuid)
      .reduce((sum, [_, amount]) => sum + (parseInt(amount) || 0), 0);
      
    // Limit new value so total inputs don't exceed capacity
    const limitedValue = Math.min(
      parseInt(value) || 0,
      capacity - otherInputsTotal
    );
  
    setInputValues(prev => ({
      ...prev,
      [drugUuid]: limitedValue >= 0 ? limitedValue : ''
    }));
  };
  

  const isBuyDisabled = () => {
    const totalInputAmount = Object.values(inputValues)
      .reduce((sum, amount) => sum + (parseInt(amount) || 0), 0);
    
    const availableSpace = capacity - totalCarried;
    return totalInputAmount > availableSpace;
  };
  
  const isSellDisabled = () => {
    // Check each drug to ensure we have enough to sell
    return drugs.some(drug => {
      const sellAmount = parseInt(inputValues[drug.uuid]) || 0;
      const heldAmount = drug.drugs_helds[0]?.amount || 0;
      return sellAmount > heldAmount;
    });
  };
  
  const handleTransaction = async type => {
    const transactions = Object.entries(inputValues)
      .filter(([_, value]) => value > 0)
      .map(([drugUuid, amount]) => ({
        drugUuid,
        amount: parseInt(amount),
        type,
      }));

    if (transactions.length > 0) {
      try {
        const result = await drugTransaction({ transactions }).unwrap();
        setInputValues({});
        setAlertData({ message: result.message, type: 'success' });
        refetch();
      } catch (error) {
        console.error('Transaction failed:', error);
        setAlertData({
          message: error.data?.message || 'Transaction failed',
          type: 'fail',
        });
      }
    }
  };

  const handleStartSmuggling = async () => {
    if (!selectedBoat || !selectedCrew || !selectedDrug || !selectedLocation) {
      setAlertData({
        message: 'Please select a boat, crew, drug and destination',
        type: 'fail',
      });
      return;
    }
  
    try {
      const result = await startSmuggling({
        boatUuid: selectedBoat,
        crewId: parseInt(selectedCrew), // Send crew ID instead of strength
        drugUuid: selectedDrug,
        amount: 100,
        destination: selectedLocation
      }).unwrap();
  
      setAlertData({ message: result.message, type: result.type });
    } catch (error) {
      setAlertData({
        message: error.data?.message || 'Failed to start smuggling operation',
        type: 'fail',
      });
    }
  };

  const calculateTotalPrice = type => {
    return Object.entries(inputValues)
      .reduce((total, [drugUuid, amount]) => {
        const drug = drugs.find(d => d.uuid === drugUuid);
        const price = drug?.drugs_prices[0]?.price || 0;
        return total + (parseInt(amount) || 0) * price;
      }, 0)
      .toLocaleString();
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading drugs list</div>;

  const totalCarried = drugs 
  ? Object.values(drugs)
      .filter(drug => drug.drugs_helds)
      .reduce((sum, drug) => sum + (drug.drugs_helds[0]?.amount || 0), 0)
  : 0;

  const maxCarry = 1000; // Replace with actual max carry amount

  return (
    <div>
      <Response message={alertData.message} type={alertData.type} />
      <div className="row">
        <div className="col-lg-1"></div>
        <div className="col-lg-10">
          <div className="family-tabs-container">
            <div className="tabs-wrapper">
              <ul className="nav nav-tabs flex-nowrap">
                <li className="nav-item">
                  <a
                    href="#drugs-tab"
                    onClick={() => handleTabClick('drugs')}
                    className={`nav-link ${activeTab === 'drugs' ? 'active' : ''}`}
                  >
                    Drugs
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#smuggling-tab"
                    onClick={() => handleTabClick('smuggling')}
                    className={`nav-link ${activeTab === 'smuggling' ? 'active' : ''}`}
                  >
                    Smuggling
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content panel rounded-0 p-3 m-0">
              <div
                className={`tab-pane fade ${activeTab === 'drugs' ? 'active show' : ''}`}
                id="drugs-tab"
              >
                <div className="carry-info text-center mb-3">
                You currently carry {totalCarried} amount, the maximum you can
                carry is {capacity} amount.
                </div>
                <div className="drugs-container">
                  {drugs.map((drug, index) => (
                    <div key={index} className="drug-item">
                      <img
                        src={drug.image ? `/assets/img/drugs/${drug.image}` : 'https://via.placeholder.com/400x100'}
                        alt={drug.title}
                        className="drug-image"
                      />
                      <div className="drug-title">{drug.title}</div>
                      <div className="drug-amount">{drug.drugs_helds[0]?.amount || 0}</div>
                      <div className="drug-price">£{drug.drugs_prices[0]?.price || 'N/A'}</div>
                      <input
                        type="number"
                        className="drug-quantity-input"
                        max="9999"
                        min="0"
                        value={inputValues[drug.uuid] || ''}
                        onChange={e => handleInputChange(drug.uuid, e.target.value)}
                        style={{
                          position: 'absolute',
                          bottom: '5px',
                          right: '5px',
                          width: '60px',
                          fontSize: '12px',
                          padding: '0',
                          margin: '0',
                          border: '1px solid #ccc',
                          textAlign: 'center',
                        }}
                      />
                    </div>
                  ))}
                </div>
                <div className="drug-actions text-center mt-3">
                  <button
                    className="btn btn-danger btn-sm"
                    style={{ marginRight: '25px' }}
                    onClick={() => handleTransaction('sell')}
                    disabled={isSellDisabled()}
                  >
                    Sell for £{calculateTotalPrice('sell')}
                  </button>
                  <button
                    className="btn btn-success btn-sm"
                    style={{ marginLeft: '25px' }}
                    onClick={() => handleTransaction('buy')}
                    disabled={isBuyDisabled()}
                  >
                    Buy for £{calculateTotalPrice('buy')}
                  </button>
                </div>
              </div>


<div
  className={`tab-pane fade ${activeTab === 'smuggling' ? 'active show' : ''}`}
  id="smuggling-tab"
>
{userBoatsData?.activeSmuggling ? (
  <div className="panel">
    <div className="smuggling-card">
      <div className="smuggling-header">
        <h3>
          <i className="fas fa-ship mr-2"></i>
          Active Smuggling Operation
        </h3>
      </div>
      
      <div className="smuggling-route">
        <div className="route-point">
          <span className="city">{userBoatsData.activeSmuggling.fromLocationData.city}</span>
          <i className="fas fa-arrow-right mx-3"></i>
          <span className="city">{userBoatsData.activeSmuggling.toLocationData.city}</span>
        </div>
      </div>

      <div className="smuggling-details">
        <div className="detail-row">
          <i className="far fa-clock"></i>
          <span>Arrives: {new Date(userBoatsData.activeSmuggling.arrivesAt).toLocaleString()}</span>
        </div>
      </div>
    </div>
  </div>
) : (
    <div className="panel">
      <h3>Start New Smuggling Operation</h3>
      <div className="form-group mb-4">
        <label htmlFor="boatSelect">Select a Vehicle:</label>
        <select
          className="form-control"
          id="boatSelect"
          value={selectedBoat}
          onChange={e => setSelectedBoat(e.target.value)}
        >
          <option value="">Select a boat</option>
          {userBoats && userBoats.map(boat => (
            <option key={boat.uuid} value={boat.uuid}>
              {boat.vehicle.title} ({boat.vehicle.type}) - Value: £{boat.value}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group mb-4">
        <label htmlFor="crewSelect">Hire a Crew:</label>
        <select
          className="form-control"
          id="crewSelect"
          value={selectedCrew}
          onChange={e => setSelectedCrew(e.target.value)}
        >
          <option value="">Select a crew</option>
          {crewOptions.map(crew => (
            <option key={crew.id} value={crew.id}>
              {crew.title} - £{crew.price.toLocaleString()}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group mb-4">
        <label htmlFor="locationSelect">Select Destination:</label>
        <select
          className="form-control"
          id="locationSelect"
          value={selectedLocation}
          onChange={e => setSelectedLocation(e.target.value)}
        >
          <option value="">Select a destination</option>
          {locations.map(location => (
            <option key={location.uuid} value={location.uuid}>
              {location.city}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group mb-4">
        <label htmlFor="drugSelect">Choose a Drug:</label>
        <select
          className="form-control"
          id="drugSelect"
          value={selectedDrug}
          onChange={e => setSelectedDrug(e.target.value)}
        >
          <option value="">Select a drug</option>
          {drugs &&
            drugs.map(drug => (
              <option key={drug.uuid} value={drug.uuid}>
                {drug.title} - £{drug.drugs_prices[0]?.price || 'N/A'}
              </option>
            ))}
        </select>
      </div>

      <div className="text-center mt-4">
        <button
          className="btn btn-primary"
          onClick={handleStartSmuggling}
        >
          Start Smuggling
        </button>
      </div>
    </div>
  )}
</div>

            </div>
          </div>
        </div>
        <div className="col-lg-1"></div>
      </div>
    </div>
  );
};

export default Drugs;
