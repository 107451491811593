import React, { useState } from 'react';
import { format } from 'date-fns';
import {
  useGetPointsDataQuery,
  useTransferPointsMutation,
  //useClaimPointsRecoveryMutation,
} from '../../api/game';

function GeneralTab() {
  return (
    <div>
      <h3 className="mt-10px">General Store</h3>
      <div className="list-group">
        <div className="list-group-item">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>Mission Skip</h5>
              <p className="mb-0">Skip current mission cooldown</p>
            </div>
            <button className="btn btn-primary">10 Points</button>
          </div>
        </div>
        <div className="list-group-item">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>Contract Skip</h5>
              <p className="mb-0">Skip current contract cooldown</p>
            </div>
            <button className="btn btn-primary">15 Points</button>
          </div>
        </div>
      </div>
    </div>
  );
}

function ItemsTab() {
  return (
    <div>
      <h3 className="mt-10px">Special Items</h3>
      <div className="row">
        <div className="col-md-4 mb-3">
          <div className="card">
            <div className="card-body text-center">
              <h5>Premium Blueprint</h5>
              <p>Unlock rare item crafting</p>
              <button className="btn btn-primary">50 Points</button>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="card">
            <div className="card-body text-center">
              <h5>Rare Weapon Kit</h5>
              <p>Special weapon upgrades</p>
              <button className="btn btn-primary">75 Points</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function GlobalBoostsTab() {
  return (
    <div>
      <h3 className="mt-10px">Global Boosts</h3>
      <div className="list-group">
        <div className="list-group-item">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>1 Hour Global Double XP</h5>
              <p className="mb-0">Boost XP for all players</p>
            </div>
            <button className="btn btn-primary">100 Points</button>
          </div>
        </div>
        <div className="list-group-item">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>2 Hours Factory Production</h5>
              <p className="mb-0">Double factory output</p>
            </div>
            <button className="btn btn-primary">150 Points</button>
          </div>
        </div>
      </div>
    </div>
  );
}

function FamilyTab() {
  return (
    <div>
      <h3 className="mt-10px">Family Boosts</h3>
      <div className="list-group">
        <div className="list-group-item">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>Worker Capacity Boost</h5>
              <p className="mb-0">Increase family worker limit</p>
            </div>
            <button className="btn btn-primary">200 Points</button>
          </div>
        </div>
      </div>
    </div>
  );
}

function ResetsTab() {
  return (
    <div>
      <h3 className="mt-10px">Reset Options</h3>
      <div className="list-group">
        <div className="list-group-item">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h5>Character Reset</h5>
              <p className="mb-0">Start fresh with bonus points</p>
            </div>
            <button className="btn btn-danger">Reset Character</button>
          </div>
        </div>
      </div>
    </div>
  );
}

function PointPanel() {
  const { data: pointsData } = useGetPointsDataQuery();

  return (
    <div>
      <table className="table">
        <tbody>
          <tr>
            <td align="center">Your Points:</td>
            <td align="center">{pointsData?.points?.toLocaleString() ?? 0}</td>
          </tr>
          <tr>
            <td align="center">Get Points:</td>
            <td align="center">
              <button className="btn btn-primary me-2">Bank Transfer</button>
              <button className="btn btn-primary">PayPal</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function PointSpentPanel() {
  const { data: pointsData } = useGetPointsDataQuery();

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <td align="center">ID</td>
            <td align="center">Spent On</td>
            <td align="center">Cost</td>
            <td align="center">Time</td>
          </tr>
        </thead>
        <tbody>
          {pointsData?.spentHistory?.map(item => (
            <tr key={item.id}>
              <td align="center">{item.id}</td>
              <td align="center">{item.used}</td>
              <td align="center">{item.points}</td>
              <td align="center">
                {format(new Date(item.time), 'EEE dd-MM-yyyy / HH:mm')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function SendPointsPanel() {
  const [recipient, setRecipient] = useState('');
  const [amount, setAmount] = useState('');
  const [transferPoints] = useTransferPointsMutation();
  const { data: pointsData } = useGetPointsDataQuery();

  const handleTransfer = async () => {
    await transferPoints({ to: recipient, amount: parseInt(amount) });
    setRecipient('');
    setAmount('');
  };

  return (
    <div>
      <table className="table">
        <tbody>
          <tr>
            <td align="right">To:</td>
            <td align="left">
              <input
                type="text"
                value={recipient}
                onChange={e => setRecipient(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td align="right">Amount:</td>
            <td align="left">
              <input
                type="number"
                value={amount}
                onChange={e => setAmount(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="right">
              <button className="btn btn-primary" onClick={handleTransfer}>
                Transfer
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <h5 className="mt-4">Recent Transfers</h5>
      <table className="table">
        <tbody>
          {pointsData?.transfers?.map(transfer => (
            <tr key={transfer.id}>
              <td align="center">{transfer.id}</td>
              <td align="center">{transfer.to}</td>
              <td align="center">{transfer.amount}</td>
              <td align="center">
                {format(new Date(transfer.time), 'EEE dd-MM-yyyy / HH:mm')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function PointsPage() {
  const [activeTab, setActiveTab] = useState('general');
  const [activePanelTab, setActivePanelTab] = useState('panel');

  return (
    <div className="family-tabs-container">
      <div className="tabs-wrapper">
        <ul className="nav nav-tabs flex-nowrap">
          <li className="nav-item">
            <a
              href="#general"
              onClick={() => setActiveTab('general')}
              className={`nav-link ${activeTab === 'general' ? 'active' : ''}`}
            >
              General
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#items"
              onClick={() => setActiveTab('items')}
              className={`nav-link ${activeTab === 'items' ? 'active' : ''}`}
            >
              Items
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#family"
              onClick={() => setActiveTab('family')}
              className={`nav-link ${activeTab === 'family' ? 'active' : ''}`}
            >
              Family
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#resets"
              onClick={() => setActiveTab('resets')}
              className={`nav-link ${activeTab === 'resets' ? 'active' : ''}`}
            >
              Resets
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#global"
              onClick={() => setActiveTab('global')}
              className={`nav-link ${activeTab === 'global' ? 'active' : ''}`}
            >
              Global
            </a>
          </li>
        </ul>
      </div>

      <div className="tab-content panel rounded-0 p-3 m-0">
        <div
          className={`tab-pane fade ${activeTab === 'general' ? 'active show' : ''}`}
        >
          {activeTab === 'general' && <GeneralTab />}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'items' ? 'active show' : ''}`}
        >
          {activeTab === 'items' && <ItemsTab />}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'family' ? 'active show' : ''}`}
        >
          {activeTab === 'family' && <FamilyTab />}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'resets' ? 'active show' : ''}`}
        >
          {activeTab === 'resets' && <ResetsTab />}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'global' ? 'active show' : ''}`}
        >
          {activeTab === 'global' && <GlobalBoostsTab />}
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <div className="tabs-wrapper">
            <ul className="nav nav-tabs flex-nowrap">
              <li className="nav-item">
                <a
                  href="#panel"
                  onClick={() => setActivePanelTab('panel')}
                  className={`nav-link ${activePanelTab === 'panel' ? 'active' : ''}`}
                >
                  Point Panel
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#spent"
                  onClick={() => setActivePanelTab('spent')}
                  className={`nav-link ${activePanelTab === 'spent' ? 'active' : ''}`}
                >
                  Points Spent
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#send"
                  onClick={() => setActivePanelTab('send')}
                  className={`nav-link ${activePanelTab === 'send' ? 'active' : ''}`}
                >
                  Send Points
                </a>
              </li>
            </ul>

            <div className="tab-content panel rounded-0 p-3 m-0">
              <div
                className={`tab-pane fade ${activePanelTab === 'panel' ? 'active show' : ''}`}
              >
                {activePanelTab === 'panel' && <PointPanel />}
              </div>
              <div
                className={`tab-pane fade ${activePanelTab === 'spent' ? 'active show' : ''}`}
              >
                {activePanelTab === 'spent' && <PointSpentPanel />}
              </div>
              <div
                className={`tab-pane fade ${activePanelTab === 'send' ? 'active show' : ''}`}
              >
                {activePanelTab === 'send' && <SendPointsPanel />}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    </div>
  );
}

export default PointsPage;
