import React from 'react';
import { useGlobalState } from '../../../auth/globalState'; // Adjust the import path as needed
import { Link } from 'react-router-dom';
import { RoleGuard, ROLES } from '../../../components/RoleGuard';


function DropdownProfile() {
  const { globalState } = useGlobalState();
  const displayName = globalState.data?.displayName || 'User';

  return (
    <div className="navbar-item navbar-user dropdown">
      <a
        href="#/"
        className="navbar-link dropdown-toggle d-flex align-items-center"
        data-bs-toggle="dropdown"
      >
        <img src="/assets/img/user/user-13.jpg" alt="" />
        <span>
          <span className="d-none d-md-inline">{displayName}</span>
          <b className="caret"></b>
        </span>
      </a>
      <div className="dropdown-menu dropdown-menu-end me-1">

      <RoleGuard roles={[ROLES.Admin]}>
          <Link to="/game/admin/panel" className="dropdown-item">
            Staff Panel
          </Link>
          <div className="dropdown-divider"></div>
        </RoleGuard>

        <Link to="/game/misc/profile-settings" className="dropdown-item">
          Edit Profile
        </Link>
        <div className="dropdown-divider"></div>
        <Link to="/logout" className="dropdown-item">
          Log Out
        </Link>
      </div>
    </div>
  );
}

export default DropdownProfile;
