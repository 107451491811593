import React, { useState } from 'react';
import { usePrisonListQuery, usePrisonBustMutation } from '../../api/game';
import Response from '../../shared-components/response';

const Prison = () => {
  const {
    data: prisonList,
    isLoading,
    isError,
    refetch,
  } = usePrisonListQuery();
  const [prisonBust] = usePrisonBustMutation();
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  const handlePrisonBust = async uuid => {
    try {
      const result = await prisonBust({ uuid }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      if (!result.justCaught) {
        refetch();
      }
        } catch (error) {
      setAlertData({
        message: error.data?.message || 'Prison bust failed',
        type: 'danger',
      });
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading prison list</div>;

  // Filter out the inPrison property and convert object to array if needed
  const prisoners = Object.values(prisonList).filter(item => typeof item === 'object' && 'uuid' in item);

  return (
    <div>
      <Response message={alertData.message} type={alertData.type} />
      <div className="row">
        <div className="col-lg-1"></div>
        <div className="col-lg-10">
          <div className="panel rounded-0 p-3 m-0">
            <h3>Prison</h3>
            <table className="table">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Username</th>
                  <th>Reason</th>
                  <th>Time Left</th>
                </tr>
              </thead>
              <tbody>
                {prisoners.map(prisoner => (
                  <tr key={prisoner.uuid}>
                    <td>
                      <button 
                        className="btn btn-primary btn-sm"
                        onClick={() => handlePrisonBust(prisoner.uuid)}
                      >
                        Bust
                      </button>
                    </td>
                    <td>{prisoner.displayName}</td>
                    <td>{prisoner.reason}</td>
                    <td>{prisoner.timeLeft} seconds</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-lg-1"></div>
      </div>
    </div>
  );
};
export default Prison;