import React, { useState } from 'react';
import {
  useGarageListVehiclesQuery,
  useGarageShipVehicleMutation,
  useGarageSendVehicleMutation,
  useGarageRepairVehicleMutation,
  useGarageScrapVehicleMutation,
  useGarageSellVehicleMutation,


  useMarinaListVehiclesQuery,
  useMarinaShipVehicleMutation,
  useMarinaSendVehicleMutation,
  useMarinaRepairVehicleMutation,
  useMarinaScrapVehicleMutation,
  useMarinaSellVehicleMutation,

  useListLocationsQuery,

  useMarketListVehiclesQuery,
  useMarketBuyVehicleMutation,
  useMarketSellVehicleMutation

} from '../../api/game';

import Response from '../../shared-components/response';
import { formatNumber } from '../../shared-components/numberFormat';




const Garage = () => {
  const {
    data: vehicles,
    isLoading: vehiclesLoading,
    isError,
    refetch,
  } = useGarageListVehiclesQuery();
  
  const [shipVehicle] = useGarageShipVehicleMutation();
  const [sendVehicle] = useGarageSendVehicleMutation();
  const [repairVehicle] = useGarageRepairVehicleMutation();
  const [scrapVehicle] = useGarageScrapVehicleMutation();
  const [sellVehicle] = useGarageSellVehicleMutation();
  const [marketsellVehicle] = useMarketSellVehicleMutation();

  const { data: locationsData = { locations: [] } } = useListLocationsQuery();

  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [destination, setDestination] = useState('');
  const [recipient, setRecipient] = useState('');
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [actionSuccess, setActionSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bulkRecipient, setBulkRecipient] = useState('');
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [listingPrice, setListingPrice] = useState('');


  
  const handleCheckAll = e => {
    if (e.target.checked) {
      const vehicleIds = vehiclesArray.map(v => v.uuid);
      setSelectedVehicles(vehicleIds);
    } else {
      setSelectedVehicles([]);
    }
  };

  const handleCheck = uuid => {
    setSelectedVehicles(prev =>
      prev.includes(uuid) ? prev.filter(id => id !== uuid) : [...prev, uuid]
    );
  };

  const handleBulkRepair = () => {
    setConfirmAction(() => async () => {
      setIsLoading(true);
      try {
        const result = await repairVehicle({ uuids: selectedVehicles }).unwrap();
        setAlertData({ message: result.message, type: result.type });
        if (result.type === 'success') {
          refetch();
          setShowBulkModal(false);
        }
      } catch (error) {
        setAlertData({
          message: error.data?.message || 'Error performing bulk repair',
          type: 'error'
        });
      }
      setIsLoading(false);
      setShowConfirmDialog(false);
    });
    setShowConfirmDialog(true);
  };
  
  const handleBulkSend = () => {
    setConfirmAction(() => async () => {
      try {
        const response = await sendVehicle({
          uuids: selectedVehicles,
          recipient: bulkRecipient
        }).unwrap();
        setAlertData({ message: response.message, type: response.type });
        if (response.type === 'success') {
          refetch();
          setBulkRecipient('');
          setSelectedVehicles([]);
          setShowBulkModal(false);
        }
      } catch (error) {
        setAlertData({
          message: error.data?.message || 'Failed to send vehicles',
          type: 'error'
        });
      }
      setShowConfirmDialog(false);
    });
    setShowConfirmDialog(true);
  };
  
  const handleBulkScrap = () => {
    setConfirmAction(() => async () => {
      try {
        const response = await scrapVehicle({
          uuids: selectedVehicles
        }).unwrap();
        setAlertData({ message: response.message, type: response.type });
        if (response.type === 'success') {
          refetch();
          setSelectedVehicles([]);
          setShowBulkModal(false);
        }
      } catch (error) {
        setAlertData({
          message: error.data?.message || 'Failed to scrap vehicles',
          type: 'error'
        });
      }
      setShowConfirmDialog(false);
    });
    setShowConfirmDialog(true);
  };
  
  const handleBulkSell = () => {
    setConfirmAction(() => async () => {
      try {
        const response = await sellVehicle({
          uuids: selectedVehicles
        }).unwrap();
        setAlertData({ message: response.message, type: response.type });
        if (response.type === 'success') {
          refetch();
          setSelectedVehicles([]);
          setShowBulkModal(false);
        }
      } catch (error) {
        setAlertData({
          message: error.data?.message || 'Failed to sell vehicles',
          type: 'error'
        });
      }
      setShowConfirmDialog(false);
    });
    setShowConfirmDialog(true);
  };
  

  const handleBulkShip = async () => {
    if (!destination) {
      setAlertData({
        message: 'Please select a destination',
        type: 'error'
      });
      return;
    }

    setConfirmAction(() => async () => {
      setIsLoading(true);
      try {
        const results = await Promise.all(
          selectedVehicles.map(uuid => 
            shipVehicle({ uuid, destination }).unwrap()
          )
        );
        const successCount = results.filter(r => r.type === 'success').length;
        setAlertData({
          message: `Successfully shipped ${successCount} vehicles`,
          type: 'success'
        });
        refetch();
      } catch (error) {
        setAlertData({
          message: 'Error performing bulk shipping',
          type: 'error'
        });
      }
      setIsLoading(false);
      setShowConfirmDialog(false);
    });
    setShowConfirmDialog(true);
  };

  const handleVehicleClick = vehicle => {
    setSelectedVehicle(vehicle);
    setShowModal(true);
    setDestination('');
    setRecipient('');
    setActionSuccess(false);
    setAlertData({ message: '', type: '' });
  };

  const handleSingleAction = async (action, data = {}) => {
    setConfirmAction(() => async () => {
      setIsLoading(true);
      try {
        let result;
        switch (action) {
          case 'ship':
            result = await shipVehicle({ uuid: selectedVehicle.uuid, destination: data.destination }).unwrap();
            break;
          case 'send':
            result = await sendVehicle({ uuid: selectedVehicle.uuid, recipient: data.recipient }).unwrap();
            break;
          case 'repair':
            result = await repairVehicle({ uuid: selectedVehicle.uuid }).unwrap();
            break;
          case 'scrap':
            result = await scrapVehicle({ uuid: selectedVehicle.uuid }).unwrap();
            break;
          case 'sell':
            result = await sellVehicle({ uuid: selectedVehicle.uuid }).unwrap();
            break;
          case 'list':
            result = await marketsellVehicle({ 
              uuid: selectedVehicle.uuid, 
              price: data.price,
              type: 'garage'
            }).unwrap();
            break;
            
        }
        setAlertData({ message: result.message, type: result.type });
        if (result.type === 'success') {
          refetch();
          setShowModal(false);
        }
      } catch (error) {
        setAlertData({
          message: error.data?.message || `Failed to ${action} vehicle`,
          type: 'error'
        });
      }
      setIsLoading(false);
      setShowConfirmDialog(false);
    });
    setShowConfirmDialog(true);
  };
  

  if (vehiclesLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading vehicles</div>;

  const getStatusColor = status => {
    switch (status) {
      case 'Stored In Garage':
        return 'text-success';
      case 'In Transport':
        return 'text-warning';
      default:
        return 'text';
    }
  };

  const formatCountdown = time => {
    const remaining = time - Date.now();
    if (remaining <= 0) return 'Arrived';
    const minutes = Math.floor(remaining / 60000);
    return `${minutes}m remaining`;
  };

  const vehiclesArray = Object.values(vehicles || {}).filter(item => typeof item === 'object');


  const calculateTotalRepairCost = () => {
    return selectedVehicles.reduce((total, uuid) => {
      const vehicle = vehiclesArray.find(v => v.uuid === uuid);
      return total + (vehicle?.repairCost || 0);
    }, 0);
  };
  


  return (
    <div>
      <GarageStats vehicles={vehiclesArray} />
      
      <div className="mb-3">
  {selectedVehicles.length > 0 && (
    <button 
      className="btn btn-primary"
      onClick={() => setShowBulkModal(true)}
    >
      Actions With Selected ({selectedVehicles.length})
    </button>
  )}
</div>


      <Response message={alertData.message} type={alertData.type} />
      <div className="table-responsive">

      <table className="table">
        <thead>
          <tr>
            <th>
              <input 
                type="checkbox" 
                onChange={handleCheckAll}
                checked={selectedVehicles.length === vehiclesArray.length}
              />
            </th>
            <th>Vehicle</th>
            <th>Location</th>
            <th>Origin</th>
            <th>Damage</th>
            <th>Value</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {vehiclesArray.length === 0 ? (
      <tr>
        <td colSpan="8" className="text-center">
          You don't have any cars in your garage
        </td>
      </tr>
    ) : (
          vehiclesArray.map(vehicle => (
            <tr key={vehicle.uuid}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedVehicles.includes(vehicle.uuid)}
                  onChange={() => handleCheck(vehicle.uuid)}
                />
              </td>
              <td>{vehicle.name}</td>
              <td>{vehicle.location}</td>
              <td>{vehicle.origin}</td>
              <td>{vehicle.damage}%</td>
              <td>${formatNumber(vehicle.value)}</td>
              <td className={getStatusColor(vehicle.status)}>
                {vehicle.status}
                {vehicle.arrivalTime && (
                  <span className="ms-2">
                    ({formatCountdown(vehicle.arrivalTime)})
                  </span>
                )}
              </td>
              <td>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => handleVehicleClick(vehicle)}
                >
                  Actions
                </button>
              </td>
            </tr>
          ))
        )}
        </tbody>
      </table>

      </div>

      {showModal && (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Actions for {selectedVehicle.name}
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowModal(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Response message={alertData.message} type={alertData.type} />

                {!actionSuccess && (
                  <>
                    <h6>Ship Vehicle</h6>
                    <select
                      className="form-control mb-2"
                      value={destination}
                      onChange={e => setDestination(e.target.value)}
                    >
                      <option value="">Select destination</option>
                      {locationsData.locations.map(location => (
    <option key={location.uuid} value={location.uuid}>
      {location.city}
    </option>
  ))}
                    </select>
                    <button
                      className="btn btn-primary mb-3"
                      onClick={() => handleSingleAction('ship', { destination })}
                      disabled={!destination || isLoading}
                    >
                      Ship
                    </button>

                    <h6>Send Vehicle</h6>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Enter username"
                      value={recipient}
                      onChange={e => setRecipient(e.target.value)}
                    />
                    <button
                      className="btn btn-primary mb-3"
                      onClick={() => handleSingleAction('send', { recipient })}
                      disabled={!recipient || isLoading}
                    >
                      Send
                    </button>

                    <h6>Repair Vehicle</h6>
                    <p>
                      Repair Cost: ${formatNumber(selectedVehicle.repairCost)}
                    </p>
                    <button
                      className="btn btn-primary mb-3"
                      onClick={() => handleSingleAction('repair')}
                      disabled={isLoading}
                    >
                      Repair
                    </button>

                    <h6>Scrap Vehicle</h6>
                    <button 
                      className="btn btn-danger"
                      onClick={() => handleSingleAction('scrap')}
                      disabled={isLoading}
                    >
                      Scrap Vehicle
                    </button>
                    <h6>Sell Vehicle</h6>
<p>Sale Value: ${formatNumber(selectedVehicle.value)}</p>
<button 
  className="btn btn-success mb-3" 
  onClick={() => handleSingleAction('sell')}
>
  Sell Vehicle
</button>

<h6>List on Market</h6>
<input
  type="number"
  className="form-control mb-2"
  placeholder="Enter listing price"
  value={listingPrice}
  onChange={e => setListingPrice(e.target.value)}
/>
<button 
  className="btn btn-primary mb-3"
  onClick={() => handleSingleAction('list', { price: listingPrice })}
  disabled={!listingPrice || isLoading}
>
  List on Market
</button>


                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}


<BulkActionsModal
      show={showBulkModal}
      onClose={() => setShowBulkModal(false)}
      onRepair={handleBulkRepair}
      onShip={handleBulkShip}
      onSend={handleBulkSend}
      onScrap={handleBulkScrap}
      onSell={handleBulkSell}
      totalValue={selectedVehicles.reduce((total, uuid) => {
        const vehicle = vehiclesArray.find(v => v.uuid === uuid);
        return total + (vehicle?.value || 0);
      }, 0)}
      totalRepairCost={calculateTotalRepairCost()}
      locations={locationsData.locations}
      bulkRecipient={bulkRecipient}
      setBulkRecipient={setBulkRecipient}
      destination={destination}
      setDestination={setDestination}
    />


<ConfirmDialog
  show={showConfirmDialog}
  message="Are you sure you want to perform this action?"
  onConfirm={() => confirmAction?.()}
  onCancel={() => setShowConfirmDialog(false)}
  setShowModal={setShowModal}
  setShowBulkModal={setShowBulkModal}
/>

    </div>
  );
};

const Marina = () => {
  const {
    data: vehicles,
    isLoading: vehiclesLoading,
    isError,
    refetch,
  } = useMarinaListVehiclesQuery();
  
  const [shipMarineVehicle] = useMarinaShipVehicleMutation();
  const [sendMarineVehicle] = useMarinaSendVehicleMutation();
  const [repairMarineVehicle] = useMarinaRepairVehicleMutation();
  const [scrapMarineVehicle] = useMarinaScrapVehicleMutation();
  const [sellMarineVehicle] = useMarinaSellVehicleMutation();
  const [marketsellVehicle] = useMarketSellVehicleMutation();

  const { data: locationsData = { locations: [] } } = useListLocationsQuery();

  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [destination, setDestination] = useState('');
  const [recipient, setRecipient] = useState('');
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [actionSuccess, setActionSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bulkRecipient, setBulkRecipient] = useState('');
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [listingPrice, setListingPrice] = useState('');



  const handleCheckAll = e => {
    if (e.target.checked) {
      const vehicleIds = vehiclesArray.map(v => v.uuid);
      setSelectedVehicles(vehicleIds);
    } else {
      setSelectedVehicles([]);
    }
  };
  

  const handleCheck = uuid => {
    setSelectedVehicles(prev =>
      prev.includes(uuid) ? prev.filter(id => id !== uuid) : [...prev, uuid]
    );
  };


  const handleBulkRepair = () => {
    setConfirmAction(() => async () => {
      setIsLoading(true);
      try {
        const result = await repairMarineVehicle({ uuids: selectedVehicles }).unwrap();
        setAlertData({ message: result.message, type: result.type });
        if (result.type === 'success') {
          refetch();
          setShowBulkModal(false);
        }
      } catch (error) {
        setAlertData({
          message: error.data?.message || 'Error performing bulk repair',
          type: 'error'
        });
      }
      setIsLoading(false);
      setShowConfirmDialog(false);
    });
    setShowConfirmDialog(true);
  };
  
  const handleBulkSend = () => {
    setConfirmAction(() => async () => {
      try {
        const response = await sendMarineVehicle({
          uuids: selectedVehicles,
          recipient: bulkRecipient
        }).unwrap();
        setAlertData({ message: response.message, type: response.type });
        if (response.type === 'success') {
          refetch();
          setBulkRecipient('');
          setSelectedVehicles([]);
          setShowBulkModal(false);
        }
      } catch (error) {
        setAlertData({
          message: error.data?.message || 'Failed to send vessels',
          type: 'error'
        });
      }
      setShowConfirmDialog(false);
    });
    setShowConfirmDialog(true);
  };
  
  const handleBulkScrap = () => {
    setConfirmAction(() => async () => {
      try {
        const response = await scrapMarineVehicle({
          uuids: selectedVehicles
        }).unwrap();
        setAlertData({ message: response.message, type: response.type });
        if (response.type === 'success') {
          refetch();
          setSelectedVehicles([]);
          setShowBulkModal(false);
        }
      } catch (error) {
        setAlertData({
          message: error.data?.message || 'Failed to scrap vessels',
          type: 'error'
        });
      }
      setShowConfirmDialog(false);
    });
    setShowConfirmDialog(true);
  };
  
  const handleBulkSell = () => {
    setConfirmAction(() => async () => {
      try {
        const response = await sellMarineVehicle({
          uuids: selectedVehicles
        }).unwrap();
        setAlertData({ message: response.message, type: response.type });
        if (response.type === 'success') {
          refetch();
          setSelectedVehicles([]);
          setShowBulkModal(false);
        }
      } catch (error) {
        setAlertData({
          message: error.data?.message || 'Failed to sell vessels',
          type: 'error'
        });
      }
      setShowConfirmDialog(false);
    });
    setShowConfirmDialog(true);
  };
  
  

  

  const handleBulkShip = async () => {
    if (!destination) {
      setAlertData({
        message: 'Please select a destination',
        type: 'error'
      });
      return;
    }

    setConfirmAction(() => async () => {
      setIsLoading(true);
      try {
        const results = await Promise.all(
          selectedVehicles.map(uuid => 
            shipMarineVehicle({ uuid, destination }).unwrap()
          )
        );
        const successCount = results.filter(r => r.type === 'success').length;
        setAlertData({
          message: `Successfully shipped ${successCount} marine vehicles`,
          type: 'success'
        });
        refetch();
      } catch (error) {
        setAlertData({
          message: 'Error performing bulk shipping',
          type: 'error'
        });
      }
      setIsLoading(false);
      setShowConfirmDialog(false);
    });
    setShowConfirmDialog(true);
  };

  const handleVehicleClick = vehicle => {
    setSelectedVehicle(vehicle);
    setShowModal(true);
    setDestination('');
    setRecipient('');
    setActionSuccess(false);
    setAlertData({ message: '', type: '' });
  };

  const handleSingleAction = async (action, data = {}) => {
    setIsLoading(true);
    try {
      let result;
      switch (action) {
        case 'ship':
          result = await shipMarineVehicle({ 
            uuid: selectedVehicle.uuid, 
            destination: data.destination 
          }).unwrap();
          break;
        case 'send':
          result = await sendMarineVehicle({ 
            uuid: selectedVehicle.uuid, 
            recipient: data.recipient 
          }).unwrap();
          break;
        case 'repair':
          result = await repairMarineVehicle({ 
            uuid: selectedVehicle.uuid 
          }).unwrap();
          break;
        case 'scrap':
          result = await scrapMarineVehicle({ 
            uuid: selectedVehicle.uuid 
          }).unwrap();
          break;
        case 'sell':
            result = await sellMarineVehicle({ 
              uuid: selectedVehicle.uuid 
            }).unwrap();
            break;
        case 'list':
          result = await marketsellVehicle({ 
            uuid: selectedVehicle.uuid, 
            price: data.price,
            type: 'marina'
          }).unwrap();
          break;
            
          
      }
      setAlertData({ message: result.message, type: result.type });
      setActionSuccess(result.type === 'success');
      if (result.type === 'success') {
        refetch();
        setShowModal(false);
      }
    } catch (error) {
      setAlertData({
        message: error.data?.message || `Failed to ${action} marine vehicle`,
        type: 'error'
      });
    }
    setIsLoading(false);
  };

  if (vehiclesLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading marine vehicles</div>;

  const getStatusColor = status => {
    switch (status) {
      case 'Docked':
        return 'text-success';
      case 'In Transport':
        return 'text-warning';
      default:
        return 'text';
    }
  };

  const formatCountdown = time => {
    const remaining = time - Date.now();
    if (remaining <= 0) return 'Arrived';
    const minutes = Math.floor(remaining / 60000);
    return `${minutes}m remaining`;
  };

  const vehiclesArray = Object.values(vehicles || {}).filter(
    item => typeof item === 'object'
  );

  const calculateTotalRepairCost = () => {
    return selectedVehicles.reduce((total, uuid) => {
      const vehicle = vehiclesArray.find(v => v.uuid === uuid);
      return total + (vehicle?.repairCost || 0);
    }, 0);
  };
  


  return (
    <div>
      <MarinaStats vehicles={vehiclesArray} />
      <div className="mb-3">
  {selectedVehicles.length > 0 && (
    <button 
      className="btn btn-primary"
      onClick={() => setShowBulkModal(true)}
    >
      Actions With Selected ({selectedVehicles.length})
    </button>
  )}
</div>

      <Response message={alertData.message} type={alertData.type} />
      <div className="table-responsive">

      <table className="table">
        <thead>
          <tr>
            <th>
              <input 
                type="checkbox" 
                onChange={handleCheckAll}
                checked={selectedVehicles.length === vehiclesArray.length}
              />
            </th>
            <th>Vehicle</th>
            <th>Location</th>
            <th>Origin</th>
            <th>Damage</th>
            <th>Value</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {vehiclesArray.length === 0 ? (
      <tr>
        <td colSpan="8" className="text-center">
          You don't have any boats in your marina
        </td>
      </tr>
    ) : (
          vehiclesArray.map(vehicle => (
            <tr key={vehicle.uuid}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedVehicles.includes(vehicle.uuid)}
                  onChange={() => handleCheck(vehicle.uuid)}
                />
              </td>
              <td>{vehicle.name}</td>
              <td>{vehicle.location}</td>
              <td>{vehicle.origin}</td>
              <td>{vehicle.damage}%</td>
              <td>${formatNumber(vehicle.value)}</td>
              <td className={getStatusColor(vehicle.status)}>
                {vehicle.status}
                {vehicle.arrivalTime && (
                  <span className="ms-2">
                    ({formatCountdown(vehicle.arrivalTime)})
                  </span>
                )}
              </td>
              <td>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => handleVehicleClick(vehicle)}
                >
                  Actions
                </button>
              </td>
            </tr>
          ))
        )}
        </tbody>
      </table>
</div>
      {showModal && (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Actions for {selectedVehicle.name}
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowModal(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Response message={alertData.message} type={alertData.type} />

                {!actionSuccess && (
                  <>
                    <h6>Ship Marine Vehicle</h6>
                    <select
  className="form-control mb-2"
  value={destination}
  onChange={e => setDestination(e.target.value)}
>
  <option value="">Select destination</option>
  {locationsData.locations.map(location => (
    <option key={location.uuid} value={location.uuid}>
      {location.city}
    </option>
  ))}
</select>
                    <button
                      className="btn btn-primary mb-3"
                      onClick={() => handleSingleAction('ship', { destination })}
                      disabled={!destination || isLoading}
                    >
                      Ship
                    </button>

                    <h6>Send Marine Vehicle</h6>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Enter username"
                      value={recipient}
                      onChange={e => setRecipient(e.target.value)}
                    />
                    <button
                      className="btn btn-primary mb-3"
                      onClick={() => handleSingleAction('send', { recipient })}
                      disabled={!recipient || isLoading}
                    >
                      Send
                    </button>

                    <h6>Repair Marine Vehicle</h6>
                    <p>
                      Repair Cost: ${formatNumber(selectedVehicle.repairCost)}
                    </p>
                    <button
                      className="btn btn-primary mb-3"
                      onClick={() => handleSingleAction('repair')}
                      disabled={isLoading}
                    >
                      Repair
                    </button>

                    <h6>Scrap Marine Vehicle</h6>
                    <button 
                      className="btn btn-danger"
                      onClick={() => handleSingleAction('scrap')}
                      disabled={isLoading}
                    >
                      Scrap Marine Vehicle
                    </button>
                    <h6>Sell Marine Vehicle</h6>
<p>Sale Value: ${formatNumber(selectedVehicle.value)}</p>
<button 
  className="btn btn-success mb-3" 
  onClick={() => handleSingleAction('sell')}
>
  Sell Marine Vehicle
</button>
<h6>List on Market</h6>
<input
  type="number"
  className="form-control mb-2"
  placeholder="Enter price"
  value={listingPrice}
  onChange={e => setListingPrice(e.target.value)}
/>
<button 
  className="btn btn-primary mb-3"
  onClick={() => handleSingleAction('list', { price: listingPrice })}
  disabled={!listingPrice || isLoading}
>
  List on Market
</button>



                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
 <BulkActionsModal
      show={showBulkModal}
      onClose={() => setShowBulkModal(false)}
      onRepair={handleBulkRepair}
      onShip={handleBulkShip}
      onSend={handleBulkSend}
      onScrap={handleBulkScrap}
      onSell={handleBulkSell}
      totalValue={selectedVehicles.reduce((total, uuid) => {
        const vehicle = vehiclesArray.find(v => v.uuid === uuid);
        return total + (vehicle?.value || 0);
      }, 0)}
      totalRepairCost={calculateTotalRepairCost()}
      locations={locationsData.locations}
      bulkRecipient={bulkRecipient}
      setBulkRecipient={setBulkRecipient}
      destination={destination}
      setDestination={setDestination}
    />
<ConfirmDialog
  show={showConfirmDialog}
  message="Are you sure you want to perform this action?"
  onConfirm={() => confirmAction?.()}
  onCancel={() => setShowConfirmDialog(false)}
  setShowModal={setShowModal}
  setShowBulkModal={setShowBulkModal}
/>

    </div>
  );
};

const Market = () => {
  const { data: locationsData = { locations: [] } } = useListLocationsQuery();
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  const [filters, setFilters] = useState({
    type: 'all',
    location: '',
    maxPrice: '',
    search: ''
  });

  const { data: listings = [], refetch } = useMarketListVehiclesQuery(filters);
  
  const [buyVehicle] = useMarketBuyVehicleMutation();


const handleBuy = async (uuid) => {
  try {
    const response = await buyVehicle({
      uuid
    }).unwrap();
    setAlertData({ message: response.message, type: response.type });
    if (response.type === 'success') {
      refetch();
    }
  } catch (error) {
    setAlertData({
      message: error.data?.message || 'Failed to buy vehicle',
      type: 'error'
    });
  }
};

  const vehicleArray = listings ? Object.values(listings).filter(item => item !== false) : [];
  
  const filteredListings = vehicleArray.filter(item =>
    item.name?.toLowerCase().includes(filters.search.toLowerCase())
  );


  return (
    <>
      <Response message={alertData.message} type={alertData.type} />
      <div className="card">
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-md-3">
            <select 
              className="form-control"
              value={filters.type}
              onChange={e => setFilters({...filters, type: e.target.value})}
            >
              <option value="all">All Vehicles</option>
              <option value="car">Cars Only</option>
              <option value="boat">Boats Only</option>
            </select>
          </div>
          <div className="col-md-3">
            <select 
              className="form-control"
              value={filters.location}
              onChange={e => setFilters({...filters, location: e.target.value})}
            >
              <option value="">All Locations</option>
              {locationsData.locations.map(loc => (
    <option key={loc.uuid} value={loc.uuid}>{loc.city}</option>
  ))}
            </select>
          </div>
          <div className="col-md-3">
            <input
              type="number"
              className="form-control"
              placeholder="Max Price"
              value={filters.maxPrice}
              onChange={e => setFilters({...filters, maxPrice: e.target.value})}
            />
          </div>
          <div className="col-md-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search vehicles..."
              value={filters.search}
              onChange={e => setFilters({...filters, search: e.target.value})}
            />
          </div>
        </div>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Vehicle - Damage %</th>
                <th>Price</th>
                <th>Location</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredListings.map(item => (
                <tr key={item.id}>
                  <td>{item.name} - {item.damage}%</td>
                  <td>${formatNumber(item.price)}</td>
<td>{item.location} {item.shipped && <span>(Shipped)</span>}</td>
                  <td>
                    <button 
                      className="btn btn-success btn-sm"
                      onClick={() => handleBuy(item.uuid)}
                      >
                      Buy
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </>
  );
};


// Stats components
const GarageStats = ({ vehicles }) => {
  const totalStored = vehicles.filter(v => v.status === 'stored').length;
  const inTransport = vehicles.filter(v => v.status === 'In Transport').length;

  return (
    <div className="card mb-3">
      <div className="card-header">Garage Statistics</div>
      <div className="card-body">
        <div>Total Vehicles Stored: {totalStored}</div>
        <div>Vehicles in Transport: {inTransport}</div>
      </div>
    </div>
  );
};

const MarinaStats = ({ vehicles }) => {
  console.log('Marina vehicles:', vehicles); // Debug raw data

  const totalDocked = vehicles.filter(v => 
    v.status.toLowerCase().includes('dock') || 
    v.status === 'stored'
  ).length;
  
  const inTransport = vehicles.filter(v => 
    v.status.toLowerCase().includes('transport')
  ).length;

  return (
    <div className="card mb-3">
      <div className="card-header">Marina Statistics</div>
      <div className="card-body">
        <div>Total Vessels Docked: {totalDocked}</div>
        <div>Vessels in Transport: {inTransport}</div>
      </div>
    </div>
  );
};

// Confirmation dialog component
const ConfirmDialog = ({ 
  show, 
  message, 
  onConfirm, 
  onCancel,
  setShowModal,
  setShowBulkModal 
}) => {

  const handleConfirm = () => {
    onConfirm();
    setShowModal(false);
    setShowBulkModal(false);
  };

  const handleCancel = () => {
    onCancel();
    setShowModal(false);
    setShowBulkModal(false);
  };

  if (!show) return null;
  
  return (
    <div className="modal" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.5)'}}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Confirm Action</h5>
          </div>
          <div className="modal-body">
            <p>{message}</p>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
            <button className="btn btn-primary" onClick={handleConfirm}>Confirm</button>
          </div>
        </div>
      </div>
    </div>
  );
};



const BulkActionsModal = ({ 
  show, 
  onClose, 
  onRepair, 
  onShip,
  onSend,
  onScrap,
  onSell,
  totalValue,
  totalRepairCost,
  locations,
  bulkRecipient,
  setBulkRecipient,
  destination,
  setDestination
}) => {
  if (!show) return null;
  
  return (
    <div className="modal" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.5)'}}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Bulk Actions</h5>
            <button type="button" className="close" onClick={onClose}>
              <span>&times;</span>
            </button>
          </div>

          <div className="modal-body">
  <div className="d-grid gap-3">
    {/* Repair Section */}
    <div className="border-bottom pb-3">
      <button className="btn btn-primary w-100" onClick={onRepair}>
        Repair Selected (${formatNumber(totalRepairCost)})
      </button>
    </div>

    {/* Ship Section */}
    <div className="border-bottom pb-3">
      <select
        className="form-control mb-2"
        value={destination}
        onChange={e => setDestination(e.target.value)}
      >
        <option value="">Ship Selected To...</option>
        {locations.map(location => (
          <option key={location.uuid} value={location.uuid}>
            {location.city}
          </option>
        ))}
      </select>
      <button 
        className="btn btn-primary w-100"
        onClick={onShip}
        disabled={!destination}
      >
        Ship Selected
      </button>
    </div>

    {/* Send Section */}
    <div className="border-bottom pb-3">
      <input
        type="text"
        className="form-control mb-2"
        placeholder="Enter username"
        value={bulkRecipient}
        onChange={e => setBulkRecipient(e.target.value)}
      />
      <button 
        className="btn btn-primary w-100"
        onClick={onSend}
        disabled={!bulkRecipient}
      >
        Send Selected
      </button>
    </div>

    {/* Scrap and Sell Section */}
{/* Scrap and Sell Section */}
<div className="d-grid gap-2">
  <div className="border-bottom pb-3">
    <button className="btn btn-danger w-100" onClick={onScrap}>
      Scrap Selected
    </button>
  </div>
  <button className="btn btn-success" onClick={onSell}>
    Sell Selected (${formatNumber(totalValue)})
  </button>
</div>


  </div>
</div>

        </div>
      </div>
    </div>
  );
};



const Vehicles = () => {
  const [activeTab, setActiveTab] = useState('garage');

  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };

  return (
    <div>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a
            href="#garage-tab"
            onClick={() => handleTabClick('garage')}
            className={`nav-link ${activeTab === 'garage' ? 'active' : ''}`}
          >
            <span className="d-sm-none">Garage</span>
            <span className="d-sm-block d-none">Garage</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#marina-tab"
            onClick={() => handleTabClick('marina')}
            className={`nav-link ${activeTab === 'marina' ? 'active' : ''}`}
          >
            <span className="d-sm-none">Marina</span>
            <span className="d-sm-block d-none">Marina</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#market-tab"
            onClick={() => handleTabClick('market')}
            className={`nav-link ${activeTab === 'market' ? 'active' : ''}`}
          >
            <span className="d-sm-none">Market</span>
            <span className="d-sm-block d-none">Market</span>
          </a>
        </li>
      </ul>
      <div className="tab-content panel rounded-0 p-3 m-0">
        <div
          className={`tab-pane fade ${activeTab === 'garage' ? 'active show' : ''}`}
          id="garage-tab"
        >
          <Garage />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'marina' ? 'active show' : ''}`}
          id="marina-tab"
        >
          <Marina />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'market' ? 'active show' : ''}`}
          id="market-tab"
        >
          <Market />
        </div>
      </div>
    </div>
  );
};

export default Vehicles;