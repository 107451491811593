import React, { useState } from 'react';
import {
  useLotteryInfoQuery,
  useLotteryListUserTicketsQuery,
  useLotteryDrawHistoryQuery,
  useLotteryBuyTicketMutation,
  useLotteryStatsQuery
} from '../../api/game';
import './lottery.css';
import Response from '../../shared-components/response';


const Lottery = () => {
  const [activeTab, setActiveTab] = useState('potInfo');
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [response, setResponse] = useState({ message: '', type: '' });

  const { data: lotteryInfo } = useLotteryInfoQuery();
  const { data: userTickets } = useLotteryListUserTicketsQuery();
  const { data: drawHistory } = useLotteryDrawHistoryQuery();
  const [buyTicket] = useLotteryBuyTicketMutation();

  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };

  const handleNumberSelection = number => {
    if (selectedNumbers.includes(number)) {
      setSelectedNumbers(selectedNumbers.filter(num => num !== number));
    } else if (selectedNumbers.length < 3) {
      setSelectedNumbers([...selectedNumbers, number].sort((a, b) => a - b));
    } else {
      alert('You can only select up to 3 numbers.');
    }
  };

  const luckyDip = () => {
    const numbers = [];
    while (numbers.length < 3) {
      const randomNumber = Math.floor(Math.random() * 30) + 1;
      if (!numbers.includes(randomNumber)) {
        numbers.push(randomNumber);
      }
    }
    setSelectedNumbers(numbers.sort((a, b) => a - b));
  };

  const handleBuyTicket = async () => {
    if (selectedNumbers.length !== 3) {
      setResponse({
        message: 'Please select 3 numbers before buying a ticket.',
        type: 'danger'
      });
      return;
    }

    try {
      const result = await buyTicket({ 
        numbers: selectedNumbers.join(', ') 
      }).unwrap();
      
      setResponse({
        message: result.message,
        type: result.type
      });

      if (result.type === 'success') {
        setSelectedNumbers([]);
      }
    } catch (error) {
      setResponse({
        message: error.data?.message || 'Failed to purchase ticket',
        type: 'danger'
      });
    }
  };

  const PotInfo = () => (
    <div className="tab-pane fade active show">
      <h2>Lottery Information</h2>
      <div className="lottery-stats">
        <div className="stat-item">
          <span>Current Draw #:</span>
          <span>{lotteryInfo?.currentDrawNumber}</span>
        </div>
        <div className="stat-item">
          <span>Jackpot:</span>
          <span>£{lotteryInfo?.jackpot.toLocaleString()}</span>
        </div>
        <div className="stat-item">
          <span>Tickets Sold:</span>
          <span>{lotteryInfo?.totalTickets}</span>
        </div>
        <div className="stat-item">
          <span>Next Draw:</span>
          <span>{lotteryInfo?.nextDraw}</span>
        </div>
      </div>
    </div>
  );

  const TicketHistory = () => (
    <div className={`tab-pane fade ${activeTab === 'yourTickets' ? 'active show' : ''}`}>
      <h2>Your Tickets</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Draw #</th>
            <th>Numbers</th>
            <th>Purchase Date</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(userTickets || {})
            .filter(([key, ticket]) => !isNaN(key) && ticket?.uuid)
            .map(([_, ticket]) => (
              <tr key={ticket.uuid}>
                <td>{ticket.drawNumber}</td>
                <td>{ticket.numbers}</td>
                <td>{new Date(ticket.date).toLocaleString()}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
  
  
  
  const DrawHistory = () => (
    <div className={`tab-pane fade ${activeTab === 'drawHistory' ? 'active show' : ''}`}>
      <h2>Draw History and Stats</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Numbers</th>
            <th>Winner</th>
            <th>Total Tickets</th>
          </tr>
        </thead>
        <tbody>
        {Object.entries(drawHistory || {})
          .filter(([key]) => !isNaN(key))
          .map(([_, draw]) => (
            <tr key={draw.uuid}>
              <td>{new Date(draw.date).toLocaleDateString()}</td>
              <td>{draw.winningNumbers}</td>
              <td>{draw.winnerUuid || 'No Winner'}</td>
              <td>{draw.totalTickets}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
  
  
  

  return (
    <div className="row">
      <div className="col-lg-2"></div>
      <div className="col-lg-8">
        <div className="lottery-tabs-container">
          <div className="tabs-wrapper">
            <ul className="nav nav-tabs flex-nowrap">
              <li className="nav-item">
                <a
                  href="#pot-info"
                  onClick={() => handleTabClick('potInfo')}
                  className={`nav-link ${activeTab === 'potInfo' ? 'active' : ''}`}
                >
                  Pot Information
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#buy-ticket"
                  onClick={() => handleTabClick('buyTicket')}
                  className={`nav-link ${activeTab === 'buyTicket' ? 'active' : ''}`}
                >
                  Buy a ticket!
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#your-tickets"
                  onClick={() => handleTabClick('yourTickets')}
                  className={`nav-link ${activeTab === 'yourTickets' ? 'active' : ''}`}
                >
                  Your Tickets
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#draw-history"
                  onClick={() => handleTabClick('drawHistory')}
                  className={`nav-link ${activeTab === 'drawHistory' ? 'active' : ''}`}
                >
                  Draw History and Data
                </a>
              </li>
            </ul>
          </div>

          <div className="tab-content panel rounded-0 p-3 m-0">
            {activeTab === 'potInfo' && <PotInfo />}
            {activeTab === 'buyTicket' && (
              <div className={`tab-pane fade ${activeTab === 'buyTicket' ? 'active show' : ''}`} id="buy-ticket">
                <h2>Buy a ticket</h2>
                <Response 
          message={response.message}
          type={response.type}
        />
                <div className="number-selector">
                  {[...Array(3)].map((_, row) => (
                    <div key={row} className="number-row">
                      {[...Array(10)].map((_, col) => {
                        const number = row * 10 + col + 1;
                        return number <= 30 ? (
                          <div
                            key={number}
                            className={`number-circle ${selectedNumbers.includes(number) ? 'selected' : ''}`}
                            onClick={() => handleNumberSelection(number)}
                          >
                            {number}
                          </div>
                        ) : null;
                      })}
                    </div>
                  ))}
                </div>
                <div className="buttons-container">
                  <button onClick={luckyDip} className="lucky-dip-button">
                    Lucky Dip
                  </button>
                  <button
                    onClick={handleBuyTicket}
                    disabled={selectedNumbers.length !== 3}
                    className="submit-button"
                  >
                    Buy Ticket
                  </button>
                </div>
 
                <div className="selected-numbers-container">
                  <div className="selected-numbers-list">
                    {selectedNumbers.map(number => (
                      <div key={number} className="selected-number-circle">
                        {number}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {activeTab === 'yourTickets' && <TicketHistory />}
            {activeTab === 'drawHistory' && <DrawHistory />}
          </div>
        </div>
      </div>
      <div className="col-lg-2"></div>
    </div>
  );
};

export default Lottery;