import React, { useState } from 'react';
import { useAdminListUsersQuery, useAdminListCharactersQuery, useAdminGetCharacterDetailsQuery } from '../../api/game';
import { formatNumber } from '../../shared-components/numberFormat';

import { RoleGuard, ROLES } from '../../components/RoleGuard';

const ROLE_DISPLAY = Object.entries(ROLES).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {});

  function Users() {
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [sortBy, setSortBy] = useState('email');
    const [sortOrder, setSortOrder] = useState('asc');
    const [selectedUser, setSelectedUser] = useState(null);

    const { data: users, isLoading } = useAdminListUsersQuery({
      search: searchTerm,
      page,
      limit,
      sortBy,
      sortOrder
    });

    const handleSearch = (e) => {
      setSearchTerm(e.target.value);
      setPage(1);
    };
  
    const handleSort = (column) => {
      if (sortBy === column) {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      } else {
        setSortBy(column);
        setSortOrder('asc');
      }
    };
  
    const handleRowClick = (user) => {
      setSelectedUser(user);
    };
  
    const handleBack = () => {
      setSelectedUser(null);
    };
  
    if (selectedUser) {
      return (
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h4>Managing User: {selectedUser.email}</h4>
            <button className="btn btn-secondary" onClick={handleBack}>Back to Users</button>
          </div>
          <div className="card-body">
            <div className="row mb-3">
              <div className="col">
                <h5>Account Details</h5>
                <p>Role: {ROLE_DISPLAY[selectedUser.role] || 'User'}</p>
                <p>Status: {selectedUser.banned === 'no' ? 'Active' : 'Banned'}</p>
                <p>Points: {formatNumber(selectedUser.points)}</p>
                <p>ATM: {selectedUser.atmUuid ? 'Yes' : 'None'}</p>
              </div>
              <div className="row mt-3">
                <div className="col">
                    <h5>Login History</h5>
                    <div className="table-responsive">
                    <table className="table table-sm">
                    <thead>
                        <tr>
                            <th>IP Address</th>
                            <th>Last Used</th>
                        </tr>
                        </thead>
                        <tbody>
                        {selectedUser.uniqueIps?.map((log, index) => (
                            <tr key={index}>
                            <td>{log.ip}</td>
                            <td>{new Date(log.lastUsed).toLocaleString()}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>

              <div className="col">
                <h5>Characters</h5>
                {selectedUser.Users_characters?.length > 0 ? (
                  <ul>
                    {selectedUser.Users_characters.map(char => (
                      <li key={char.username}>{char.username}</li>
                    ))}
                  </ul>
                ) : (
                  <p>No characters</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h5>Actions</h5>
                <div className="btn-group">
                  <button className="btn btn-danger">Ban Account</button>
                  <button className="btn btn-warning">Mute Account</button>
                  <button className="btn btn-info">Reset Password</button>
                  <button className="btn btn-secondary">View Logs</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  
    return (
      <div>
        <h3 className="mt-10px">Users Management</h3>
        
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between mb-3">
              <input
                type="text"
                className="form-control w-25"
                placeholder="Search users..."
                value={searchTerm}
                onChange={handleSearch}
              />
              <select 
                className="form-select w-auto"
                value={limit}
                onChange={(e) => setLimit(Number(e.target.value))}
              >
                <option value="10">10 per page</option>
                <option value="25">25 per page</option>
                <option value="50">50 per page</option>
                <option value="100">100 per page</option>
              </select>
            </div>
  
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th onClick={() => handleSort('email')} style={{cursor: 'pointer'}}>
                      Email {sortBy === 'email' && (sortOrder === 'asc' ? '↑' : '↓')}
                    </th>
                    <th onClick={() => handleSort('role')} style={{cursor: 'pointer'}}>
                      Role {sortBy === 'role' && (sortOrder === 'asc' ? '↑' : '↓')}
                    </th>
                    <th onClick={() => handleSort('banned')} style={{cursor: 'pointer'}}>
                      Status {sortBy === 'banned' && (sortOrder === 'asc' ? '↑' : '↓')}
                    </th>
                    <th onClick={() => handleSort('points')} style={{cursor: 'pointer'}}>
                      Points {sortBy === 'points' && (sortOrder === 'asc' ? '↑' : '↓')}
                    </th>
                    <th>ATM</th>
                    <th>Characters</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="6" className="text-center">Loading...</td>
                    </tr>
                  ) : users?.data.map(user => (
                    <tr key={user.uuid} onClick={() => handleRowClick(user)} style={{cursor: 'pointer'}}>
                      <td>{user.email}</td>
                      <td>{ROLE_DISPLAY[user.role] || 'User'}</td>
                      <td>
                        <span className={`badge bg-${user.banned === 'no' ? 'success' : 'danger'}`}>
                          {user.banned === 'no' ? 'Active' : 'Banned'}
                        </span>
                      </td>
                      <td>{formatNumber(user.points)}</td>
                      <td>{user.atmUuid ? 'Yes' : 'None'}</td>
                      <td>
                        {user.Users_characters?.map(char => char.username).join(', ') || 'None'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
  
            {users?.total > 0 && (
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div>
                  Showing {((page - 1) * limit) + 1} to {Math.min(page * limit, users.total)} of {users.total} entries
                </div>
                <div className="btn-group">
                  <button 
                    className="btn btn-outline-primary"
                    disabled={page === 1}
                    onClick={() => setPage(page - 1)}
                  >
                    Previous
                  </button>
                  <button 
                    className="btn btn-outline-primary"
                    disabled={page * limit >= users.total}
                    onClick={() => setPage(page + 1)}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
}
  

function Characters() {
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [sortBy, setSortBy] = useState('username');
    const [sortOrder, setSortOrder] = useState('asc');
    const [selectedUuid, setSelectedUuid] = useState(null);
  
    const { data: characters, isLoading } = useAdminListCharactersQuery({
      search: searchTerm,
      page,
      limit,
      sortBy,
      sortOrder
    });

    const { data: characterDetails } = useAdminGetCharacterDetailsQuery(selectedUuid, {
      skip: !selectedUuid
    });
  
    const handleSearch = (e) => {
      setSearchTerm(e.target.value);
      setPage(1);
    };
  
    const handleSort = (column) => {
      if (sortBy === column) {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      } else {
        setSortBy(column);
        setSortOrder('asc');
      }
    };
  
    const handleRowClick = (character) => {
      setSelectedUuid(character.uuid);
    };
    const characterArray = Object.values(characters || {}).filter(item => typeof item === 'object' && item.uuid);

    if (selectedUuid && characterDetails) {
      return (
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h4>Managing Character: {characterDetails.username}</h4>
            <button className="btn btn-secondary" onClick={() => setSelectedUuid(null)}>Back to Characters</button>
          </div>
          <div className="card-body">
            <div className="row mb-3">
              <div className="col">
                <h5>Character Details</h5>
                <p>Status: {characterDetails.status}</p>
                <p>Rank: {characterDetails.game_rank?.rankName}</p>
                <p>Location: {characterDetails.location?.city}</p>
                <p>Money: £{formatNumber(characterDetails.money)}</p>
                <p>Bullets: {formatNumber(characterDetails.bullets)}</p>
                <p>Kevlars: {formatNumber(characterDetails.kevlars)}</p>
                <p>Health: {characterDetails.health}%</p>
                <p>Weapon: {characterDetails.weapons_option?.title || 'None'}</p>
                <p>Protection: {characterDetails.protection_option?.title || 'None'}</p>
                <p>Family: {characterDetails.memberFamily?.name || 'None'}</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h5>Actions</h5>
                <div className="btn-group">
                  <button className="btn btn-danger">Kill Character</button>
                  <button className="btn btn-warning">Jail Character</button>
                  <button className="btn btn-info">Reset Stats</button>
                  <button className="btn btn-secondary">View Logs</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  
    return (
      <div>
        <h3 className="mt-10px">Characters Management</h3>
        
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between mb-3">
              <input
                type="text"
                className="form-control w-25"
                placeholder="Search characters..."
                value={searchTerm}
                onChange={handleSearch}
              />
              <select 
                className="form-select w-auto"
                value={limit}
                onChange={(e) => setLimit(Number(e.target.value))}
              >
                <option value="10">10 per page</option>
                <option value="25">25 per page</option>
                <option value="50">50 per page</option>
                <option value="100">100 per page</option>
              </select>
            </div>
  
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th onClick={() => handleSort('username')} style={{cursor: 'pointer'}}>
                      Username {sortBy === 'username' && (sortOrder === 'asc' ? '↑' : '↓')}
                    </th>
                    <th onClick={() => handleSort('status')} style={{cursor: 'pointer'}}>
                      Status {sortBy === 'status' && (sortOrder === 'asc' ? '↑' : '↓')}
                    </th>
                    <th onClick={() => handleSort('money')} style={{cursor: 'pointer'}}>
                      Money {sortBy === 'money' && (sortOrder === 'asc' ? '↑' : '↓')}
                    </th>
                    <th onClick={() => handleSort('bullets')} style={{cursor: 'pointer'}}>
                      Bullets {sortBy === 'bullets' && (sortOrder === 'asc' ? '↑' : '↓')}
                    </th>
                    <th onClick={() => handleSort('kevlars')} style={{cursor: 'pointer'}}>
                      Kevlars {sortBy === 'kevlars' && (sortOrder === 'asc' ? '↑' : '↓')}
                    </th>
                    <th onClick={() => handleSort('health')} style={{cursor: 'pointer'}}>
                      Health {sortBy === 'health' && (sortOrder === 'asc' ? '↑' : '↓')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="6" className="text-center">Loading...</td>
                    </tr>
                  ) : Object.values(characters || {}).filter(item => typeof item === 'object' && item.uuid).map(character => (
                    <tr key={character.uuid} onClick={() => handleRowClick(character)} style={{cursor: 'pointer'}}>
                      <td>{character.username}</td>
                      <td>
                        <span className={`badge bg-${character.status === 'Alive' ? 'success' : 'danger'}`}>
                          {character.status}
                        </span>
                      </td>
                      <td>£{formatNumber(character.money)}</td>
                      <td>{formatNumber(character.bullets)}</td>
                      <td>{formatNumber(character.kevlars)}</td>
                      <td>{character.health}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {characterArray.length > 0 && (
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div>
                Showing {((page - 1) * limit) + 1} to {Math.min(page * limit, characterArray.length)} of {characterArray.length} entries
                </div>
                <div className="btn-group">
                  <button 
                    className="btn btn-outline-primary"
                    disabled={page === 1}
                    onClick={() => setPage(page - 1)}
                  >
                    Previous
                  </button>
                  <button 
                    className="btn btn-outline-primary"
                    disabled={page * limit >= characters.length}
                    onClick={() => setPage(page + 1)}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  

function Properties() {
  return (
    <div>
      <h3 className="mt-10px">Properties Management</h3>
      {/* Properties content will go here */}
    </div>
  );
}

function Logs() {
  return (
    <div>
      <h3 className="mt-10px">System Logs</h3>
      {/* Logs content will go here */}
    </div>
  );
}

function Configs() {
  return (
    <div>
      <h3 className="mt-10px">Game Configurations</h3>
      {/* Configs content will go here */}
    </div>
  );
}

function Misc() {
  return (
    <div>
      <h3 className="mt-10px">Miscellaneous Tools</h3>
      {/* Misc content will go here */}
    </div>
  );
}

function AdminPanel() {
  const [activeTab, setActiveTab] = useState('users');

  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };

  return (
    <RoleGuard roles={[ROLES.Admin]}>

    <div className="family-tabs-container">
      <div className="tabs-wrapper">
        <ul className="nav nav-tabs flex-nowrap">
          <li className="nav-item">
            <a
              href="#users-tab"
              onClick={() => handleTabClick('users')}
              className={`nav-link ${activeTab === 'users' ? 'active' : ''}`}
            >
              Users
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#characters-tab"
              onClick={() => handleTabClick('characters')}
              className={`nav-link ${activeTab === 'characters' ? 'active' : ''}`}
            >
              Characters
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#properties-tab"
              onClick={() => handleTabClick('properties')}
              className={`nav-link ${activeTab === 'properties' ? 'active' : ''}`}
            >
              Properties
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#logs-tab"
              onClick={() => handleTabClick('logs')}
              className={`nav-link ${activeTab === 'logs' ? 'active' : ''}`}
            >
              Logs
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#configs-tab"
              onClick={() => handleTabClick('configs')}
              className={`nav-link ${activeTab === 'configs' ? 'active' : ''}`}
            >
              Configs
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#misc-tab"
              onClick={() => handleTabClick('misc')}
              className={`nav-link ${activeTab === 'misc' ? 'active' : ''}`}
            >
              Misc
            </a>
          </li>
        </ul>
      </div>
      <div className="tab-content panel rounded-0 p-3 m-0">
        <div
          className={`tab-pane fade ${activeTab === 'users' ? 'active show' : ''}`}
          id="users-tab"
        >
        {activeTab === 'users' && <Users />}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'characters' ? 'active show' : ''}`}
          id="characters-tab"
        >
        {activeTab === 'characters' && <Characters />}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'properties' ? 'active show' : ''}`}
          id="properties-tab"
        >
          {activeTab === 'properties' && <Properties />}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'logs' ? 'active show' : ''}`}
          id="logs-tab"
        >
          {activeTab === 'logs' && <Logs />}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'configs' ? 'active show' : ''}`}
          id="configs-tab"
        >
          {activeTab === 'configs' && <Configs />}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'misc' ? 'active show' : ''}`}
          id="misc-tab"
        >
          {activeTab === 'misc' && <Misc />}
        </div>
      </div>
    </div>
    </RoleGuard>
  );
}

export default AdminPanel;
