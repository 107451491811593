import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  usePropertyDetailsQuery,
  usePropertyOwnershipCheckMutation,
  usePlayDiceMutation,
} from '../../api/game';
import PropertyPanel from './propertyPanel';
import Response from '../../shared-components/response';
import './dice.css';
import { formatNumber } from '../../shared-components/numberFormat';

const DiceFace = ({ number }) => {
  const dots = Array(number).fill(null);
  return (
    <div className="dice-face">
      {dots.map((_, index) => (
        <div key={index} className="dice-dot"></div>
      ))}
    </div>
  );
};

const Dice = () => {
  const { data: propertyDetails, isLoading, refetch } = usePropertyDetailsQuery('dice');
  const [checkOwnership, { data: ownershipData }] = usePropertyOwnershipCheckMutation();
  const [bet, setBet] = useState('');
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [playDice, { data: diceResult }] = usePlayDiceMutation();

  useEffect(() => {
    checkOwnership({ property: 'dice' });
  }, [checkOwnership]);

  const handlePropertyUpdate = async () => {
    await refetch();
    await checkOwnership({ property: 'dice' });
  };

  const handleSubmit = async diceNumber => {
    try {
      const result = await playDice({ bet, diceNumber }).unwrap();
      setAlertData({ message: result.message, type: result.type });
    } catch (error) {
      console.error('Error playing dice:', error);
      setAlertData({ message: 'Error playing dice', type: 'danger' });
    }
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div>
      <Response
        message={alertData.message}
        type={alertData.type}
        diceResult={diceResult?.result}
      />
      <div className="tab-content rounded-0 m-0">
        {ownershipData?.isOwner ? (
          <PropertyPanel 
            property="dice" 
            onPropertyUpdate={handlePropertyUpdate}
          />
        ) : propertyDetails.owner === null ? (
          <div>
            <p>
              Nobody owns this property. You can pick it up at the 
              <Link to="/game/general/properties">locations page</Link>.
            </p>
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title text-center">Dice</h3>
                  <input
                    type="number"
                    className="form-control mb-3"
                    value={bet}
                    onChange={e => setBet(e.target.value)}
                    placeholder="Enter your bet"
                  />
                  <div className="dice-container">
                    {[1, 2, 3, 4, 5, 6].map(number => (
                      <div
                        key={number}
                        className="dice-box"
                        onDoubleClick={() => handleSubmit(number)}
                      >
                        <DiceFace number={number} />
                      </div>
                    ))}
                  </div>
                  <div className="dice-info mt-3">
                    <p>Max bet: £{formatNumber(propertyDetails.maxBet)}</p>

                    <p>
                      Owned by:
                      {propertyDetails.owner ? (
                        <Link
                          to={`/game/general/userprofile/${propertyDetails.owner}`}
                        >
                          {propertyDetails.owner}
                        </Link>
                      ) : (
                        'None'
                      )}
                    </p>
                    <p>Double-click on a die to place your bet!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Dice;
