import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useGameChatListQuery,
  useGameChatSendMutation,
} from '../../api/game';
import { useGlobalState } from '../../auth/globalState';

function ChatArea({ isOpen }) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isChatActive, setIsChatActive] = useState(false);
  const { globalState } = useGlobalState();

  const { data: chatData, isLoading } = useGameChatListQuery(undefined, {
    skip: !globalState.character_uuid || !isChatActive,
  });
  const [sendMessage] = useGameChatSendMutation();

  useEffect(() => {
    if (chatData) {
      // Convert object to array and filter out inPrison key
      const messageArray = Object.values(chatData)
        .filter(item => item.uuid && item.content);
      setMessages(messageArray);
    } else {
      setMessages([]);
    }
  }, [chatData]);
  

  useEffect(() => {
    if (isOpen) {
      setIsChatActive(true);
    }
  }, [isOpen]);

  const handleSend = async () => {
    if (newMessage.trim() && globalState.character_uuid) {
      try {
        await sendMessage({ message: newMessage });
        setNewMessage('');
      } catch (error) {
        console.error('Failed to send message:', error);
      }
    }
  };

  if (!globalState.character_uuid) {
  }

  if (isLoading) {
    return <div>Loading chat...</div>;
  }

  return (
    <div className={`chat-area ${isOpen ? 'open' : ''}`} data-bs-theme="dark">
      <div className="chat-header">Game Chat</div>
      <PerfectScrollbar
        className="chat-messages"
        options={{ suppressScrollX: true }}
      >
{messages.map(msg => (
  <div key={msg.uuid} className="chat-bubble">
    <div className="chat-username">{msg.username}</div>
    <div className="chat-message">{msg.content}</div>
    <div className="chat-time">
      {new Date(msg.date).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      })}
    </div>
  </div>
))}


      </PerfectScrollbar>
      <div className="chat-input">
        <input
          type="text"
          value={newMessage}
          onChange={e => setNewMessage(e.target.value)}
          placeholder="Type a message..."
        />
        <button onClick={handleSend}>Send</button>
      </div>
    </div>
  );
}

export default ChatArea;
