import React, { useState, useEffect } from 'react';
import {
  useFoundryBuyMutation,
  useFoundryStockQuery,
} from '../../api/game';
import Response from '../../shared-components/response';

function Foundry() {
  const [bullets, setBullets] = useState('');
  const [buyFromFoundry] = useFoundryBuyMutation();
  const { data: foundryData, refetch } = useFoundryStockQuery();
  const [response, setResponse] = useState({ message: '', type: '' });
  const [maxPurchase, setMaxPurchase] = useState(1000);

  useEffect(() => {
    if (foundryData?.stock > 5000) {
      setMaxPurchase(1000);
    } else {
      setMaxPurchase(500);
    }
  }, [foundryData?.stock]);

  const handlePurchase = async () => {
    try {
      const result = await buyFromFoundry({
        amount: parseInt(bullets),
      }).unwrap();
      setResponse({ message: result.message, type: result.type });
      if (result.type === 'success') {
        setBullets('');
        refetch(); // Refresh the stock data after successful purchase
      }
    } catch (err) {
      setResponse({ message: 'Failed to purchase bullets', type: 'danger' });
    }
  };

  return (
    <div className="row">
      <div className="col-lg-3"></div>
      <div className="col-lg-6">
        <div className="card">
          <div className="card-header text-center">The Foundry</div>
          <div className="card-body">
            <div className="text-center mb-4">
              The Foundry will be stocked at random times throughout the day!
              Keep checking back!
            </div>

            <div className="text-center mb-4">
              <input
                type="number"
                className="form-control d-inline-block w-50 me-2"
                min="0"
                max={maxPurchase}
                step="250"
                placeholder={`Enter Amount (Max: ${maxPurchase})`}
                value={bullets}
                onChange={e => setBullets(e.target.value)}
              />
              <button
                className="btn btn-primary"
                onClick={handlePurchase}
                disabled={!bullets || bullets <= 0}
              >
                Purchase
              </button>
            </div>

            <div className="text-center">
              There are currently{' '}
              <u>{foundryData?.stock?.toLocaleString() || 0}</u> bullets in
              stock at the price of{' '}
              <u>£{foundryData?.price?.toLocaleString() || 500}</u> per bullet!
            </div>

            <Response message={response.message} type={response.type} />
          </div>
        </div>
      </div>
      <div className="col-lg-3"></div>
    </div>
  );
}export default Foundry;
