import React, { useState, useMemo } from 'react';
import {
  useBuyScratchcardMutation,
  useListRecentScratchcardsQuery,
} from '../../api/game';
import Response from '../../shared-components/response';
import { useGlobalState } from '../../auth/globalState';
import { formatNumber } from '../../shared-components/numberFormat';
import './scratchcards.css';
const ScratchPanel = ({ onComplete, result, tier }) => {
  const [revealed, setRevealed] = useState(Array(9).fill(false));
  const [isComplete, setIsComplete] = useState(false);
  
  const tierMultipliers = {
    bronze: 1,
    silver: 3, 
    gold: 7
  };

  const multiplier = tierMultipliers[tier.toLowerCase()];

  const losingRewards = useMemo(() => {
    const possibleRewards = [
      { type: 'bullets', amount: 1000 * multiplier },
      { type: 'kevlars', amount: 500 * multiplier },
      { type: 'money', amount: 2500000 * multiplier },
      { type: 'lockpicks', amount: 50 * multiplier },
      { type: 'rankpoints', amount: 500 * multiplier },
      { type: 'points', amount: 4 * multiplier }
    ].filter(reward => 
      reward.type !== result.type || reward.amount !== result.amount
    );
    
    return Array(9).fill(null).map(() => 
      possibleRewards[Math.floor(Math.random() * possibleRewards.length)]
    );
  }, [multiplier, result]);
  
  const winningPositions = useMemo(() => {
    const positions = new Set();
    while(positions.size < 3) {
      positions.add(Math.floor(Math.random() * 9));
    }
    return positions;
  }, []);

  // Log to verify the winning positions
  console.log('Tier:', tier);
  console.log('Winning Positions:', winningPositions);

  const formatReward = (reward) => {
    if (!reward) return '';
    return `${reward.amount.toLocaleString()} ${reward.type}`;
  };

  const getCellContent = (index) => {
    if (!revealed[index]) return '';
    
    if (winningPositions.has(index)) {
      return formatReward(result);
    }
    
    const losingReward = losingRewards[index];
    return `${losingReward.amount.toLocaleString()} ${losingReward.type}`;
  };

  const handleReveal = (index) => {
    const newRevealed = [...revealed];
    newRevealed[index] = true;
    
    // First set the new revealed state
    setRevealed(newRevealed);
    
    // Then count how many winning positions are now revealed
    const revealedWinningCount = [...winningPositions].filter(pos => newRevealed[pos]).length;

    if (revealedWinningCount === 3) {
      // Reveal all remaining cells
      setRevealed(Array(9).fill(true));
      setIsComplete(true);
      onComplete();
    }
  };

  const getCellStyle = (index) => {
    if (!revealed[index]) {
      return { backgroundColor: '#CD7F32' }; // Unscratched color
    }
  
    return {
      backgroundColor: '#FFE5B4', // All revealed boxes will be this color
      color: '#000000', // Black text for all revealed boxes
      cursor: !isComplete ? 'pointer' : 'default'
    };
  };  return (
    <div className="card mb-4">
      <div className="card-header">
        <h3 className="text-center mb-0">Scratch to Reveal</h3>
      </div>
      <div className="card-body">
        <div className="scratch-grid">
          {[...Array(9)].map((_, i) => (
            <div 
              key={i}
              className={`scratch-cell ${revealed[i] ? 'revealed' : ''}`}
              onClick={() => !isComplete && handleReveal(i)}
              style={getCellStyle(i)}
            >
              {getCellContent(i)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Scratchcards = () => {
  const [playScratchcard] = useBuyScratchcardMutation();
  const { data: lastScratchcardsData, refetch: refetchLastScratchcards } = useListRecentScratchcardsQuery();
  const lastScratchcards = lastScratchcardsData ? Object.values(lastScratchcardsData).filter(item => item.type) : [];
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [activePanel, setActivePanel] = useState(null);
  const { updateTimer } = useGlobalState();
  const [showScratchPanel, setShowScratchPanel] = useState(false);
const handlePlay = async tier => {
  try {
    const result = await playScratchcard({ tier }).unwrap();
    
    // Only show error messages immediately
    if (result.type === 'danger') {
      setAlertData({ message: result.message, type: result.type });
      return;
    }
    
    // For success, don't set alert message yet
    if (result.type === 'success' && result.showPanel === true && result.reward) {
      setShowScratchPanel(true);
      setActivePanel({
        tier: tier, // Make sure this matches the case of your tier names
        result: {
          ...result,
          reward: {
            type: result.reward.type,
            amount: result.reward.amount
          }
        }
      });
      
      if (result.time) {
        updateTimer('scratchcard', result.time);
      }
    }
  } catch (error) {
    setAlertData({ message: 'Error playing scratchcard', type: 'danger' });
  }
};

const handlePanelComplete = () => {
  // Now show the success message after panel completion
  setAlertData({ 
    message: activePanel.result.message, 
    type: activePanel.result.type 
  });
  setShowScratchPanel(false);
  setActivePanel(null);
  refetchLastScratchcards();
};
  const scratchcardTiers = [
    { name: 'Bronze', cost: formatNumber(1000000), color: '#CD7F32' },
    { name: 'Silver', cost: formatNumber(5000000), color: '#C0C0C0' },
    { name: 'Gold', cost: formatNumber(10000000), color: '#FFD700' },
  ];

  return (
    <div>
      <Response message={alertData.message} type={alertData.type} />
      {showScratchPanel && activePanel && (
        <div className="row justify-content-center">
          <div className="col-lg-4">
            <ScratchPanel 
              onComplete={handlePanelComplete}
              result={activePanel.result.reward}
              tier={activePanel.tier}
            />
          </div>
        </div>
      )}
      
      {/* Only show tier options when not scratching */}
      {!showScratchPanel && (
        <div className="tab-content rounded-0 m-0">
          <div className="row">
            {scratchcardTiers.map(tier => (
              <div className="col-lg-4" key={tier.name}>
                <div className="card mb-3" style={{ borderColor: tier.color }}>
                  <img
                    src="https://placehold.co/600x400"
                    className="card-img-top"
                    alt={`${tier.name} Scratchcard`}
                  />
                  <div className="card-body">
                    <h3 className="card-title text-center">
                      {tier.name} Scratchcard
                    </h3>
                    <div className="d-flex flex-column align-items-center">
                      <button
                        onClick={() => handlePlay(tier.name.toLowerCase())}
                        className="btn btn-primary mb-2"
                        style={{
                          backgroundColor: tier.color,
                          borderColor: tier.color,
                        }}
                      >
                        Buy and Scratch
                      </button>
                      <p className="mb-0">Price: £{tier.cost.toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Recent scratchcards table */}
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <div className="card mt-3">
            <div className="card-body">
              <h4>Last 10 Scratchcards</h4>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Reward</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                {lastScratchcards.map((scratchcard, index) => (
                  <tr key={index}>
                    <td>{scratchcard.type}</td>
                    <td>{scratchcard.reward}</td>
                    <td>{new Date(scratchcard.date).toLocaleString()}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Scratchcards;
