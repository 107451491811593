import React, { useState } from 'react';
import { useFamilyProfileQuery } from '../../api/game';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

const FamilyProfile = () => {
    const { "*": fullPath } = useParams();
    // Extract just the family name by removing the 'famprofile/' prefix
    const familyName = fullPath.replace('famprofile/', '');
    console.log('Family Name from params:', familyName); // Add this for debugging

    const [activeTab, setActiveTab] = useState('bio');
    const { data: familyProfile, isLoading } = useFamilyProfileQuery(decodeURIComponent(familyName));

  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="row">
      <div className="col-md-12 mb-4">
        <div className="card">
          <div className="card-header">
            <h3 className="m-0 text-center">{familyProfile.name}</h3>
          </div>
          <div className="card-body" style={{ backgroundColor: '#3f3f3f' }}>
            <div className="row">
              <div className="col-md-4">
              <table className="table">
  <tbody>
    <tr>
      <td colSpan={2}>
        <img 
          src={familyProfile.profileImage}
          alt={familyProfile.name}
          style={{ 
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: 'block'
          }}
        />
      </td>
    </tr>
    <tr>
      <td className="tablesubheader">Leader:</td>
      <td>
        <Link to={`/game/general/userprofile/${familyProfile.leaderName}`}>
          {familyProfile.leaderName}
        </Link>
      </td>
    </tr>
    <tr>
      <td className="tablesubheader">Recruiting:</td>
      <td>
        <span style={{ color: familyProfile.recruiting ? 'green' : 'red' }}>
          {familyProfile.recruiting ? 'Yes' : 'No'}
        </span>
      </td>
    </tr>
    <tr>
      <td className="tablesubheader">Wealth:</td>
      <td>{familyProfile.wealthRank}</td>
    </tr>
    <tr>
      <td className="tablesubheader">Location:</td>
      <td>{familyProfile.location}</td>
    </tr>
    <tr>
      <td className="tablesubheader">Founded:</td>
      <td>{new Date(familyProfile.createdAt).toLocaleDateString()}</td>
    </tr>
    <tr>
      <td colSpan={2}>
        <img 
  src={familyProfile.bannerImage || "https://uk-mafia.co.uk/pages/misc/upload/upload/677a9fc94b68f8.28834238_eqfmopgkjlhin.png"}
  alt="Family Banner"
          style={{ 
            width: '100%',
            height: '75px',
            display: 'block',
          }}
        />
      </td>
    </tr>
  </tbody>
</table>

              </div>
              <div className="col-md-8">
                <div className="tabs-wrapper">
                  <ul className="nav nav-tabs flex-nowrap">
                    <li className="nav-item">
                      <a
                        href="#bio-tab"
                        onClick={() => handleTabClick('bio')}
                        className={`nav-link ${activeTab === 'bio' ? 'active' : ''}`}
                      >
                        Bio
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#info-tab"
                        onClick={() => handleTabClick('info')}
                        className={`nav-link ${activeTab === 'info' ? 'active' : ''}`}
                      >
                        Info
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#members-tab"
                        onClick={() => handleTabClick('members')}
                        className={`nav-link ${activeTab === 'members' ? 'active' : ''}`}
                      >
                        Members
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="tab-content panel rounded-0 p-3 m-0">
                  <div className={`tab-pane fade ${activeTab === 'bio' ? 'active show' : ''}`}>
                    <table className="table">
                      <tbody>
                        <tr>
<td align="center" className="tablesubheader">
  <div style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>
    {familyProfile.bio}
  </div>
</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className={`tab-pane fade ${activeTab === 'info' ? 'active show' : ''}`}>
                    <table className="table">
                      <tbody>
                        <tr>
                          <td align="center" className="tablesubheader">Size: {familyProfile.size.toLocaleString()}</td>
                        </tr>
                        <tr>
                          <td align="center" className="tablesubheader">Businesses: {familyProfile.businessCount.toLocaleString()}</td>
                        </tr>
                        <tr>
                          <td align="center">{familyProfile.familyTake}% Family Take</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
<div className={`tab-pane fade ${activeTab === 'members' ? 'active show' : ''}`}>
  <table className="table">
    <thead>
      <tr>
        <th>Member</th>
        <th>Rank</th>
      </tr>
    </thead>
    <tbody>
      {familyProfile.members?.map(member => (
        <tr key={member.username}>
          <td>
            <Link to={`/game/general/userprofile/${member.username}`}>
              {member.username}
            </Link>
          </td>
          <td>{`${member.game_rank.rankName} - ${member.game_rank.rankNumber}`}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>




                </div>
              </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};
export default FamilyProfile;