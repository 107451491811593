import React, { useEffect } from 'react';
import {
  useLayoutGetNotificationsQuery,
  useLayoutClearNotificationsMutation,
  useLayoutReadSingleNotificationMutation,
} from '../../../api/game';
import { useGlobalState } from '../../../auth/globalState';
import { Link } from 'react-router-dom';

function DropdownNotification() {
  const { globalState } = useGlobalState();
  const [readAllNotifications] = useLayoutClearNotificationsMutation();
  const [readSingleNotification] = useLayoutReadSingleNotificationMutation();

  const { data, refetch } = useLayoutGetNotificationsQuery(
    undefined,
    {
      skip: !globalState.character_uuid,
    }
  );

  useEffect(() => {
    if (globalState.character_uuid) {
      const interval = setInterval(() => {
        refetch();
      }, 10000); // 10 seconds

      return () => clearInterval(interval);
    }
  }, [refetch, globalState.character_uuid]);

  // Then ensure our count calculations are correct
  const unreadCount = data?.notifications?.length || 0;
  const unreadMessageCount = data?.unreadMessages || 0;

  /*
  const handleDropdownOpen = async () => {
    if (data?.notifications?.length > 0) {
      await readAllNotifications();
      refetch();
    }
  };
*/
  if (!globalState.character_uuid) {
    return null; // Don't render anything if character_uuid is null
  }

  return (
    <div className="navbar-item dropdown d-flex">
      <Link to="/game/messaging/inbox" className="navbar-link icon ms-3">
        <i
          className="fa fa-envelope fa-2x"
          style={{
            fontSize: '2em',
            color: unreadMessageCount > 0 ? '#ff0000' : 'inherit',
          }}
        ></i>
      </Link>

      <a
        href="#/"
        data-bs-toggle="dropdown"
        className="navbar-link dropdown-toggle icon"
      >
        <i className="fa fa-bell fa-2x" style={{ fontSize: '2em' }}></i>
        {unreadCount > 0 && <span className="badge">{unreadCount}</span>}
      </a>

      <div className="dropdown-menu media-list dropdown-menu-end">
        <div className="dropdown-header">
          NOTIFICATIONS ({data?.notifications ? data.notifications.length : 0})
        </div>
        {data?.notifications && data.notifications.length > 0 ? (
          data.notifications.map(notification => (
<a
  href={notification.link}
  className="dropdown-item media"
  key={notification.uuid}
  onClick={async (e) => {
    if (!notification.link) e.preventDefault();
    await readSingleNotification({ notificationId: notification.uuid });
    refetch();
  }}
>
              <div className="media-left">
                <i
                  className={`fa ${notification.icon} media-object bg-gray-500`}
                ></i>
              </div>
              <div className="media-body">
                <h6 className="media-heading">{notification.title}</h6>
                <p>{notification.description}</p>
                <div className="text-muted fs-10px">
                  {new Date(notification.time).toLocaleString()}
                </div>
              </div>
            </a>
          ))
        ) : (
          <div className="dropdown-item">You have no notifications!</div>
        )}
        <div className="dropdown-footer text-center">
          <a
            href="#/"
            className="text-decoration-none"
            onClick={async e => {
              e.preventDefault();
              await readAllNotifications();
              refetch();
            }}
          >
            Mark all as read
          </a>
        </div>
      </div>
    </div>
  );
}

export default DropdownNotification;
