import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  usePropertyDetailsQuery,
  usePropertyOwnershipCheckMutation,
  usePlayKenoMutation,
} from '../../api/game';
import PropertyPanel from './propertyPanel';
import Response from '../../shared-components/response';
import './keno.css';
import { formatNumber } from '../../shared-components/numberFormat';

const Keno = () => {
  const { data: propertyDetails, isLoading, refetch } = usePropertyDetailsQuery('keno');
  const [checkOwnership, { data: ownershipData }] = usePropertyOwnershipCheckMutation();
  const [bet, setBet] = useState('');
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [playKeno, { data: kenoResult }] = usePlayKenoMutation();
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [winningNumbers, setWinningNumbers] = useState([]);

  useEffect(() => {
    checkOwnership({ property: 'keno' });
  }, [checkOwnership]);

  const handlePropertyUpdate = async () => {
    await refetch();
    await checkOwnership({ property: 'keno' });
  };

  const toggleNumber = number => {
    if (selectedNumbers.includes(number)) {
      setSelectedNumbers(selectedNumbers.filter(num => num !== number));
    } else if (selectedNumbers.length < 10) {
      setSelectedNumbers([...selectedNumbers, number]);
    }
  };

  const handleSubmit = async () => {
    try {
      const result = await playKeno({ bet, selectedNumbers }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      setWinningNumbers(result.result.winningNumbers);
    } catch (error) {
      console.error('Error playing keno:', error);
      setAlertData({ message: 'Error playing keno', type: 'danger' });
    }
  };

  const renderKenoCard = () => {
    const rows = [];
    for (let i = 0; i < 8; i++) {
      const row = [];
      for (let j = 1; j <= 10; j++) {
        const number = i * 10 + j;
        row.push(
          <input
            key={number}
            type="button"
            value={number}
            onClick={() => toggleNumber(number)}
            className={`kenonumber ${selectedNumbers.includes(number) ? 'selected' : ''} ${winningNumbers.includes(number) ? 'winning' : ''}`}
          />
        );
      }
      rows.push(
        <div key={i} className="kenorow">
          {row}
        </div>
      );
    }
    return rows;
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div>
      <Response
        message={alertData.message}
        type={alertData.type}
        kenoResult={kenoResult?.result}
      />

      <div className="tab-content rounded-0 m-0">
        {ownershipData?.isOwner ? (
          <PropertyPanel property="keno" onPropertyUpdate={handlePropertyUpdate} />
        ) : propertyDetails.owner === null ? (
          <div>
            <p>
              Nobody owns this property. You can pick it up at the&nbsp;
              <Link to="/game/general/properties">locations page</Link>.
            </p>
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title text-center">Keno</h3>
                  <div className="kenocontainer">{renderKenoCard()}</div>
                  <div className="d-flex mb-3 mt-3">
                    <input
                      type="number"
                      className="form-control mr-2"
                      value={bet}
                      onChange={e => setBet(e.target.value)}
                      placeholder="Enter bet amount"
                    />
                    <button onClick={handleSubmit} className="btn btn-primary">
                      Play
                    </button>
                  </div>
                  <hr />
                  <p>Max bet: £{formatNumber(propertyDetails.maxBet)}</p>
                  <p>Jackpot: {formatNumber(propertyDetails.jackpot)}</p>
                  <p>
                    Owned by:
                    {propertyDetails.owner ? (
                      <Link
                        to={`/game/general/userprofile/${propertyDetails.owner}`}
                      >
                        {propertyDetails.owner}
                      </Link>
                    ) : (
                      'None'
                    )}
                  </p>
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-body">
                  <h4>Payout Table</h4>
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th>Matches</th>
                        <th>Payout</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>2</td>
                        <td>0.5x Bet</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>4x Bet</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>20x Bet</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>80x Bet</td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>150x Bet</td>
                      </tr>
                      <tr>
                        <td>7+</td>
                        <td>300x Bet + Jackpot</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-4"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Keno;
