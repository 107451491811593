// PropertyPanel.jsx
import React, { useState } from 'react';
import {
  usePropertyDetailsQuery,
  usePropertyUpdateMutation,
} from '../../api/game';
import Response from '../../shared-components/response';

const PropertyPanel = ({ property, onPropertyUpdate }) => {
  const { data: propertyDetails, isLoading, refetch } = usePropertyDetailsQuery(property);
  const [updateProperty] = usePropertyUpdateMutation();
  const [maxBetOrPrice, setMaxBetOrPrice] = useState('');
  const [transferTo, setTransferTo] = useState('');
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  const isFactory = propertyDetails?.name?.toLowerCase().includes('factory');

  const handlePropertyUpdate = async (updateFunction) => {
    try {
      const response = await updateFunction();
      setAlertData({ message: response.message, type: response.type });
      if (response.type === 'success') {
        await refetch(); // Refresh the property details
        if (onPropertyUpdate) {
          onPropertyUpdate(); // Notify parent component
        }
      }
    } catch (error) {
      setAlertData({ message: error.message, type: error.type });
    }
  };

  const handleSetMaxBetOrPrice = () => handlePropertyUpdate(async () => {
    return await updateProperty({
      propertyId: property,
      [isFactory ? 'price' : 'maxBet']: maxBetOrPrice,
    }).unwrap();
  });

  const handleTransferProperty = () => handlePropertyUpdate(async () => {
    return await updateProperty({
      propertyId: property,
      newOwner: transferTo,
    }).unwrap();
  });

  const handleDropProperty = () => handlePropertyUpdate(async () => {
    return await updateProperty({
      propertyId: property,
      drop: true,
    }).unwrap();
  });

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="property-panel card col-md-6">
    <Response message={alertData.message} type={alertData.type} />
    <div className="card-header">
      <h2>{propertyDetails.name}</h2>
    </div>
    <div className="card-body">
      <div className="form-group">
        <label>Total Profit:</label> £{propertyDetails.totalProfit.toLocaleString()}
      </div>
      <hr />
      <div className="form-group">
        <label>{isFactory ? 'Set Price:' : 'Set Max Bet:'}</label>
        <input
          type="number"
          className="form-control"
          value={maxBetOrPrice}
          onChange={e => setMaxBetOrPrice(e.target.value)}
          placeholder={
            isFactory
              ? `Current price is £${propertyDetails.price || 0}`
              : `Current max bet is £${propertyDetails.maxBet || 0}`
          }
        />
        <button className="btn btn-primary mt-2" onClick={handleSetMaxBetOrPrice}>
          {isFactory ? 'Set Price' : 'Set Max Bet'}
        </button>
      </div>
      <hr />
      <div className="form-group mt-3">
        <label>Current Owner: {propertyDetails.owner}</label>
        <input
          type="text"
          className="form-control"
          value={transferTo}
          onChange={e => setTransferTo(e.target.value)}
        />
        <button className="btn btn-warning mt-2" onClick={handleTransferProperty}>
          Transfer Property
        </button>
      </div>
      <hr />
      <div className="mt-4">
        <button className="btn btn-danger" onClick={handleDropProperty}>
          Drop Property
        </button>
      </div>
    </div>
  </div>
  );
};

export default PropertyPanel;
