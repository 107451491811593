import React, { useState, useEffect } from 'react';
import {
  useSyndicateCheckQuery,
  useSyndicateStartMutation,
  useSyndicateInviteMutation,
  useSyndicateListInvitesQuery,
  useSyndicateResponseToInviteMutation,
  useSyndicateDataQuery,
  useSyndicateLeaveMutation,
} from '../../api/game';
import Response from '../../shared-components/response';

const Syndicates = () => {
  const [activeTab, setActiveTab] = useState('start');
  const { data: syndicateStatus, isLoading } = useSyndicateCheckQuery();
  const [startSyndicate] = useSyndicateStartMutation();
  const { data: invites } = useSyndicateListInvitesQuery();
  const [respondToInvite] = useSyndicateResponseToInviteMutation();
  const [inviteUsername, setInviteUsername] = useState('');
  const { data: syndicateData } = useSyndicateDataQuery();
  const [inviteToSyndicate] = useSyndicateInviteMutation();
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [showLeaveConfirm, setShowLeaveConfirm] = useState(false);
  const [leaveSyndicate] = useSyndicateLeaveMutation();

  useEffect(() => {
    if (syndicateStatus?.inSyndicate) {
      setActiveTab('main');
    }
  }, [syndicateStatus]);

  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };

  const handleStartSyndicate = async () => {
    const result = await startSyndicate();
    if (result.data?.type === 'success') {
      // Refetch syndicate status
    }
  };

  if (isLoading) return <p>Loading...</p>;

  if (!syndicateStatus?.inSyndicate) {
    return (
      <div className="family-tabs-container">
        <div className="tabs-wrapper">
          <ul className="nav nav-tabs flex-nowrap">
            <li className="nav-item">
              <a
                href="#start-tab"
                onClick={() => handleTabClick('start')}
                className={`nav-link ${activeTab === 'start' ? 'active' : ''}`}
              >
                Start
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#invites-tab"
                onClick={() => handleTabClick('invites')}
                className={`nav-link ${activeTab === 'invites' ? 'active' : ''}`}
              >
                Invites
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content panel rounded-0 p-3 m-0">
          <div
            className={`tab-pane fade ${activeTab === 'start' ? 'active show' : ''}`}
            id="start-tab"
          >
            <h3>Start a Syndicate</h3>
            <div className="card">
              <div className="card-body">
                <p>
                  Here you can start a syndicate, where you and your team can do
                  crimes and share items, and also build up juicy pots to
                  collect and split between your team! It requires a team of 3
                  people to start building your pot, and it will build up
                  automatically, you can't kick members, but they can choose to
                  leave, for a price
                </p>
                <div className="mt-4">
                  <p className="fw-bold">Cost to start: £100,000</p>
                  <button
                    className="btn btn-primary"
                    onClick={handleStartSyndicate}
                  >
                    Start Syndicate!
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`tab-pane fade ${activeTab === 'invites' ? 'active show' : ''}`}
            id="invites-tab"
          >
            <h3>Syndicate Invites</h3>
            {Array.isArray(invites) && invites.map(invite => (
  <div key={invite.uuid} className="card mb-3">
    <div className="card-body">
      <h5>Invite from {invite.syndicate.leader.username}'s Syndicate</h5>
      <div className="mt-3">
        <button
          className="btn btn-success me-2"
          onClick={() =>
            respondToInvite({
              inviteUuid: invite.uuid,
              accept: true,
            })
          }
        >
          Accept
        </button>
        <button
          className="btn btn-danger"
          onClick={() =>
            respondToInvite({
              inviteUuid: invite.uuid,
              accept: false,
            })
          }
        >
          Decline
        </button>
      </div>
    </div>
  </div>
))}
{(!invites || !Array.isArray(invites) || invites.length === 0) && <p>No pending invites</p>}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="family-tabs-container">
      <div className="tabs-wrapper">
        <ul className="nav nav-tabs flex-nowrap">
          <li className="nav-item">
            <a
              href="#main-tab"
              onClick={() => handleTabClick('main')}
              className={`nav-link ${activeTab === 'main' ? 'active' : ''}`}
            >
              Main
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#items-tab"
              onClick={() => handleTabClick('items')}
              className={`nav-link ${activeTab === 'items' ? 'active' : ''}`}
            >
              Items
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#stats-tab"
              onClick={() => handleTabClick('stats')}
              className={`nav-link ${activeTab === 'stats' ? 'active' : ''}`}
            >
              Stats
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#crimes-tab"
              onClick={() => handleTabClick('crimes')}
              className={`nav-link ${activeTab === 'crimes' ? 'active' : ''}`}
            >
              Crimes
            </a>
          </li>
        </ul>
      </div>
      <div className="tab-content panel rounded-0 p-3 m-0">
        <div
          className={`tab-pane fade ${activeTab === 'main' ? 'active show' : ''}`}
          id="main-tab"
        >
          <h3>Syndicate Main</h3>
          <div className="card mb-3">
            <Response message={alertData.message} type={alertData.type} />

            <div className="card-body">
              <div className="row mb-4">
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="mb-0">Leader</h5>
                    </div>
                    <div className="card-body">
                      {syndicateData?.leader?.username}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="mb-0">Member 2</h5>
                    </div>
                    <div className="card-body">
                      {syndicateData?.members?.[0]?.username || 'None'}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="mb-0">Member 3</h5>
                    </div>
                    <div className="card-body">
                      {syndicateData?.members?.[1]?.username || 'None'}
                    </div>
                  </div>
                </div>
              </div>

              {syndicateData?.members?.length < 3 &&
                syndicateData?.isLeader && (
                  <div className="mt-4">
                    <h5>Invite Member</h5>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Username"
                        value={inviteUsername}
                        onChange={e => setInviteUsername(e.target.value)}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={async () => {
                          const result = await inviteToSyndicate({
                            username: inviteUsername,
                          }).unwrap();
                          setAlertData({
                            message: result.message,
                            type: result.type,
                          });
                          setInviteUsername('');
                        }}
                      >
                        Send Invite
                      </button>
                    </div>
                  </div>
                )}

              <div className="row mt-4">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="mb-0">Syndicate Pot</h5>
                    </div>
                    <div className="card-body">
                      <p>
                        <strong>Money:</strong> £
                        {syndicateData?.pot?.money?.toLocaleString()}
                      </p>
                      <p>
                        <strong>Bullets:</strong>{' '}
                        {syndicateData?.pot?.bullets?.toLocaleString()}
                      </p>
                      <p>
                        <strong>Kevlar:</strong>{' '}
                        {syndicateData?.pot?.kevlars?.toLocaleString()}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="mb-0">Leave Syndicate</h5>
                    </div>
                    <div className="card-body text-center">
                      <button
                        className="btn btn-danger"
                        onClick={() => setShowLeaveConfirm(true)}
                      >
                        Costs £1,000,000
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {showLeaveConfirm && (
                <div
                  className="modal d-block"
                  style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Confirm Leave Syndicate</h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={() => setShowLeaveConfirm(false)}
                        ></button>
                      </div>
                      <div className="modal-body">
                        Are you sure? Leaving syndicate will cost you £1,000,000
                      </div>
                      <div className="modal-footer">
                        <button
                          className="btn btn-secondary"
                          onClick={() => setShowLeaveConfirm(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={async () => {
                            const result = await leaveSyndicate().unwrap();
                            setAlertData({
                              message: result.message,
                              type: result.type,
                            });
                            setShowLeaveConfirm(false);
                          }}
                        >
                          Leave Syndicate
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'items' ? 'active show' : ''}`}
          id="items-tab"
        >
          <h3>Syndicate Items</h3>
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'stats' ? 'active show' : ''}`}
          id="stats-tab"
        >
          <h3>Syndicate Stats</h3>
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'crimes' ? 'active show' : ''}`}
          id="crimes-tab"
        >
          <h3>Syndicate Crimes</h3>
        </div>
      </div>
    </div>
  );
};

export default Syndicates;
