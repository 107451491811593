import { apiSlice } from "../auth/apiSlice";

export const gameApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    //global ListLocations access
    ListLocations: builder.query({ query: () => ({ body: { functionName: 'listLocations' } }) }),
    //before master account login
    MasterAccountLogin: builder.mutation({ query: credentials => ({ body: { functionName: 'MasterLogin', params: credentials } }) }),
    MasterAccountRegister: builder.mutation({ query: body => ({ body: { functionName: 'MasterRegister', params: body } }) }),
    MasterAccountResetPassword: builder.mutation({ query: body => ({ body: { functionName: 'MasterResetpass', params: body } }) }),
    //after master account login
    MasterAccountListCharacters: builder.query({ query: () => ({ body: { functionName: 'MasterListCharacters' } }), providesTags: ['character_select_page'] }),
    MasterAccountSelectCharacter: builder.mutation({ query: body => ({ body: { functionName: 'MasterSelectCharacter', params: body } }) }),
    MasterAccountCreateCharacter: builder.mutation({ query: body => ({ body: { functionName: 'MasterCreateCharacter', params: body } }) }),
    //after character login
    LayoutGetTimers: builder.query({ query: () => ({ body: { functionName: 'Layout_timers' } }), providesTags: ['timers_page'] }),
    LayoutGetStatsfeed: builder.query({ query: () => ({ body: { functionName: 'Layout_statsfeed' } }), providesTags: ['statsfeed'] }),
    LayoutGetNotifications: builder.query({ query: () => ({ body: { functionName: 'Layout_notifications' } }), providesTags: ['notifications'] }),
    LayoutClearNotifications: builder.mutation({ query: body => ({ body: { functionName: 'Layout_clearNotifications', params: body } }) }),
    LayoutReadSingleNotification: builder.mutation({       query: body => ({         body: {           functionName: 'Layout_readNotification',           params: body         }       })     }),    
    
    
    
    
    //GENERAL SECTION
    //dashboard
    DashboardListGameUpdates: builder.query({ query: () => ({ body: { functionName: 'Dashboard_listGameUpdates' } }), providesTags: ['dashboard_updates'] }),
    DashboardUserActivity: builder.query({ query: () => ({ body: { functionName: 'Dashboard_userActivity' } }), providesTags: ['dashboard_user_activity'] }),
    DashboardCharacterStats: builder.query({ query: () => ({ body: { functionName: 'Dashboard_characterStats' } }), providesTags: ['dashboard_character_stats'] }),
    DashboardGameStats:  builder.query({ query: () => ({ body: { functionName: 'Dashboard_gameStats' } }), providesTags: ['dashboard_game_stats'] }),
    DashboardNewsArticles: builder.query({ query: () => ({ body: { functionName: 'Dashboard_newsArticles' } }), providesTags: ['dashboard_news_articles'] }),
    DashboardCreateNewsArticle: builder.mutation({ query: body => ({ body: { functionName: 'Dashboard_createNewsArticle', params: body } }) }),
    DashboardClaimDaily: builder.mutation({ query: body => ({ body: { functionName: 'Dashboard_claimDaily', params: body } }) }),
    DashboardClaimStarter: builder.mutation({ query: body => ({ body: { functionName: 'Dashboard_claimStarter', params: body } }) }),
    DashboardGetUpgrades: builder.query({       query: (type) => ({         body: {           functionName: 'Dashboard_getUpgrades',          params: { type }         }       }),       providesTags: ['dashboard_upgrades']     }),
    DashboardPerformUpgrade: builder.mutation({ query: body => ({ body: { functionName: 'Dashboard_performUpgrade', params: body } }) }),
    DashboardGetAchievements: builder.query({ query: () => ({ body: { functionName: 'Dashboard_getAchievements' } }), providesTags: ['dashboard_achievements'] }),
    DashboardClaimAchievement: builder.mutation({ query: body => ({ body: { functionName: 'Dashboard_claimAchievement', params: body } }) }),
    DashboardYourStats: builder.query({ query: () => ({ body: { functionName: 'Dashboard_Yourstats' } }), providesTags: ['dashboard_user_stats'] }),
    //travel page
    TravelData: builder.query({ query: () => ({ body: { functionName: 'Travel_Data' } }), providesTags: ['travel_page_data'] }),
    TravelPost: builder.mutation({ query: body => ({ body: { functionName: 'Travel_Post', params: body } }) }),
    //money
    BankBalance: builder.query({ query: () => ({ body: { functionName: 'BankBalance' } }) }),
    BankTransfer: builder.mutation({ query: body => ({ body: { functionName: 'BankTransfer', params: body } }) }),
    BankTransactions: builder.mutation({ query: body => ({ body: { functionName: 'BankTransactions', params: body } }) }),

    SavingsCreate: builder.mutation({ query: body => ({ body: { functionName: 'SavingsCreate', params: body } }) }),
    SavingsCheck: builder.mutation({ query: body => ({ body: { functionName: 'SavingsCheck', params: body } }) }),

    ATMCheck: builder.query({ query: body => ({ body: { functionName: 'ATMCheck', params: body } }) }),
    ATMOpen:  builder.mutation({ query: body => ({ body: { functionName: 'ATMOpen', params: body } }) }),
    ATMData: builder.query({ query: () => ({ body: { functionName: 'ATMData' } }) }),
    ATMAction: builder.mutation({ query: body => ({ body: { functionName: 'ATMAction', params: body } }) }),
    ATMPin: builder.mutation({ query: body => ({ body: { functionName: 'ATMPin', params: body } }) }),
    //player directory
    PlayersOnline: builder.query({ query: () => ({ body: { functionName: 'PlayersOnline' } }) }),
    PlayersSearch: builder.query({ query: body => ({ body: { functionName: 'PlayersSearch', params: body } }), providesTags: ['players_search'] }),
    //properties page
    ListAllProperties: builder.query({ query: () => ({ body: { functionName: 'ListAllProperties' } }), providesTags: ['properties_list'] }),
    PickupProperty: builder.mutation({ query: body => ({ body: { functionName: 'PickupProperty', params: body } }) }),
    //items page
    ListUserItems: builder.query({ query: () => ({ body: { functionName: 'ListUserItems' } }), providesTags: ['items_list'] }),
    //family page
    FamilyPage: builder.query({ query: () => ({ body: { functionName: 'FamilyPage' } }), providesTags: ['family_page'] }),
    FamilyCreateFamily: builder.mutation({ query: body => ({ body: { functionName: 'FamilyCreateFamily', params: body } }) }),
    FamilyListInvites: builder.query({ query: () => ({ body: { functionName: 'FamilyListInvites' } }), providesTags: ['family_list_invites'] }),
    FamilyRespondInvite: builder.mutation({ query: body => ({ body: { functionName: 'FamilyRespondInvite', params: body } }) }),
    FamilyInvite: builder.mutation({ query: body => ({ body: { functionName: 'FamilyInvite', params: body } }) }),
    FamilyKick: builder.mutation({ query: body => ({ body: { functionName: 'FamilyKick', params: body } }) }),
    FamilyLeave: builder.mutation({ query: body => ({ body: { functionName: 'FamilyLeave', params: body } }) }),
    FamilyListBusinesses: builder.query({ query: () => ({ body: { functionName: 'FamilyListBusinesses' } }), providesTags: ['family_list_businesses'] }),
    FamilyBuyBusiness: builder.mutation({ query: body => ({ body: { functionName: 'FamilyBuyBusiness', params: body } }) }),
    FamilySellBusiness: builder.mutation({ query: body => ({ body: { functionName: 'FamilySellBusiness', params: body } }) }),
    FamilyRecruitFollowers: builder.mutation({ query: body => ({ body: { functionName: 'FamilyRecruitFollowers', params: body } }) }),
    FamilyShowFollowers: builder.query({ query: () => ({ body: { functionName: 'FamilyShowFollowers' } }), providesTags: ['family_get_followers'] }),
    FamilyShowBar: builder.query({ query: () => ({ body: { functionName: 'FamilyShowBar' } }), providesTags: ['family_show_bar'] }),
    FamilyBuyDrink: builder.mutation({ query: body => ({ body: { functionName: 'FamilyBuyDrink', params: body } }) }),
    FamilyBuildBuilding: builder.mutation({ query: body => ({ body: { functionName: 'FamilyBuildBuilding', params: body } }) }),
    FamilyMassMessage: builder.mutation({ query: body => ({ body: { functionName: 'FamilyMassMessage', params: body } }) }),

    //MESSAGING SECTION
    //game chat
    GameChatList: builder.query({ query: () => ({ body: { functionName: 'GameChatList' } }), providesTags: ['game_chat_list'] }),
    GameChatSend: builder.mutation({ query: body => ({ body: { functionName: 'GameChatSend', params: body } }) }),
    //inbox
    InboxList: builder.query({ query: () => ({ body: { functionName: 'InboxList' } }), providesTags: ['inbox_list'] }),
    InboxConversations: builder.query({       query: (subject) => ({         body: {           functionName: 'InboxConversations',           params: { subject }         }       }),       providesTags: ['inbox_conversations']     }),
    inboxSend: builder.mutation({ query: body => ({ body: { functionName: 'inboxSend', params: body } }) }),
    inboxDeleteMessages: builder.mutation({ 
      query: body => ({ 
        body: { 
          functionName: 'InboxDeleteMessages', 
          params: body 
        } 
      })
    }),
    leaveConversation: builder.mutation({ 
      query: body => ({ 
        body: { 
          functionName: 'InboxLeaveConversation', 
          params: body 
        } 
      })
    }),
    //ideas centre
    IdeasList: builder.query({       query: (status) => ({         body: {           functionName: 'IdeasList',           params: { status }         }       }),      providesTags: ['ideas_list']    }),
        IdeasSubmit: builder.mutation({ query: body => ({ body: { functionName: 'IdeasSubmit', params: body } }) }),
    IdeasVote: builder.mutation({ query: body => ({ body: { functionName: 'IdeasVote', params: body } }) }),
    IdeasComment: builder.mutation({       query: body => ({         body: {           functionName: 'IdeasComment',           params: {             ideaId: body.ideaId,             content: body.content           }         }       }),      invalidatesTags: ['ideas_comments_list']    }),
    IdeasCommentsList: builder.query({       query: (ideaUuid) => ({         body: {           functionName: 'IdeasCommentsList',          params: { ideaId: ideaUuid }        }       }),       providesTags: ['ideas_comments_list']     }),
    IdeasAdminAction: builder.mutation({ 
      query: body => ({ 
        body: { 
          functionName: 'IdeasAdminAction', 
          params: body 
        } 
      })
    }),
    IdeasDeleteComment: builder.mutation({
      query: body => ({
        body: {
          functionName: 'IdeasDeleteComment',
          params: body
        }
      })
    }), 
    
    
    //forums
    ForumListTopics: builder.query({           query: (forumType) => ({             body: {               functionName: 'ForumListTopics',               params: { forumType }             }           }),           providesTags: ['forum_list_topics']         }),    
    ForumCreateTopic: builder.mutation({ query: body => ({ body: { functionName: 'ForumCreateTopic', params: body } }) }),
    ForumFetchTopic: builder.query({       query: (topicId) => ({         body: {           functionName: 'ForumFetchTopic',           params: { uuid: topicId }        }       }),      providesTags: ['forum_fetch_topic']     }),
    ForumPostComment: builder.mutation({ query: body => ({ body: { functionName: 'ForumPostComment', params: body } }) }),
    ForumTopicAction: builder.mutation({ 
      query: body => ({ 
        body: { 
          functionName: 'ForumTopicAction', 
          params: body 
        } 
      })
    }),
    //CRIME SECTION
    CrimesList: builder.query({ query: () => ({ body: { functionName: 'CrimesList' } }), providesTags: ['crimes_list'] }),
    CrimesCommit: builder.mutation({ query: body => ({ body: { functionName: 'CrimesCommit', params: body } }) }),

    AdvancedCrimesList: builder.query({ query: () => ({ body: { functionName: 'AdvancedCrimesList' } }), providesTags: ['advanced_crimes_list'] }),
    AdvancedCrimesCommit: builder.mutation({ query: body => ({ body: { functionName: 'AdvancedCrimesCommit', params: body } }) }),
    
    CarTheftList: builder.query({ query: () => ({ body: { functionName: 'CarTheftList' } }), providesTags: ['car_theft_list'] }),
    CarTheftCommit: builder.mutation({ query: body => ({ body: { functionName: 'CarTheftCommit', params: body } }) }),
    
    BoatTheftList: builder.query({ query: () => ({ body: { functionName: 'BoatTheftList' } }), providesTags: ['boat_theft_list'] }),
    BoatTheftCommit: builder.mutation({ query: body => ({ body: { functionName: 'BoatTheftCommit', params: body } }) }),
    
    Chase: builder.mutation({ query: body => ({ body: { functionName: 'Chase', params: body } }) }),
    
    ExtortionList: builder.query({ query: () => ({ body: { functionName: 'ExtortionList' } }), providesTags: ['extortion_list'] }),
    ExtortionCommit: builder.mutation({ query: body => ({ body: { functionName: 'ExtortionCommit', params: body } }) }),
    
    DrugsList: builder.query({ query: () => ({ body: { functionName: 'DrugsList' } }), providesTags: ['drugs_list'] }),
    DrugsTransaction: builder.mutation({ query: body => ({ body: { functionName: 'DrugsTransaction', params: body } }) }),
    
    SmuggleBoatsList: builder.query({ query: () => ({ body: { functionName: 'SmuggleBoatsList' } }), providesTags: ['smuggle_boats_list'] }),
    SmuggleCommit: builder.mutation({ query: body => ({ body: { functionName: 'SmuggleCommit', params: body } }) }),
    
    PrisonList: builder.query({ query: () => ({ body: { functionName: 'PrisonList' } }), providesTags: ['prison_list'] }),
    PrisonBust: builder.mutation({ query: body => ({ body: { functionName: 'PrisonBust', params: body } }) }),
    
    SyndicateCheck: builder.query({ query: () => ({ body: { functionName: 'SyndicateCheck' } }), providesTags: ['syndicate_check'] }),
    SyndicateStart: builder.mutation({ query: body => ({ body: { functionName: 'SyndicateStart', params: body } }) }),
    SyndicateInvite: builder.mutation({ query: body => ({ body: { functionName: 'SyndicateInvite', params: body } }) }),
    SyndicateListInvites: builder.query({ query: () => ({ body: { functionName: 'SyndicateListInvites' } }), providesTags: ['syndicate_list_invites'] }),
    SyndicateResponseToInvite: builder.mutation({ query: body => ({ body: { functionName: 'SyndicateResponseToInvite', params: body } }) }),
    SyndicateData: builder.query({ query: () => ({ body: { functionName: 'SyndicateData' } }), providesTags: ['syndicate_data'] }),
    SyndicateLeave: builder.mutation({ query: body => ({ body: { functionName: 'SyndicateLeave', params: body } }) }),
    //ORGANISED CRIME
    OCCheck: builder.query({ query: () => ({ body: { functionName: 'OCCheck' } }), providesTags: ['oc_check'] }),
    OCStart: builder.mutation({ query: body => ({ body: { functionName: 'OCStart', params: body } }) }),
    OCDetails: builder.query({ query: () => ({ body: { functionName: 'OCDetails' } }), providesTags: ['oc_details'] }),
    OCInvite: builder.mutation({ query: body => ({ body: { functionName: 'OCInvite', params: body } }) }),
    OCInvitesList: builder.query({ query: () => ({ body: { functionName: 'OCInvitesList' } }), providesTags: ['oc_invites_list'] }),
    OCInviteResponse: builder.mutation({ query: body => ({ body: { functionName: 'OCInviteResponse', params: body } }) }),
    OCDisband: builder.mutation({ query: body => ({ body: { functionName: 'OCDisband', params: body } }) }),
    OCWeaponsBuy: builder.mutation({ query: body => ({ body: { functionName: 'OCWeaponsBuy', params: body } }) }),
    OCDemolitionsBuy: builder.mutation({ query: body => ({ body: { functionName: 'OCDemolitionsBuy', params: body } }) }),
    OCVehicleSubmit: builder.mutation({ query: body => ({ body: { functionName: 'OCVehicleSubmit', params: body } }) }),
    OCGetVehicles: builder.query({ query: () => ({ body: { functionName: 'OCGetVehicles' } }), providesTags: ['oc_vehicles'] }),
    OCAttempt: builder.mutation({ query: body => ({ body: { functionName: 'OCAttempt', params: body } }) }),
    OCKick: builder.mutation({ query: body => ({ body: { functionName: 'OCKick', params: body } }) }),
    LeaveOC: builder.mutation({ query: body => ({ body: { functionName: 'LeaveOC', params: body } }) }),
    //ENCOUNTER
    EncounterStatus: builder.query({ query: () => ({ body: { functionName: 'EncounterStatus' } }), providesTags: ['encounter_status'] }),
    EncounterStats: builder.query({ query: () => ({ body: { functionName: 'EncounterStats' } }), providesTags: ['encounter_stats'] }),
    EncounterShoot: builder.mutation({ query: body => ({ body: { functionName: 'EncounterShoot', params: body } }) }),
    EncounterRunaway: builder.mutation({ query: body => ({ body: { functionName: 'EncounterRunaway', params: body } }) }),
    EncounterSpawn: builder.mutation({ query: body => ({ body: { functionName: 'EncounterSpawn', params: body } }) }),
    EncounterInvitesList: builder.query({ query: () => ({ body: { functionName: 'EncounterInvitesList' } }), providesTags: ['encounter_invites_list'] }),
    EncounterInvite: builder.mutation({ query: body => ({ body: { functionName: 'EncounterInvite', params: body } }) }),
    EncounterInviteAccept: builder.mutation({ query: body => ({ body: { functionName: 'EncounterInviteAccept', params: body } }) }),
    EncounterInviteDecline: builder.mutation({ query: body => ({ body: { functionName: 'EncounterInviteDecline', params: body } }) }),
    EncounterItemsList: builder.query({ query: () => ({ body: { functionName: 'EncounterItemsList' } }), providesTags: ['encounter_items_list'] }),
    EncounterItemUse: builder.mutation({ query: body => ({ body: { functionName: 'EncounterItemUse', params: body } }) }),

    //KILL SECTION
    RussianRoulettePlay: builder.mutation({ query: body => ({ body: { functionName: 'RussianRoulettePlay', params: body } }) }),
    RussianRouletteInfo: builder.query({ query: () => ({ body: { functionName: 'RussianRouletteInfo' } }), providesTags: ['russian_roulette_info'] }),

    KillSearch: builder.mutation({ query: body => ({ body: { functionName: 'KillSearch', params: body } }) }),
    KillSearchesList: builder.query({ query: () => ({ body: { functionName: 'KillSearchesList' } }), providesTags: ['kill_searches_list'] }),
    KillAttempts: builder.query({ query: () => ({ body: { functionName: 'KillAttempts' } }), providesTags: ['kill_attempts'] }),
    KillStatements: builder.query({ query: () => ({ body: { functionName: 'KillStatements' } }), providesTags: ['kill_statements'] }),

    HitlistAdd: builder.mutation({ query: body => ({ body: { functionName: 'HitlistAdd', params: body } }) }),
    HitlistDisplay: builder.query({ query: () => ({ body: { functionName: 'HitlistDisplay' } }), providesTags: ['hitlist_display'] }),
    //factory queries can be found in the properties section

    //MARKETPLACE SECTION
    RefineryStatus: builder.query({ query: () => ({ body: { functionName: 'RefineryStatus' } }), providesTags: ['refinery_status'] }),
    RefineryPurchasePass: builder.mutation({       query: body => ({         body: {           functionName: 'RefineryPurchasePass',           params: { passType: body }        }       })     }),
    RefineryMeltCars: builder.mutation({ query: body => ({ body: { functionName: 'RefineryMeltCars', params: body } }) }),
    RefineryMeltBoats: builder.mutation({ query: body => ({ body: { functionName: 'RefineryMeltBoats', params: body } }) }),
    RefineryCollectStock: builder.mutation({ query: body => ({ body: { functionName: 'RefineryCollectStock', params: body } }) }),
    RefineryUpgradePass: builder.mutation({ query: body => ({ body: { functionName: 'RefineryUpgradePass', params: body } }) }),
    RefineryProduceKevlar: builder.mutation({ query: body => ({ body: { functionName: 'RefineryProduceKevlar', params: body } }) }),

    BarItemsList: builder.query({ query: () => ({ body: { functionName: 'BarItemsList' } }), providesTags: ['bar_items_list'] }),
    BarItemSend: builder.mutation({ query: body => ({ body: { functionName: 'BarItemSend', params: body } }) }),
    
    BlackMarketItemsList: builder.query({ query: () => ({ body: { functionName: 'BlackMarketItemsList' } }), providesTags: ['black_market_items_list'] }),
    BlackMarketItemBuy: builder.mutation({ query: body => ({ body: { functionName: 'BlackMarketItemBuy', params: body } }) }),
    BlackMarketItemSell: builder.mutation({ query: body => ({ body: { functionName: 'BlackMarketItemSell', params: body } }) }),
    BlackMarketUserItems: builder.query({ query: () => ({ body: { functionName: 'BlackMarketUserItems' } }), providesTags: ['black_market_user_items'] }),

    BlackMarketPropertyList: builder.query({ query: () => ({ body: { functionName: 'BlackMarketPropertyList' } }), providesTags: ['black_market_property_list'] }),
    BlackMarketPropertyBuy: builder.mutation({ query: body => ({ body: { functionName: 'BlackMarketPropertyBuy', params: body } }) }),
    BlackMarketPropertySell: builder.mutation({ query: body => ({ body: { functionName: 'BlackMarketPropertySell', params: body } }) }),
    BlackMarketUserProperties: builder.query({ query: () => ({ body: { functionName: 'BlackMarketUserProperties' } }), providesTags: ['black_market_user_properties'] }),

    BlackMarketInsuranceList: builder.query({ query: () => ({ body: { functionName: 'BlackMarketInsuranceList' } }), providesTags: ['black_market_insurance_list'] }),
    BlackMarketInsuranceBuy: builder.mutation({ query: body => ({ body: { functionName: 'BlackMarketInsuranceBuy', params: body } }) }),
    BlackMarketInsuranceSell: builder.mutation({ query: body => ({ body: { functionName: 'BlackMarketInsuranceSell', params: body } }) }),
    BlackMarketUserInsurance: builder.query({ query: () => ({ body: { functionName: 'BlackMarketUserInsurance' } }), providesTags: ['black_market_user_insurance'] }),

    BlackMarketPointsList: builder.query({ query: () => ({ body: { functionName: 'BlackMarketPointsList' } }), providesTags: ['black_market_points_list'] }),
    BlackMarketPointsBuy: builder.mutation({ query: body => ({ body: { functionName: 'BlackMarketPointsBuy', params: body } }) }),
    BlackMarketPointsSell: builder.mutation({ query: body => ({ body: { functionName: 'BlackMarketPointsSell', params: body } }) }),
    BlackMarketUserPoints: builder.query({ query: () => ({ body: { functionName: 'BlackMarketUserPoints' } }), providesTags: ['black_market_user_points'] }),

    BlackMarketLockpicksList: builder.query({ query: () => ({ body: { functionName: 'BlackMarketLockpicksList' } }), providesTags: ['black_market_lockpicks_list'] }),
    BlackMarketLockpicksBuy: builder.mutation({ query: body => ({ body: { functionName: 'BlackMarketLockpicksBuy', params: body } }) }),
    BlackMarketLockpicksSell: builder.mutation({ query: body => ({ body: { functionName: 'BlackMarketLockpicksSell', params: body } }) }),
    BlackMarketUserLockpicks: builder.query({ query: () => ({ body: { functionName: 'BlackMarketUserLockpicks' } }), providesTags: ['black_market_user_lockpicks'] }),

    GarageListVehicles: builder.query({ query: () => ({ body: { functionName: 'GarageListVehicles' } }), providesTags: ['garage_list_vehicles'] }),
    GarageShipVehicle: builder.mutation({ query: body => ({ body: { functionName: 'GarageShipVehicle', params: body } }) }),
    GarageSendVehicle: builder.mutation({ query: body => ({ body: { functionName: 'GarageSendVehicle', params: body } }) }),
    GarageRepairVehicle: builder.mutation({ query: body => ({ body: { functionName: 'GarageRepairVehicle', params: body } }) }),
    GarageScrapVehicle: builder.mutation({ query: body => ({ body: { functionName: 'GarageScrapVehicle', params: body } }) }),
    GarageSellVehicle: builder.mutation({ query: body => ({ body: { functionName: 'GarageSellVehicle', params: body } }) }),

    MarinaListVehicles: builder.query({ query: () => ({ body: { functionName: 'MarinaListVehicles' } }), providesTags: ['marina_list_vehicles'] }),
    MarinaShipVehicle: builder.mutation({ query: body => ({ body: { functionName: 'MarinaShipVehicle', params: body } }) }),
    MarinaSendVehicle: builder.mutation({ query: body => ({ body: { functionName: 'MarinaSendVehicle', params: body } }) }),
    MarinaRepairVehicle: builder.mutation({ query: body => ({ body: { functionName: 'MarinaRepairVehicle', params: body } }) }),
    MarinaScrapVehicle: builder.mutation({ query: body => ({ body: { functionName: 'MarinaScrapVehicle', params: body } }) }),
    MarinaSellVehicle: builder.mutation({ query: body => ({ body: { functionName: 'MarinaSellVehicle', params: body } }) }),
    MarketListVehicles: builder.query({ 
      query: (filters) => ({ 
        body: { 
          functionName: 'MarketListVehicles',
          params: filters 
        } 
      }),
      providesTags: ['market_listings']
    }),
    MarketBuyVehicle: builder.mutation({
      query: body => ({
        body: {
          functionName: 'MarketBuyVehicle',
          params: body
        }
      }),
      invalidatesTags: ['market_listings']
    }),
    MarketSellVehicle: builder.mutation({
      query: body => ({
        body: {
          functionName: 'MarketSellVehicle',
          params: body
        }
      }),
      invalidatesTags: ['market_listings']
    }),

    InsuranceGetCurrent: builder.query({ query: () => ({ body: { functionName: 'InsuranceGetCurrent' } }), providesTags: ['insurance_get_current'] }),
    InsuranceBuy: builder.mutation({ query: body => ({ body: { functionName: 'InsuranceBuy', params: body } }) }),

    //CASINO SECTION
    PropertyOwnershipCheck: builder.mutation({ query: body => ({ body: { functionName: 'PropertyOwnershipCheck', params: body } }), providesTags: ['property_ownership_check'] }),
    PropertyDetails: builder.query({       query: (propertyId) => ({         body: {           functionName: 'PropertyDetails',           params: { propertyId }        }       })    }),    
    PropertyUpdate: builder.mutation({ query: body => ({ body: { functionName: 'PropertyUpdate', params: body } }) }),
    PlayRoulette: builder.mutation({ query: body => ({ body: { functionName: 'PlayRoulette', params: body } }) }),
    PlaySlots: builder.mutation({ query: body => ({ body: { functionName: 'PlaySlots', params: body } }) }),
    PlayDice: builder.mutation({ query: body => ({ body: { functionName: 'PlayDice', params: body } }) }),
    PlayKeno: builder.mutation({ query: body => ({ body: { functionName: 'PlayKeno', params: body } }) }),
    PlayHeadsTails: builder.mutation({ query: body => ({ body: { functionName: 'PlayHeadsTails', params: body } }) }),
    BuyScratchcard: builder.mutation({ query: body => ({ body: { functionName: 'BuyScratchcard', params: body } }) }),
    ListRecentScratchcards: builder.query({ query: () => ({ body: { functionName: 'ListRecentScratchcards' } }), providesTags: ['list_recent_scratchcards'] }),
    BuyFromFactory: builder.mutation({ query: body => ({ body: { functionName: 'BuyFromFactory', params: body } }) }),
    LotteryInfo: builder.query({ query: () => ({ body: { functionName: 'LotteryInfo' } }), providesTags: ['lottery_info'] }),
    LotteryResults: builder.query({ query: () => ({ body: { functionName: 'LotteryResults' } }), providesTags: ['lottery_results'] }),
    LotteryBuyTicket: builder.mutation({ query: body => ({ body: { functionName: 'LotteryBuyTicket', params: body } }) }),
    LotteryListUserTickets: builder.query({ query: () => ({ body: { functionName: 'LotteryListUserTickets' } }), providesTags: ['lottery_list_user_tickets'] }),
    LotteryDrawHistory: builder.query({ query: () => ({ body: { functionName: 'LotteryDrawHistory' } }), providesTags: ['lottery_draw_history'] }),
    LotteryStats: builder.query({ query: () => ({ body: { functionName: 'LotteryStats' } }), providesTags: ['lottery_stats'] }),


    FoundryStock: builder.query({ query: () => ({ body: { functionName: 'FoundryStock' } }), providesTags: ['foundry_stock'] }),
    FoundryBuy: builder.mutation({ query: body => ({ body: { functionName: 'FoundryBuy', params: body } }) }),

    BoxingTraining: builder.mutation({ query: body => ({ body: { functionName: 'BoxingTraining', params: body } }) }),
    BoxingData: builder.query({ query: () => ({ body: { functionName: 'BoxingData' } }), providesTags: ['boxing_data'] }),
    BoxingFightsList: builder.query({       query: () => ({         body: {           functionName: 'BoxingFightsList'         }       }),       providesTags: ['boxing_fights_list']     }),        
    BoxingFightCreate: builder.mutation({       query: body => ({         body: {           functionName: 'BoxingFightCreate',           params: body         }       }),      invalidatesTags: ['boxing_fights_list']    }),    BoxingFightAccept: builder.mutation({       query: body => ({         body: {           functionName: 'BoxingFightAccept',           params: body         }       }),      invalidatesTags: ['boxing_fights_list']    }),    
    
    
    //MISCELLANEOUS    
    UserProfile: builder.query({       query: (username) => ({         body: {           functionName: 'UserProfile',           params: { username }         }       })    }),
    UserProfileUpdate: builder.mutation({ query: body => ({ body: { functionName: 'UserProfileUpdate', params: body } }) }),
    UserProfileImage: builder.mutation({ query: body => ({ body: { functionName: 'UserProfileImage', params: body } }) }),
    UserProfileMusic: builder.mutation({ query: body => ({ body: { functionName: 'UserProfileMusic', params: body } }) }),
    UserProfileForumImage: builder.mutation({ query: body => ({ body: { functionName: 'UserProfileForumImage', params: body } }) }),
    UserProfilePasswordChange: builder.mutation({ query: body => ({ body: { functionName: 'UserProfilePasswordChange', params: body } }) }),
    UserSecurityPinChange: builder.mutation({ query: body => ({ body: { functionName: 'UserSecurityPinChange', params: body } }) }),
    UserSecurityPinToggle: builder.mutation({ query: body => ({ body: { functionName: 'UserSecurityPinToggle', params: body } }) }),
    UserBackfireSettings: builder.mutation({ query: body => ({ body: { functionName: 'UserBackfireSettings', params: body } }) }),
    UserProfileVisibility: builder.mutation({ query: body => ({ body: { functionName: 'UserProfileVisibility', params: body } }) }),


    // Add this to the gameApi endpoints object
    FamilyProfile: builder.query({ 
      query: (familyName) => ({ 
        body: { 
          functionName: 'viewFamilyProfile',
          params: { familyName } 
        } 
      }),
      providesTags: ['family_profile']
    }),


    
    //ADMIN TOOLS
    AdminListUsers: builder.query({ query: () => ({ body: { functionName: 'AdminListUsers' } }), providesTags: ['admin_list_users'] }),
    AdminListCharacters: builder.query({ query: () => ({ body: { functionName: 'AdminListCharacters' } }), providesTags: ['admin_list_characters'] }),
    AdminGetCharacterDetails: builder.query({ 
      query: (uuid) => ({ 
        body: { 
          functionName: 'AdminGetCharacterDetails', 
          params: { uuid } 
        } 
      })
    }),
    

    //beta testing tools - delete later
    BetatestingGiveXp: builder.mutation({ query: body => ({ body: { functionName: 'betaXp', params: body } }) }),
    BetatestingGiveMoney: builder.mutation({ query: body => ({ body: { functionName: 'betaMoney', params: body } }) }),
    BetatestingGivePoints: builder.mutation({ query: body => ({ body: { functionName: 'betaPoints', params: body } }) }),
    BetatestingGiveLockpicks: builder.mutation({ query: body => ({ body: { functionName: 'betaLockpicks', params: body } }) }),
    BetatestingGiveKevlars: builder.mutation({ query: body => ({ body: { functionName: 'betaKevlars', params: body } }) }),
    BetatestingGiveBullets: builder.mutation({ query: body => ({ body: { functionName: 'betaBullets', params: body } }) }),
    BetatestingResetTimers: builder.mutation({ query: body => ({ body: { functionName: 'betaResetTimers', params: body } }) }),
    BetatestingEmptyJail: builder.mutation({ query: () => ({ body: { functionName: 'betaEmptyJail' } }) }),
    BetatestingFinishRefinery: builder.mutation({ 
      query: body => ({ 
        body: { 
          functionName: 'betaFinishRefinery', 
          params: body 
        } 
      }) 
    }),
    BetatestingFindRandomVehicle: builder.mutation({ 
      query: body => ({ 
        body: { 
          functionName: 'betaFindRandomVehicle', 
          params: body 
        } 
      }) 
    }),
    
    GetPointsData: builder.query({ query: () => ({ body: { functionName: 'GetPointsData' } }), providesTags: ['get_points_data'] }),
    TransferPoints: builder.mutation({ query: body => ({ body: { functionName: 'TransferPoints', params: body } }) }),





    }),
});

export const {
  useListLocationsQuery,
  useMasterAccountLoginMutation,
  useMasterAccountRegisterMutation,
  useMasterAccountResetPasswordMutation,

  useMasterAccountListCharactersQuery,
  useMasterAccountSelectCharacterMutation,
  useMasterAccountCreateCharacterMutation,

  useLayoutGetTimersQuery,
  useLayoutGetStatsfeedQuery,
  useLayoutGetNotificationsQuery,
  useLayoutClearNotificationsMutation,
  useLayoutReadSingleNotificationMutation,

  useDashboardListGameUpdatesQuery,
  useDashboardUserActivityQuery,
  useDashboardCharacterStatsQuery,
  useDashboardGameStatsQuery,
  useDashboardNewsArticlesQuery,
  useDashboardCreateNewsArticleMutation,
  useDashboardClaimDailyMutation,
  useDashboardClaimStarterMutation,
  useDashboardGetUpgradesQuery,
  useDashboardPerformUpgradeMutation,
  useDashboardGetAchievementsQuery,
  useDashboardClaimAchievementMutation,
  useDashboardYourStatsQuery,

  useTravelDataQuery,
  useTravelPostMutation,

  useBankBalanceQuery,
  useBankTransactionsMutation,
  useBankTransferMutation,

  useSavingsCheckMutation, 
  useSavingsCreateMutation,

  useATMCheckQuery,
  useATMOpenMutation,
  useATMDataQuery,
  useATMActionMutation,
  useATMPinMutation,

  usePlayersOnlineQuery,
  usePlayersSearchQuery,

  useListAllPropertiesQuery,
  usePickupPropertyMutation,

  useListUserItemsQuery,

  useFamilyPageQuery,
  useFamilyCreateFamilyMutation,
  useFamilyListInvitesQuery,
  useFamilyRespondInviteMutation,
  useFamilyInviteMutation,
  useFamilyKickMutation,
  useFamilyLeaveMutation,
  useFamilyListBusinessesQuery,
  useFamilyBuyBusinessMutation,
  useFamilySellBusinessMutation,
  useFamilyRecruitFollowersMutation,
  useFamilyShowFollowersQuery,
  useFamilyShowBarQuery,
  useFamilyBuyDrinkMutation,
  useFamilyBuildBuildingMutation,
  useFamilyMassMessageMutation,


  useGameChatListQuery,
  useGameChatSendMutation,

  useInboxListQuery,
  useInboxConversationsQuery,
  useInboxSendMutation,
  useInboxDeleteMessagesMutation,
  useLeaveConversationMutation,



  useIdeasListQuery,
  useIdeasSubmitMutation,
  useIdeasVoteMutation,
  useIdeasCommentMutation,
  useIdeasCommentsListQuery,
  useIdeasAdminActionMutation,
  useIdeasDeleteCommentMutation,

  useForumListTopicsQuery,
  useForumCreateTopicMutation,
  useForumFetchTopicQuery,
  useForumPostCommentMutation,
  useForumTopicActionMutation,


  

  useCrimesListQuery,
  useCrimesCommitMutation,
  useAdvancedCrimesListQuery,
  useAdvancedCrimesCommitMutation,
  useCarTheftListQuery,
  useCarTheftCommitMutation,
  useBoatTheftListQuery,
  useBoatTheftCommitMutation,
  useChaseMutation,
  useExtortionListQuery,
  useExtortionCommitMutation,
  useDrugsListQuery,
  useDrugsTransactionMutation,
  useSmuggleBoatsListQuery,
  useSmuggleCommitMutation,
  usePrisonListQuery,
  usePrisonBustMutation,

  useSyndicateCheckQuery,
  useSyndicateStartMutation,
  useSyndicateInviteMutation,
  useSyndicateListInvitesQuery,
  useSyndicateResponseToInviteMutation,
  useSyndicateDataQuery,
  useSyndicateLeaveMutation,

  useOCCheckQuery,
  useOCStartMutation,
  useOCDetailsQuery,
  useOCInviteMutation,
  useOCInvitesListQuery,
  useOCInviteResponseMutation,
  useOCDisbandMutation,
  useOCWeaponsBuyMutation,
  useOCDemolitionsBuyMutation,
  useOCVehicleSubmitMutation,
  useOCGetVehiclesQuery,
  useOCAttemptMutation,
  useOCKickMutation,
  useLeaveOCMutation,

  useEncounterStatusQuery,
  useEncounterStatsQuery,
  useEncounterShootMutation,
  useEncounterRunawayMutation,
  useEncounterSpawnMutation,
  useEncounterInvitesListQuery,
  useEncounterInviteMutation,
  useEncounterInviteAcceptMutation,
  useEncounterInviteDeclineMutation,
  useEncounterItemsListQuery,
  useEncounterItemUseMutation,



  useRussianRoulettePlayMutation,
  useRussianRouletteInfoQuery,

  useKillSearchMutation,
  useKillSearchesListQuery,
  useKillAttemptsQuery,
  useKillStatementsQuery,

  useHitlistAddMutation,
  useHitlistDisplayQuery,

  useRefineryStatusQuery,
  useRefineryPurchasePassMutation,
  useRefineryMeltCarsMutation,
  useRefineryMeltBoatsMutation,
  useRefineryCollectStockMutation,
  useRefineryUpgradePassMutation,
  useRefineryProduceKevlarMutation,


  useBarItemsListQuery,
  useBarItemSendMutation,

  useBlackMarketItemsListQuery,
  useBlackMarketItemBuyMutation,
  useBlackMarketItemSellMutation,
  useBlackMarketUserItemsQuery,

  useBlackMarketPropertyListQuery,
  useBlackMarketPropertyBuyMutation,
  useBlackMarketPropertySellMutation,
  useBlackMarketUserPropertiesQuery,

  useBlackMarketInsuranceListQuery,
  useBlackMarketInsuranceBuyMutation,
  useBlackMarketInsuranceSellMutation,
  useBlackMarketUserInsuranceQuery,

  useBlackMarketPointsListQuery,
  useBlackMarketPointsBuyMutation,
  useBlackMarketPointsSellMutation,
  useBlackMarketUserPointsQuery,

  useBlackMarketLockpicksListQuery,
  useBlackMarketLockpicksBuyMutation,
  useBlackMarketLockpicksSellMutation,
  useBlackMarketUserLockpicksQuery,


  useGarageListVehiclesQuery,
  useGarageShipVehicleMutation,
  useGarageSendVehicleMutation,
  useGarageRepairVehicleMutation,
  useGarageScrapVehicleMutation,
  useGarageSellVehicleMutation,

  useMarinaListVehiclesQuery,
  useMarinaShipVehicleMutation,
  useMarinaSendVehicleMutation,
  useMarinaRepairVehicleMutation,
  useMarinaScrapVehicleMutation,
  useMarinaSellVehicleMutation,

  useMarketListVehiclesQuery,
  useMarketBuyVehicleMutation,
  useMarketSellVehicleMutation,

  useInsuranceGetCurrentQuery,
  useInsuranceBuyMutation,

  usePropertyOwnershipCheckMutation,
  usePropertyDetailsQuery,
  usePropertyUpdateMutation,
  usePlayRouletteMutation,
  usePlaySlotsMutation,
  usePlayDiceMutation,
  usePlayKenoMutation,
  usePlayHeadsTailsMutation,
  useBuyScratchcardMutation,
  useListRecentScratchcardsQuery,
  useBuyFromFactoryMutation,
  useLotteryInfoQuery,
  useLotteryResultsQuery,
  useLotteryBuyTicketMutation,
  useLotteryListUserTicketsQuery,
  useLotteryDrawHistoryQuery,
  useLotteryStatsQuery,

  useFoundryStockQuery,
  useFoundryBuyMutation,

  useBoxingTrainingMutation,
  useBoxingDataQuery,
  useBoxingFightCreateMutation,
  useBoxingFightsListQuery,
  useBoxingFightAcceptMutation,

useUserProfileQuery,
useUserProfileUpdateMutation,
useUserProfileImageMutation,
useUserProfileMusicMutation,
useUserProfileForumImageMutation,
useUserProfilePasswordChangeMutation,
useUserSecurityPinChangeMutation,
useUserSecurityPinToggleMutation,
useUserBackfireSettingsMutation,
useUserProfileVisibilityMutation,

useFamilyProfileQuery,


useAdminListUsersQuery,
useAdminListCharactersQuery,
useAdminGetCharacterDetailsQuery,


useBetatestingGiveXpMutation,
useBetatestingGiveMoneyMutation,
useBetatestingGivePointsMutation,
useBetatestingGiveLockpicksMutation,
useBetatestingGiveKevlarsMutation,
useBetatestingGiveBulletsMutation,
useBetatestingResetTimersMutation,
useBetatestingEmptyJailMutation,

useBetatestingFinishRefineryMutation,
useBetatestingFindRandomVehicleMutation,



useGetPointsDataQuery,
useTransferPointsMutation,



  
} = gameApi;