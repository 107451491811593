import React, { useEffect, useContext, useState } from 'react';
import {
  useMasterAccountListCharactersQuery,
  useMasterAccountSelectCharacterMutation,
  useMasterAccountCreateCharacterMutation,
} from '../../api/game';
import Response from '../..//shared-components/response';
import LocationDropdown from '../../shared-components/locationDropdown';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useGlobalState } from '../../auth/globalState';
import { setCredentials } from '../../auth/authSlice';
import { AppSettings } from './../../config/app-settings.js';

function CharacterSelect() {
  const context = useContext(AppSettings);

  useEffect(() => {
    context.handleSetAppSidebarNone(true);
    context.handleSetAppHeaderNone(true);
    context.handleSetAppContentClass('p-0');

    return () => {
      context.handleSetAppSidebarNone(false);
      context.handleSetAppHeaderNone(false);
      context.handleSetAppContentClass('');
    };
    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateGlobalState } = useGlobalState();

  const { data: characters, isLoading } = useMasterAccountListCharactersQuery();
  const [postSelect] = useMasterAccountSelectCharacterMutation();
  const [createCharacter] = useMasterAccountCreateCharacterMutation();
  const [openModal, setOpenModal] = useState(false);
  const [newCharacterUsername, setNewCharacterUsername] = useState('');
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [selectedLocation, setSelectedLocation] = useState('');

  const handleSelect = async charUuid => {
    const selectedCharacter = characters.characters.find(char => char.uuid === charUuid);
  
    if (selectedCharacter.status !== 'Alive') {
      console.log('Cannot select a character with status other than "Alive"');
      return;
    }
  
    const body = {
      character_uuid: charUuid,
    };
    const response = await postSelect(body).unwrap();
  
    if (response.type === 'success') {
      const user = response.user;
      const access_token = response.access_token;
      const character = response.character;
  
      dispatch(setCredentials({ user, access_token }));
  
      localStorage.setItem('token', access_token);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('character', JSON.stringify(character));
      updateGlobalState({
        uuid: user.uuid,
        role: user.role,
        email: user.email,
        character_uuid: character.uuid,
        character_name: character.username,
        data: {
          displayName: character.username,
          photoURL: character.avatar || '',
        },
      });
      navigate('/game/general/dashboard');
    } else {
      setAlertData({ message: response.message, type: response.type });
    }
  };
  

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setNewCharacterUsername('');
  };

  const { refetch } = useMasterAccountListCharactersQuery();

  const handleCreateCharacter = async () => {
    try {
      const body = {
        username: newCharacterUsername,
        locationUuid: selectedLocation,
      };
      const response = await createCharacter(body).unwrap();

      if (response.character) {
        console.log('New character created:', response.character);
        handleCloseModal();
        refetch();
      } else if (response.message) {
        setAlertData({ message: response.message, type: response.type });
      }
    } catch (error) {
      console.error('Error creating character:', error);
    }
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const hasAliveCharacter =
    characters &&
    Array.isArray(characters.characters) &&
    characters.characters.some(char => char.status === 'Alive');

  return (
    <div>
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">Characters</li>
        <li className="breadcrumb-item active">Select</li>
      </ol>
      <h1 className="page-header">
        Character Select{' '}
        <small>select your character or create a new one</small>
      </h1>

      <div className="row">
{characters &&
  Array.isArray(characters.characters) &&
  characters.characters.length > 0 &&
  characters.characters.map(char => (
    <div className="col-xl-3 col-md-6" key={char.uuid}>
      <div
        className="widget widget-stats bg-white text-inverse mb-3"
        onClick={() => handleSelect(char.uuid)}
      >
        <div className="stats-icon stats-icon-lg">
          <i className="fa fa-user"></i>
        </div>
        <div className="stats-content">
          <div className="stats-title">{char.username}</div>
          <div className="stats-number">Status: {char.status}</div>
        </div>
      </div>
    </div>
  ))}


        {!hasAliveCharacter && (
          <div className="col-xl-3 col-md-6">
            <div
              className="widget widget-stats bg-blue text-white mb-3"
              onClick={handleOpenModal}
            >
              <div className="stats-icon stats-icon-lg">
                <i className="fa fa-plus-circle"></i>
              </div>
              <div className="stats-content">
                <div className="stats-title">Create New Character</div>
                <div className="stats-number">Click to create</div>
              </div>
            </div>
          </div>
        )}
      </div>

      {openModal && (
        <div className="modal fade show" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Create New Character</h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label className="form-label">Username</label>
                    <input
                      type="text"
                      className="form-control"
                      value={newCharacterUsername}
                      onChange={e => setNewCharacterUsername(e.target.value)}
                    />
                  </div>
                  <LocationDropdown onLocationChange={setSelectedLocation} />
                </form>
                <Response message={alertData.message} type={alertData.type} />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleCreateCharacter}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CharacterSelect;
