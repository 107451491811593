import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import {
  useUserProfileQuery,
  useUserProfileUpdateMutation,
  useUserProfileImageMutation,
  useUserProfileMusicMutation,
  useUserProfileForumImageMutation,
  useUserProfilePasswordChangeMutation,
  useUserSecurityPinChangeMutation,
  useUserSecurityPinToggleMutation,
  useUserBackfireSettingsMutation,
  useUserProfileVisibilityMutation,
} from '../../api/game';
import Response from '../../shared-components/response';

function EditProfilePage() {
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const { data: userProfile, isLoading } = useUserProfileQuery();
  const [updateProfile] = useUserProfileUpdateMutation();
  const [updateProfileImage] = useUserProfileForumImageMutation();
  const [updateProfileMusic] = useUserProfileMusicMutation();
  const [updateForumImage] = useUserProfileForumImageMutation();
  const [changePassword] = useUserProfilePasswordChangeMutation();
  const [changeSecurityPin] = useUserSecurityPinChangeMutation();
  const [toggleSecurityPin] = useUserSecurityPinToggleMutation();
  const [updateBackfireSettings] = useUserBackfireSettingsMutation();
  const [updateProfileVisibility] = useUserProfileVisibilityMutation();

  const [modalContent, setModalContent] = useState(null);
  const [formData, setFormData] = useState({
    bio: '',
    profile_image: '',
    profile_music: '',
    forum_image: '',
    current_password: '',
    new_password: '',
    confirm_new_password: '',
    current_pin: '',
    new_pin: '',
    confirm_new_pin: '',
    enable_security_pin: '',
    backfire_enabled: '',
    rank_1_10_amount: '',
    rank_11_20_amount: '',
    rank_21_30_amount: '',
    profile_visibility: '',
  });

  useEffect(() => {
    if (userProfile) {
      setFormData(prevState => ({
        ...prevState,
        bio: userProfile.bio || '',
        profile_image: userProfile.profile_image || '',
        profile_music: userProfile.profile_music || '',
        forum_image: userProfile.forum_image || '',
      }));
    }
  }, [userProfile]);

  const handleEditClick = content => {
    setAlertData({ message: '', type: '' });
    setModalContent(content);
  };

  const handleInputChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    try {
      let response;
      switch (modalContent) {
        case 'bio':
          response = await updateProfile({ bio: formData.bio }).unwrap();
          break;
        case 'profileImage':
          response = await updateProfileImage({
            profile_image: formData.profile_image,
          }).unwrap();
          break;
        case 'profileMusic':
          response = await updateProfileMusic({
            profile_music: formData.profile_music,
          }).unwrap();
          break;
        case 'forumImage':
          response = await updateForumImage({
            forum_image: formData.forum_image,
          }).unwrap();
          break;
        case 'password':
          response = await changePassword({
            current_password: formData.current_password,
            new_password: formData.new_password,
            confirm_new_password: formData.confirm_new_password,
          }).unwrap();
          break;
        case 'securityPin':
          response = await changeSecurityPin({
            current_pin: formData.current_pin,
            new_pin: formData.new_pin,
            confirm_new_pin: formData.confirm_new_pin,
          }).unwrap();
          break;
        case 'enableSecurityPin':
          response = await toggleSecurityPin({
            enable: formData.enable_security_pin,
          }).unwrap();
          break;
        case 'backfire':
          response = await updateBackfireSettings({
            enabled: formData.backfire_enabled,
            rank_1_10_amount: formData.rank_1_10_amount,
            rank_11_20_amount: formData.rank_11_20_amount,
            rank_21_30_amount: formData.rank_21_30_amount,
          }).unwrap();
          break;
        case 'visibility':
          response = await updateProfileVisibility({
            visible: formData.profile_visibility,
          }).unwrap();
          break;
      }

      setAlertData({ message: response.message, type: response.type });
      // Close modal after saving
      // You might want to add some code here to close the modal
    } catch (error) {
      setAlertData({
        message: error.data?.message || 'An error occurred',
        type: 'fail',
      });
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <h1 className="page-header">Profile Settings</h1>
      <hr className="mb-4" />

      <div className="row">
        <div className="col-xl-12">
          <div id="updateProfile" className="mb-4 pb-3">
            <h4 className="d-flex align-items-center mb-2">
              <Icon
                className="iconify fs-24px me-2 text-body text-opacity-75 my-n1"
                icon="solar:user-bold-duotone"
              />{' '}
              Update Profile
            </h4>
            <div className="card">
              <div className="list-group list-group-flush fw-bold">
                <div className="list-group-item d-flex align-items-center">
                  <div className="flex-fill">
                    <div>Bio</div>
                    <div className="text-body text-opacity-60">
                      Update your public bio on your profile
                    </div>
                  </div>
                  <div>
                    <a
                      href="#modalEdit"
                      data-bs-toggle="modal"
                      className="btn btn-secondary w-100px"
                      onClick={() => handleEditClick('bio')}
                    >
                      Edit
                    </a>
                  </div>
                </div>
                <div className="list-group-item d-flex align-items-center">
                  <div className="flex-fill">
                    <div>Profile Image</div>
                    <div className="text-body text-opacity-60">
                      Update your profile picture
                    </div>
                  </div>
                  <div>
                    <a
                      href="#modalEdit"
                      data-bs-toggle="modal"
                      className="btn btn-secondary w-100px"
                      onClick={() => handleEditClick('profileImage')}
                    >
                      Edit
                    </a>
                  </div>
                </div>
                <div className="list-group-item d-flex align-items-center">
                  <div className="flex-fill">
                    <div>Profile Music</div>
                    <div className="text-body text-opacity-60">
                      Set your profile music
                    </div>
                  </div>
                  <div>
                    <a
                      href="#modalEdit"
                      data-bs-toggle="modal"
                      className="btn btn-secondary w-100px"
                      onClick={() => handleEditClick('profileMusic')}
                    >
                      Edit
                    </a>
                  </div>
                </div>
                <div className="list-group-item d-flex align-items-center">
                  <div className="flex-fill">
                    <div>Forum Image</div>
                    <div className="text-body text-opacity-60">
                      Update your forum image
                    </div>
                  </div>
                  <div>
                    <a
                      href="#modalEdit"
                      data-bs-toggle="modal"
                      className="btn btn-secondary w-100px"
                      onClick={() => handleEditClick('forumImage')}
                    >
                      Edit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="security" className="mb-4 pb-3">
            <h4 className="d-flex align-items-center mb-2">
              <Icon
                className="iconify fs-24px me-2 text-body text-opacity-75 my-n1"
                icon="solar:lock-password-bold-duotone"
              />{' '}
              Security
            </h4>
            <div className="card">
              <div className="list-group list-group-flush fw-bold">
                <div className="list-group-item d-flex align-items-center">
                  <div className="flex-fill">
                    <div>Change Password</div>
                    <div className="text-body text-opacity-60">
                      Update your password
                    </div>
                  </div>
                  <div>
                    <a
                      href="#modalEdit"
                      data-bs-toggle="modal"
                      className="btn btn-secondary w-100px"
                      onClick={() => handleEditClick('password')}
                    >
                      Edit
                    </a>
                  </div>
                </div>
                <div className="list-group-item d-flex align-items-center">
                  <div className="flex-fill">
                    <div>Change Security PIN</div>
                    <div className="text-body text-opacity-60">
                      Update your security PIN
                    </div>
                  </div>
                  <div>
                    <a
                      href="#modalEdit"
                      data-bs-toggle="modal"
                      className="btn btn-secondary w-100px"
                      onClick={() => handleEditClick('securityPin')}
                    >
                      Edit
                    </a>
                  </div>
                </div>
                <div className="list-group-item d-flex align-items-center">
                  <div className="flex-fill">
                    <div>Enable Security PIN</div>
                    <div className="text-body text-opacity-60">
                      Turn on/off security PIN
                    </div>
                  </div>
                  <div>
                    <a
                      href="#modalEdit"
                      data-bs-toggle="modal"
                      className="btn btn-secondary w-100px"
                      onClick={() => handleEditClick('enableSecurityPin')}
                    >
                      Edit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="backfire" className="mb-4 pb-3">
            <h4 className="d-flex align-items-center mb-2">
              <Icon
                className="iconify fs-24px me-2 text-body text-opacity-75 my-n1"
                icon="solar:fire-bold-duotone"
              />{' '}
              Backfire
            </h4>
            <div className="card">
              <div className="list-group list-group-flush fw-bold">
                <div className="list-group-item d-flex align-items-center">
                  <div className="flex-fill">
                    <div>Update Backfire Setting</div>
                    <div className="text-body text-opacity-60">
                      Modify your backfire settings
                    </div>
                  </div>
                  <div>
                    <a
                      href="#modalEdit"
                      data-bs-toggle="modal"
                      className="btn btn-secondary w-100px"
                      onClick={() => handleEditClick('backfire')}
                    >
                      Edit
                    </a>
                  </div>
                </div>
                <div className="list-group-item d-flex align-items-center">
                  <div className="flex-fill">
                    <div>Rank 1-10 Amount</div>
                    <div className="text-body text-opacity-60">
                      Set amount for ranks 1-10
                    </div>
                  </div>
                  <div>
                    <a
                      href="#modalEdit"
                      data-bs-toggle="modal"
                      className="btn btn-secondary w-100px"
                      onClick={() => handleEditClick('rank1to10')}
                    >
                      Edit
                    </a>
                  </div>
                </div>
                <div className="list-group-item d-flex align-items-center">
                  <div className="flex-fill">
                    <div>Rank 11-20 Amount</div>
                    <div className="text-body text-opacity-60">
                      Set amount for ranks 11-20
                    </div>
                  </div>
                  <div>
                    <a
                      href="#modalEdit"
                      data-bs-toggle="modal"
                      className="btn btn-secondary w-100px"
                      onClick={() => handleEditClick('rank11to20')}
                    >
                      Edit
                    </a>
                  </div>
                </div>
                <div className="list-group-item d-flex align-items-center">
                  <div className="flex-fill">
                    <div>Rank 21-30 Amount</div>
                    <div className="text-body text-opacity-60">
                      Set amount for ranks 21-30
                    </div>
                  </div>
                  <div>
                    <a
                      href="#modalEdit"
                      data-bs-toggle="modal"
                      className="btn btn-secondary w-100px"
                      onClick={() => handleEditClick('rank21to30')}
                    >
                      Edit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="parentSettings" className="mb-4 pb-3">
            <h4 className="d-flex align-items-center mb-2">
              <Icon
                className="iconify fs-24px me-2 text-body text-opacity-75 my-n1"
                icon="solar:users-group-rounded-bold-duotone"
              />{' '}
              Parent Settings
            </h4>
            <div className="card">
              <div className="list-group list-group-flush fw-bold">
                <div className="list-group-item d-flex align-items-center">
                  <div className="flex-fill">
                    <div>Profile Visibility</div>
                    <div className="text-body text-opacity-60">
                      Hide/show profile on parent's view
                    </div>
                  </div>
                  <div>
                    <a
                      href="#modalEdit"
                      data-bs-toggle="modal"
                      className="btn btn-secondary w-100px"
                      onClick={() => handleEditClick('visibility')}
                    >
                      Edit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{' '}
      </div>

      <div className="modal fade" id="modalEdit">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit {modalContent}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <Response message={alertData.message} type={alertData.type} />
              {modalContent === 'bio' && (
                <div className="mb-3">
                  <label className="form-label">Bio</label>
                  <textarea
                    className="form-control"
                    rows="3"
                    name="bio"
                    value={formData.bio}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
              )}
              {modalContent === 'profileImage' && (
                <div className="mb-3">
                  <label className="form-label">Profile Image URL</label>
                  <input
                    type="text"
                    className="form-control"
                    name="profile_image"
                    value={formData.profile_image}
                    onChange={handleInputChange}
                  />
                </div>
              )}
              {modalContent === 'profileMusic' && (
                <div className="mb-3">
                  <label className="form-label">Profile Music URL</label>
                  <input
                    type="text"
                    className="form-control"
                    name="profile_music"
                    value={formData.profile_music}
                    onChange={handleInputChange}
                  />
                </div>
              )}
              {modalContent === 'forumImage' && (
                <div className="mb-3">
                  <label className="form-label">Forum Image URL</label>
                  <input
                    type="text"
                    className="form-control"
                    name="forum_image"
                    value={formData.forum_image}
                    onChange={handleInputChange}
                  />
                </div>
              )}
              {modalContent === 'password' && (
                <>
                  <div className="mb-3">
                    <label className="form-label">Current Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="current_password"
                      value={formData.current_password}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="new_password"
                      value={formData.new_password}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Confirm New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="confirm_new_password"
                      value={formData.confirm_new_password}
                      onChange={handleInputChange}
                    />
                  </div>
                </>
              )}
              {modalContent === 'securityPin' && (
                <>
                  <div className="mb-3">
                    <label className="form-label">Current PIN</label>
                    <input
                      type="password"
                      className="form-control"
                      name="current_pin"
                      value={formData.current_pin}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">New PIN</label>
                    <input
                      type="password"
                      className="form-control"
                      name="new_pin"
                      value={formData.new_pin}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Confirm New PIN</label>
                    <input
                      type="password"
                      className="form-control"
                      name="confirm_new_pin"
                      value={formData.confirm_new_pin}
                      onChange={handleInputChange}
                    />
                  </div>
                </>
              )}
              {modalContent === 'enableSecurityPin' && (
                <div className="mb-3">
                  <label className="form-label">Enable Security PIN</label>
                  <select
                    className="form-select"
                    name="enable_security_pin"
                    value={formData.enable_security_pin}
                    onChange={handleInputChange}
                  >
                    <option value="">Select...</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              )}
              {modalContent === 'backfire' && (
                <>
                  <div className="mb-3">
                    <label className="form-label">Enable Backfire</label>
                    <select
                      className="form-select"
                      name="backfire_enabled"
                      value={formData.backfire_enabled}
                      onChange={handleInputChange}
                    >
                      <option value="">Select...</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Rank 1-10 Amount</label>
                    <input
                      type="text"
                      className="form-control"
                      name="rank_1_10_amount"
                      value={formData.rank_1_10_amount}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Rank 11-20 Amount</label>
                    <input
                      type="text"
                      className="form-control"
                      name="rank_11_20_amount"
                      value={formData.rank_11_20_amount}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Rank 21-30 Amount</label>
                    <input
                      type="text"
                      className="form-control"
                      name="rank_21_30_amount"
                      value={formData.rank_21_30_amount}
                      onChange={handleInputChange}
                    />
                  </div>
                </>
              )}
              {modalContent === 'visibility' && (
                <div className="mb-3">
                  <label className="form-label">Profile Visibility</label>
                  <select
                    className="form-select"
                    name="profile_visibility"
                    value={formData.profile_visibility}
                    onChange={handleInputChange}
                  >
                    <option value="">Select...</option>
                    <option value="yes">Visible</option>
                    <option value="no">Hidden</option>
                  </select>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSave}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProfilePage;
