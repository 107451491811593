import { useGlobalState } from '../auth/globalState';

const ROLES = {
    Admin: 2771070741,
    Editor: 4751625923,
    User: 5480058047
}

const RoleGuard = ({ roles, children }) => {
    const { globalState } = useGlobalState();
    const userRole = globalState.role;    
    return roles.includes(Number(userRole)) ? children : null;
}

export { RoleGuard, ROLES };
