import React from 'react';
import { useListUserItemsQuery } from '../../api/game';

const ItemsPage = () => {
  const { data, isLoading } = useListUserItemsQuery();
  
  console.log('Raw data:', data); // Debug log
  
  // Convert numbered object keys to array
  const items = data ? [data['0']] : [];
  
  console.log('Processed items:', items); // Debug log

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">My Items</h3>
        {isLoading && <p>Loading...</p>}
        {items && items.length > 0 && items[0] ? (
          <table className="table">
            <thead>
              <tr>
                <th>Item Name</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>Rarity</th>
              </tr>
            </thead>
            <tbody>
              {items.map(item => (
                <tr key={item.uuid}>
                  <td>{item.title}</td>
                  <td>{item.description}</td>
                  <td>{item.quantity} / {item.maxStack}</td>
                  <td><span className={`badge bg-${item.rarity}`}>{item.rarity}</span></td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No items are found</p>
        )}
      </div>
    </div>
  );
};

export default ItemsPage;
