import React, { useState } from 'react';
import {
  usePlayersOnlineQuery,
  usePlayersSearchQuery,
} from '../../api/game';
import { Link } from 'react-router-dom';
const PlayersOnline = () => {
  const { data: onlinePlayers, isLoading } = usePlayersOnlineQuery();

  const getUsernameStyle = (player) => {
    if (player.isAdmin) return { color: '#1c6cd9' };
    if (player.isHeadMod) return { color: 'gold' };
    if (player.isMod) return { color: 'red' };
    if (player.isPropertyOwner) return { color: 'green' };
    return {};
  };

  const renderUsername = (player) => {
    const style = {
      ...getUsernameStyle(player),
      textDecoration: 'none'  // Remove underline
    };
  
    const username = (
      <span style={style}>
        {player.username}
      </span>
    );
  
    return (
      <Link 
        to={`/game/general/userprofile/${player.username}`}
        style={style}
      >
        {player.isLeader ? <strong>{username}</strong> : username}
      </Link>
    );
  };
  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Players Online (Last 15 Minutes)</h3>
        {isLoading && <p>Loading...</p>}
        {onlinePlayers?.data && onlinePlayers.data.length > 0 ? (
          <p>
            {onlinePlayers.data.map((player, index) => (
              <React.Fragment key={player.uuid}>
                {renderUsername(player)}
                {index < onlinePlayers.data.length - 1 && ' - '}
              </React.Fragment>
            ))}
          </p>
        ) : (
          <p>No players online</p>
        )}

        <div className="mt-3">
          <div className="d-flex flex-wrap gap-3">
            <div className="badge bg-light text-dark p-2">
              <span style={{color: '#1c6cd9'}}>Admin</span> - Staff Support
            </div>
            <div className="badge bg-light text-dark p-2">
              <span style={{color: 'gold'}}>Head Mod</span> - Bug Reports
            </div>
            <div className="badge bg-light text-dark p-2">
              <span style={{color: 'red'}}>Mod</span> - General Help
            </div>
            <div className="badge bg-light text-dark p-2">
              <span style={{color: 'green'}}>Owner</span> - Property Owner
            </div>
            <div className="badge bg-light text-dark p-2">
              <strong>Bold</strong> - Family Leader
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('Any');
  
  const { data, isLoading } = usePlayersSearchQuery(
    { query: searchTerm, status: statusFilter }
  );

  const getStatusColor = status => {
    switch (status) {
      case 'Alive': return 'green';
      case 'Dead': return 'red';
      case 'Banned': return 'orange';
      default: return 'black';
    }
  };

  const players = data ? Object.keys(data)
    .filter(key => !isNaN(key))
    .map(key => data[key]) : [];

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Search</h3>
        <div className="row mb-3">
          <div className="col-md-8">
            <input
              type="text"
              className="form-control"
              placeholder="Search for players..."
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <select 
              className="form-control"
              value={statusFilter}
              onChange={e => setStatusFilter(e.target.value)}
            >
              <option value="Any">Any Status</option>
              <option value="Alive">Alive</option>
              <option value="Dead">Dead</option>
              <option value="Banned">Banned</option>
            </select>
          </div>
        </div>
        {isLoading && <p>Loading...</p>}
        {players.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                <th>Username</th>
                <th>Rank</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {players.map(player => (
                <tr key={player.username}>
                  <td>
                    <Link to={`/game/general/userprofile/${player.username}`}>
                      {player.username}
                    </Link>
                  </td>
                  <td>{player.rankNumber}</td>
                  <td style={{ color: getStatusColor(player.status) }}>
                    {player.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};


const Directory = () => {
  const [activeTab, setActiveTab] = useState('playersOnline');

  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };

  return (
    <div>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a
            href="#players-online-tab"
            onClick={() => handleTabClick('playersOnline')}
            className={`nav-link ${activeTab === 'playersOnline' ? 'active' : ''}`}
          >
            <span className="d-sm-none">Players Online</span>
            <span className="d-sm-block d-none">Players Online</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#search-tab"
            onClick={() => handleTabClick('search')}
            className={`nav-link ${activeTab === 'search' ? 'active' : ''}`}
          >
            <span className="d-sm-none">Search</span>
            <span className="d-sm-block d-none">Search</span>
          </a>
        </li>
      </ul>
      <div className="tab-content panel rounded-0 p-3 m-0">
        <div
          className={`tab-pane fade ${activeTab === 'playersOnline' ? 'active show' : ''}`}
          id="players-online-tab"
        >
          <PlayersOnline />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'search' ? 'active show' : ''}`}
          id="search-tab"
        >
          <Search />
        </div>
      </div>
    </div>
  );
};

export default Directory;
