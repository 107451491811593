import React, { useState, useEffect } from 'react';
import { 
  useRefineryStatusQuery,
  useRefineryPurchasePassMutation,
  useRefineryMeltCarsMutation,
  useRefineryMeltBoatsMutation,
  useRefineryCollectStockMutation,
  useRefineryUpgradePassMutation,
  useRefineryProduceKevlarMutation,
 } from '../../api/game';
import Response from '../../shared-components/response';

const PASS_TYPES = {
  BRONZE: {
    bulletsPerUnit: 1,
    maxStock: 20000,
    arrivalTime: '3 Hours',
    price: 150000,
    extra: 0
  },
  SILVER: {
    bulletsPerUnit: 2,
    maxStock: 60000,
    arrivalTime: '2 Hours',
    price: 250000,
    extra: 2
  },
  GOLD: {
    bulletsPerUnit: 3,
    maxStock: 200000,
    arrivalTime: '1 Hour',
    price: 400000,
    extra: 5
  }
};

function WelcomeSection({ onBuyPass }) {
  const [buyPass] = useRefineryPurchasePassMutation();
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const { refetch } = useRefineryStatusQuery();

  const handleBuyPass = async (passType) => {
    try {
      const result = await buyPass(passType).unwrap();
      setAlertData({ message: result.message, type: result.type });
      if (result.type === 'success') {
        setTimeout(() => {
          refetch();
        }, 1500);
            }
    } catch (error) {
      setAlertData({ message: 'Error purchasing pass', type: 'danger' });
    }
  };

  return (
    <div className="row">
      <div className="col-lg-2"></div>
      <div className="col-lg-8">
        <div className="card">
          <div className="card-header">Welcome to the Refinery</div>
          <div className="card-body">
            <img className="w-100" src="/images/refinery.jpg" alt="Refinery" />
            <p className="text-center mt-3">
              Welcome to the refinery! Here you can melt down your stolen vehicles for bullets. 
              Select a trading pass to access the refinery features.
            </p>
            <Response message={alertData.message} type={alertData.type} />
            <table className="table">
              <thead>
                <tr>
                  <th>Features</th>
                  <th>BRONZE</th>
                  <th>SILVER</th>
                  <th>GOLD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Bullets Per Unit</td>
                  <td>{PASS_TYPES.BRONZE.bulletsPerUnit}</td>
                  <td>{PASS_TYPES.SILVER.bulletsPerUnit}</td>
                  <td>{PASS_TYPES.GOLD.bulletsPerUnit}</td>
                </tr>
                <tr>
                  <td>Max Stock</td>
                  <td>{PASS_TYPES.BRONZE.maxStock.toLocaleString()}</td>
                  <td>{PASS_TYPES.SILVER.maxStock.toLocaleString()}</td>
                  <td>{PASS_TYPES.GOLD.maxStock.toLocaleString()}</td>
                </tr>
                <tr>
                  <td>Arrival Time</td>
                  <td>{PASS_TYPES.BRONZE.arrivalTime}</td>
                  <td>{PASS_TYPES.SILVER.arrivalTime}</td>
                  <td>{PASS_TYPES.GOLD.arrivalTime}</td>
                </tr>
                <tr>
                  <td>Price</td>
                  <td>£{PASS_TYPES.BRONZE.price.toLocaleString()}</td>
                  <td>£{PASS_TYPES.SILVER.price.toLocaleString()}</td>
                  <td>£{PASS_TYPES.GOLD.price.toLocaleString()}</td>
                </tr>
                <tr>
                  <td>Buy Pass</td>
                  <td>
                    <button 
                      className="btn btn-primary" 
                      onClick={() => handleBuyPass('BRONZE')}
                    >
                      Buy Bronze
                    </button>
                  </td>
                  <td>
                    <button 
                      className="btn btn-primary" 
                      onClick={() => handleBuyPass('SILVER')}
                    >
                      Buy Silver
                    </button>
                  </td>
                  <td>
                    <button 
                      className="btn btn-primary" 
                      onClick={() => handleBuyPass('GOLD')}
                    >
                      Buy Gold
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-lg-2"></div>
    </div>
  );
}


function RefineryDashboard({ status }) {
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [includeTankers, setIncludeTankers] = useState(false);
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [meltCars] = useRefineryMeltCarsMutation();
  const [meltBoats] = useRefineryMeltBoatsMutation();
  const [collectStock] = useRefineryCollectStockMutation();
  const [upgradePass] = useRefineryUpgradePassMutation();
  const { refetch } = useRefineryStatusQuery();
  const [kevlarAmount, setKevlarAmount] = useState('');
  const [produceKevlar] = useRefineryProduceKevlarMutation();

  const handleKevlarProduction = async () => {
    const result = await produceKevlar({ bulletAmount: parseInt(kevlarAmount) }).unwrap();
    setAlertData({ message: result.message, type: result.type });
    if (result.type === 'success') {
      setTimeout(() => refetch(), 1500);
      setKevlarAmount('');
    }
  };

  const getUpgradeCost = (currentPassType) => {
    switch(currentPassType) {
      case 'BRONZE':
        return PASS_TYPES.SILVER.price;
      case 'SILVER':
        return PASS_TYPES.GOLD.price;
      default:
        return 0;
    }
  };

  
  const getPassDisplay = (passType) => {
    const colors = {
      BRONZE: 'bronze',
      SILVER: 'silver',
      GOLD: 'gold'
    };
    return (
      <>
        {passType} <span style={{ color: colors[passType] }}>&bull;</span>
      </>
    );
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(timer);
  }, []);


  const handleMeltCars = async () => {
    const result = await meltCars().unwrap();
    setAlertData({ message: result.message, type: result.type });
    if (result.type === 'success') {
      setTimeout(() => refetch(), 1500);
    }
  };

  const handleMeltBoats = async () => {
    const result = await meltBoats().unwrap();
    setAlertData({ message: result.message, type: result.type });
    if (result.type === 'success') {
      setTimeout(() => refetch(), 1500);
    }
  };

  const handleCollect = async () => {
    const result = await collectStock().unwrap();
    setAlertData({ message: result.message, type: result.type });
    if (result.type === 'success') {
      setTimeout(() => refetch(), 1500);
    }
  };

  const handleUpgrade = async () => {
    const result = await upgradePass().unwrap();
    setAlertData({ message: result.message, type: result.type });
    if (result.type === 'success') {
      setTimeout(() => refetch(), 1500);
    }
  };

  const calculateTimeLeft = (completionTime) => {
    const timeLeft = new Date(completionTime).getTime() - currentTime;
    
    if (timeLeft <= 0) {
      return 'Complete';
    }
    
    const hours = Math.floor(timeLeft / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
    
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <div className="row">
                  <Response message={alertData.message} type={alertData.type} />

      <div className="col-lg-1"></div>
      <div className="col-lg-6">
        <div className="card mb-4">
          <div className="card-body">
            <img className="w-100" src="/images/refinery.jpg" alt="Refinery" />
            <div className="form-check form-switch d-flex justify-content-end mt-2">
              <label className="form-check-label me-2">Include Oil Tankers</label>
              <input
                className="form-check-input"
                type="checkbox"
                checked={includeTankers}
                onChange={(e) => setIncludeTankers(e.target.checked)}
              />
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Your Stolen Vehicles</th>
                  <th>Melting Value (Bullets)</th>
                  <th>Price to Melt</th>
                  <th>#</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{status.vehicles?.cars || 0} Cars</td>
                  <td>{status.meltValue?.cars.toLocaleString()}</td>
                  <td>£{status.meltCost?.cars.toLocaleString()}</td>
                  <td>
                    <button className="btn btn-danger" onClick={() => handleMeltCars()}>
                      Send Cars to Melt
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>{status.vehicles?.boats || 0} Boats</td>
                  <td>{status.meltValue?.boats.toLocaleString()}</td>
                  <td>£{status.meltCost?.boats.toLocaleString()}</td>
                  <td>
                    <button className="btn btn-danger" onClick={() => handleMeltBoats()}>
                      Send Boats to Melt
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card">
          <div className="card-header">Refinery Stats</div>
          <div className="card-body">
            <table className="table">
              <tbody>
                <tr>
                  <td>Trading Pass:</td>
                  <td>{getPassDisplay(status.passType)}</td>
                </tr>
                <tr>
                  <td>Jobs Done:</td>
                  <td>{status.visits.toLocaleString()}</td>
                </tr>
                <tr>
                  <td>Current Stock:</td>
                  <td>{status.stock.toLocaleString()}</td>
                </tr>
                <tr>
                  <td>Lockpicks:</td>
                  <td>{status.lockpicks.toLocaleString()}</td>
                </tr>
                <tr>
                  <td>Production Total:</td>
                  <td>{status.totalProduction.toLocaleString()}</td>
                </tr>
              </tbody>
            </table>
            {status.stock >= 4000 && (
              <button className="btn btn-primary w-100 mb-2" onClick={handleCollect}>
                Collect All Stock
              </button>
            )}
{status.passType !== 'GOLD' && (
  <button className="btn btn-success w-100" onClick={handleUpgrade}>
    Upgrade Pass for £{getUpgradeCost(status.passType).toLocaleString()}
  </button>
)}

          </div>
        </div>
        {status.stock >= 10000 && (
  <div className="row mt-4">
    <div className="col-lg-2"></div>
    <div className="col-lg-8">
      <div className="card">
        <div className="card-header">Kevlar Manufacturing Warehouse</div>
        <div className="card-body">
          <p className="text-center">
            Now that you have above 10,000 bullets in stock with us, you can access our kevlar manufacturing warehouse.
            Here you can trade in the bullets you have melted, for Kevlar. Kevlar is hard to make, so each Kevlar takes two bullets to make.
          </p>
          <div className="d-flex justify-content-center gap-2">
            <input 
              type="number" 
              className="form-control w-auto" 
              placeholder="Bullets to Send" 
              value={kevlarAmount} 
              onChange={(e) => setKevlarAmount(e.target.value)} 
            />
            <button className="btn btn-primary" onClick={handleKevlarProduction}>
              Send Bullets
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-2"></div>
  </div>
)}


        
      </div>
      {status.progress?.length > 0 && (
  <div className="row mt-4">
    <div className="col-lg-2"></div>
    <div className="col-lg-8">
      <div className="card">
        <div className="card-header">Your Current Melting Jobs!</div>
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <th>Type</th>
                <th>Amount Melting</th>
                <th>Bullets Returned</th>
                <th>Lockpicks</th>
                <th>Cost of Melt</th>
                <th>Time Left</th>
              </tr>
            </thead>
            <tbody>
              {status.progress.map(job => (
                <tr key={job.uuid}>
                  <td>{job.type}</td>
                  <td>{job.amount.toLocaleString()}</td>
                  <td>{job.bullets.toLocaleString()}</td>
                  <td>{job.lockpicks.toLocaleString()}</td>
                  <td>£{job.cost.toLocaleString()}</td>
                  <td>{calculateTimeLeft(job.completionTime)}</td>
</tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div className="col-lg-2"></div>
  </div>
)}

    </div>

    
  );
}




function Refinery() {
  const { data: refineryStatus, isLoading } = useRefineryStatusQuery();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {!refineryStatus?.hasPass ? (
        <WelcomeSection />
      ) : (
        <RefineryDashboard status={refineryStatus} />
      )}
    </div>
  );
}


export default Refinery;
