import React, { useState, useEffect } from 'react';
import {
  useListAllPropertiesQuery,
  usePickupPropertyMutation,
} from '../../api/game';
import { Link } from 'react-router-dom';
import Response from '../../shared-components/response';

const Properties = () => {
  const {
    data: properties,
    isLoading,
    isError,
    refetch,
  } = useListAllPropertiesQuery();
  const [collectProperty] = usePickupPropertyMutation();
  const [activeLocation, setActiveLocation] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  const propertyArray = properties ? Object.values(properties).filter(item => item.uuid) : [];


  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading properties</div>;

  const handleCollect = async (locationId, propertyType) => {
    setSelectedProperty({ locationId, propertyType });
    setShowModal(true);
  };

  const capitalizeWords = str => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
  };

  const confirmCollect = async () => {
    try {
      const response = await collectProperty(selectedProperty).unwrap();
      
      // Set alert message from response
      setAlertData({ 
        message: response.message,
        type: response.message.includes('successfully') ? 'success' : 'danger'
      });
      
        setShowModal(false);
        refetch();
      
      
      return response;
      
    } catch (error) {
      console.error('Failed to collect property:', error);
      setAlertData({ 
        message: 'Failed to collect property', 
        type: 'danger' 
      });
    }
  };

  const getCost = propertyType => {
    return propertyType.includes('Factory') ? '5,000,000' : '500,000';
  };
  return (
    <>
      <Response 
        message={alertData.message}
        type={alertData.type}
      />
<style>
  {`
    .player-link {
      text-decoration: none !important;
      color: white !important;
    }
    .player-link:hover {
      text-decoration: none !important;
      color: white !important;
    }
  `}
</style>

      <div className="container-fluid">
        <div className="row">
          {propertyArray.map(location => (
            <div key={location.uuid} className="col-md-6 mb-4">
              <div className="card">
                <div className="card-header">
                  <h3 className="m-0">{location.city}</h3>
                </div>
                <div className="card-body">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Property Type</th>
                        <th>Owner</th>
                        <th>Max Bet / Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[
                        'roulette',
                        'slots',
                        'dice',
                        'keno',
                        'bulletFactory',
                        'kevlarFactory',
                      ].map(propertyType => (
                        <tr key={propertyType}>
                          <td>
                            {capitalizeWords(
                              propertyType.replace(/([A-Z])/g, ' $1').trim()
                            )}
                          </td>
                          <td>
                            {location[`${propertyType}Owner`] === 'LISTED' ? (
                              <span className="text-danger">
                                Listed on Black Market
                              </span>
                            ) : location[`${propertyType}Owner`] ? (
                              <Link
                                to={`/game/general/userprofile/${location[`${propertyType}Owner`]}`}
                                className="player-link text-primary"
                              >
                                {location[`${propertyType}Owner`]}
                              </Link>

                            ) : (
                              <>
                                <button
                                  className="btn btn-primary btn-sm ml-2"
                                  onClick={() =>
                                    handleCollect(location.uuid, propertyType)
                                  }
                                >
                                  Collect Property
                                </button>
                              </>
                            )}
                          </td>
                          <td>
                            {propertyType.includes('Factory')
                              ? `£${location[`${propertyType}Price`] || 'N/A'}`
                              : `£${location[`${propertyType}MaxBet`] || 'N/A'}`}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </div>

        {showModal && (
          <div
            className="modal"
            style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirm Property Collection</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowModal(false)}
                  >
                    <span>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    This will cost £{getCost(selectedProperty.propertyType)}. Do
                    you want to proceed?
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={confirmCollect}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};export default Properties;