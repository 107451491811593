import React, { useState } from 'react';
import {
  useOCCheckQuery,
  useOCStartMutation,
  useOCDetailsQuery,
  useOCInviteMutation,
  useOCInvitesListQuery,
  useOCInviteResponseMutation,
  useOCDisbandMutation,
  useOCWeaponsBuyMutation,
  useOCDemolitionsBuyMutation,
  useOCVehicleSubmitMutation,
  useOCGetVehiclesQuery,
  useOCAttemptMutation,
  useOCKickMutation,
  useLeaveOCMutation
} from '../../api/game';
import Response from '../../shared-components/response';

function StartOC() {
  const [splits, setSplits] = useState({
    leaderSplit: 40,
    weaponsSplit: 20,
    demolitionsSplit: 20,
    driverSplit: 20,
  });
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [startOC] = useOCStartMutation();
  const { refetch: refetchMembership } = useOCCheckQuery();

  const handleSplitChange = (role, value) => {
    setSplits(prev => ({
      ...prev,
      [role]: parseInt(value) || 0,
    }));
  };

  const handleSubmit = async () => {
    try {
      const response = await startOC(splits).unwrap();
      setAlertData({ message: response.message, type: response.type });
      if (response.type === 'success') {
        refetchMembership();
      }
    } catch (error) {
      setAlertData({
        message: error.data?.message || 'Error starting OC',
        type: 'error',
      });
    }
  };

  const totalSplit = Object.values(splits).reduce((a, b) => a + b, 0);

  return (
    <div className="row">
      <div className="col-lg-3"></div>
      <div className="col-lg-6">
        <Response message={alertData.message} type={alertData.type} />
        <div className="card">
          <div className="card-header">Start Organised Crime</div>
          <div className="card-body">
            <p className="text-center">
              It costs £120,000 to start an organised crime.
            </p>
            <h5 className="text-center">What cut should everyone get?</h5>
            <div className="row mb-3">
              <div className="col-6">
                <label>Leader:</label>
                <input
                  type="number"
                  className="form-control"
                  max="100"
                  value={splits.leaderSplit}
                  onChange={e =>
                    handleSplitChange('leaderSplit', e.target.value)
                  }
                />
              </div>
              <div className="col-6">
                <label>Driver:</label>
                <input
                  type="number"
                  className="form-control"
                  max="100"
                  value={splits.driverSplit}
                  onChange={e =>
                    handleSplitChange('driverSplit', e.target.value)
                  }
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-6">
                <label>Weapons Expert:</label>
                <input
                  type="number"
                  className="form-control"
                  max="100"
                  value={splits.weaponsSplit}
                  onChange={e =>
                    handleSplitChange('weaponsSplit', e.target.value)
                  }
                />
              </div>
              <div className="col-6">
                <label>Demolitions Expert:</label>
                <input
                  type="number"
                  className="form-control"
                  max="100"
                  value={splits.demolitionsSplit}
                  onChange={e =>
                    handleSplitChange('demolitionsSplit', e.target.value)
                  }
                />
              </div>
            </div>
            <p className="text-center">Total Split: {totalSplit}%</p>
            <button
              className="btn btn-primary w-100"
              onClick={handleSubmit}
              disabled={totalSplit !== 100}
            >
              Start OC
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
function CurrentInvites() {
  const { data: invites, refetch: refetchInvites } = useOCInvitesListQuery();
  const [respondToOCInvite] = useOCInviteResponseMutation();
  const { refetch: refetchMembership } = useOCCheckQuery();
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  // Convert object to array, filtering out the inPrison property
  const invitesArray = invites ? Object.values(invites).filter(invite => invite.uuid) : [];

  const handleResponse = async (inviteUuid, response) => {
    try {
      const result = await respondToOCInvite({
        inviteUuid: inviteUuid,
        response: response,
      }).unwrap();
      setAlertData({ message: result.message, type: result.type });

      if (response === 'accept') {
        await refetchMembership();
      } else {
        await refetchInvites();
      }
    } catch (error) {
      setAlertData({
        message: error.data?.message || 'Error responding to invite',
        type: 'error',
      });
    }
  };

  return (
    <div className="card mt-3">
      <div className="card-header">Current Invites</div>
      <div className="card-body">
        <Response message={alertData.message} type={alertData.type} />
        <table className="table">
          <thead>
            <tr>
              <th>Invited By</th>
              <th>Position</th>
              <th>Invite Received</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {invitesArray.map(invite => (
              <tr key={invite.uuid}>
                <td>{invite.invitedBy.username}</td>
                <td>{invite.position}</td>
                <td>{new Date(invite.createdAt).toLocaleString()}</td>
                <td>
                  <button
                    className="btn btn-success btn-sm me-2"
                    onClick={() => handleResponse(invite.uuid, 'accept')}
                  >
                    Accept
                  </button>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleResponse(invite.uuid, 'decline')}
                  >
                    Decline
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function ActiveOC() {
  const { data: membershipData, isLoading, refetch: refetchMembership } = useOCCheckQuery();
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [kickOCMember] = useOCKickMutation();
  const [leaveOC] = useLeaveOCMutation();

  if (isLoading) return <div>Loading...</div>;

  const ocData = membershipData.ocData;

  const formatEquipmentName = type => {
    const equipmentMap = {
      assault_rifle: 'Assault Rifles',
      baseball_bat: 'Baseball Bats',
      bb_gun: 'BB Guns',
      pistol: 'Pistols',
      fireworks: 'Fireworks',
      blast_charge: 'Blast Charges',
      semtex: 'Semtex',
      c4: 'C4',
    };
    return equipmentMap[type] || type;
  };

  const handleKick = async (position) => {
    try {
      const result = await kickOCMember({ positionToKick: position }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      if (result.type === 'success') {
        refetchMembership();
      }
    } catch (error) {
      setAlertData({
        message: error.data?.message || 'Error kicking member',
        type: 'error'
      });
    }
  };

  const handleLeave = async () => {
    try {
      const result = await leaveOC({ position: membershipData.userRole }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      if (result.type === 'success') {
        refetchMembership();
      }
    } catch (error) {
      setAlertData({
        message: error.data?.message || 'Error leaving OC',
        type: 'error'
      });
    }
  };
  

  
  return (
    <div className="row">
      <div className="col-lg-1"></div>
      <div className="col-lg-10">
        <Response message={alertData.message} type={alertData.type} />

        {membershipData.userRole === 'weapons' && <WeaponsControl />}
        {membershipData.userRole === 'explosives' && <ExplosivesControl />}
        {membershipData.userRole === 'driver' && <DriverControl />}

        <div className="card mt-3">
          <div className="card-header text-center">
            INFORMATION ON YOUR OC IN:{' '}
            <span className="text-warning">{ocData.ocLocation?.city}</span>
          </div>
          <div className="card-body">
            <table className="table">
              <thead>
                <tr>
                  <th>Position</th>
                  <th>Location</th>
                  <th>Status</th>
                  <th>Cut</th>
                  <th>Equipment</th>
                  <th>Actions</th>


                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Leader - {ocData.leader?.username}</td>
                  <td
                    className={
                      ocData.leader?.locationUuid === ocData.locationUuid
                        ? 'text-success'
                        : 'text-danger'
                    }
                  >
                    {ocData.leader?.location?.city}
                  </td>
                  <td className="text-success">Ready</td>
                  <td>{ocData.leaderSplit}%</td>
                  <td>Walkie Talkies</td>
                </tr>
                <tr>
                  <td>
                    Weapons Expert - {ocData.weaponsExpert?.username || 'None'}
                  </td>
                  <td
                    className={
                      ocData.weaponsExpert?.locationUuid === ocData.locationUuid
                        ? 'text-success'
                        : 'text-danger'
                    }
                  >
                    {ocData.weaponsExpert?.location?.city}
                  </td>
                  <td
                    className={
                      ocData.weaponsReady ? 'text-success' : 'text-danger'
                    }
                  >
                    {ocData.weaponsReady ? 'Ready' : 'Not Ready'}
                  </td>
                  <td>{ocData.weaponsSplit}%</td>
                  <td>
                    {ocData.weaponsType
                      ? `${ocData.weaponsAmount}x ${formatEquipmentName(ocData.weaponsType)}`
                      : 'Not Set'}
                  </td>
                  <td>
  {membershipData.userRole === 'leader' && ocData.weaponsExpert && (
    <button 
      className="btn btn-danger btn-sm"
      onClick={() => handleKick('weapons')}
    >
      Kick
    </button>
  )}
</td>
                </tr>
                <tr>
                  <td>
                    Demolitions Expert -{' '}
                    {ocData.demolitionsExpert?.username || 'None'}
                  </td>
                  <td
                    className={
                      ocData.demolitionsExpert?.locationUuid ===
                      ocData.locationUuid
                        ? 'text-success'
                        : 'text-danger'
                    }
                  >
                    {ocData.demolitionsExpert?.location?.city}
                  </td>
                  <td
                    className={
                      ocData.demolitionsReady ? 'text-success' : 'text-danger'
                    }
                  >
                    {ocData.demolitionsReady ? 'Ready' : 'Not Ready'}
                  </td>
                  <td>{ocData.demolitionsSplit}%</td>
                  <td>
                    {ocData.demolitionsType
                      ? `${ocData.demolitionsAmount}x ${formatEquipmentName(ocData.demolitionsType)}`
                      : 'Not Set'}
                  </td>
                  <td>
  {membershipData.userRole === 'leader' && ocData.demolitionsExpert && (
    <button 
      className="btn btn-danger btn-sm"
      onClick={() => handleKick('explosives')}
    >
      Kick
    </button>
  )}
</td>
                </tr>
                <tr>
                  <td>Driver - {ocData.driver?.username || 'None'}</td>
                  <td
                    className={
                      ocData.driver?.locationUuid === ocData.locationUuid
                        ? 'text-success'
                        : 'text-danger'
                    }
                  >
                    {ocData.driver?.location?.city}
                  </td>
                  <td
                    className={
                      ocData.driverReady ? 'text-success' : 'text-danger'
                    }
                  >
                    {ocData.driverReady ? 'Ready' : 'Not Ready'}
                  </td>
                  <td>{ocData.driverSplit}%</td>
                  <td>
                    {ocData.selectedVehicle
                      ? `${ocData.selectedVehicle.vehicle.title} - Damage: ${
                          100 -
                          Math.round(
                            ((ocData.selectedVehicle.value -
                              ocData.selectedVehicle.vehicle.minValue) /
                              (ocData.selectedVehicle.vehicle.maxValue -
                                ocData.selectedVehicle.vehicle.minValue)) *
                              100
                          )
                        }%`
                      : 'Not Set'}
                  </td>
                  <td>
  {membershipData.userRole === 'leader' && ocData.driver && (
    <button 
      className="btn btn-danger btn-sm"
      onClick={() => handleKick('driver')}
    >
      Kick
    </button>
  )}
</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {membershipData.userRole !== 'leader' && (
  <div className="text-center mt-3">
    <button 
      className="btn btn-danger" 
      onClick={handleLeave}
    >
      Leave OC
    </button>
  </div>
)}
        {membershipData.userRole === 'leader' && (
          <LeaderControl setAlertData={setAlertData} />
        )}
      </div>
      <div className="col-lg-1"></div>
    </div>
  );
}

function DisbandModal({ show, onClose, onConfirm }) {
  if (!show) return null;

  return (
    <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header justify-content-center position-relative">
            <h5 className="modal-title">Disband OC</h5>
            <button type="button" className="close position-absolute" style={{ right: '10px', top: '10px' }} onClick={onClose}>
              <span>×</span>
            </button>
          </div>
          <div className="modal-body text-center">
            <p>Are you sure you want to disband this OC?</p>
            <div className="row">
              <div className="col-6">
                <button className="btn btn-danger w-100" onClick={onConfirm}>Confirm</button>
              </div>
              <div className="col-6">
                <button className="btn btn-secondary w-100" onClick={onClose}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const LeaderControl = ({ setAlertData }) => {
  const [username, setUsername] = useState('');
  const [position, setPosition] = useState('Weapons Expert');
  const [inviteToOC] = useOCInviteMutation();
  const [doOC] = useOCAttemptMutation();
  const [disbandOC] = useOCDisbandMutation();
  const { refetch: refetchMembership } = useOCCheckQuery();
  const [showDisbandModal, setShowDisbandModal] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [kickOCMember] = useOCKickMutation();

  const handleInvite = async () => {
    try {
      const result = await inviteToOC({ username, position }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      if (result.type === 'success') {
        setUsername(''); // Clear input on success
      }
    } catch (error) {
      setAlertData({
        message: error.data?.message || 'Error inviting player',
        type: 'error',
      });
    }
  };
  
  const handleDoOC = async () => {
    try {
      const result = await doOC().unwrap();
      setAlertData({ message: result.message, type: result.type });
      setShowControls(false);
    } catch (error) {
      setAlertData({
        message: error.data?.message || 'Error executing OC',
        type: 'error',
      });
      setShowControls(false);
    }
  };
  const handleDisband = async () => {
    try {
      const result = await disbandOC().unwrap();
      setAlertData({ message: result.message, type: result.type });
      if (result.type === 'success') {
        refetchMembership();
      }
    } catch (error) {
      setAlertData({
        message: error.data?.message || 'Error disbanding OC',
        type: 'error'
      });
    }
    setShowDisbandModal(false);
  };

  return (
    <div className="card mt-3">
      <div className="card-header">Leader Control</div>
      <div className="card-body">
        {showControls ? (
          <>
            <div className="row mb-3">
              <div className="col-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Username"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                />
              </div>
              <div className="col-4">
                <select
                  className="form-control"
                  value={position}
                  onChange={e => setPosition(e.target.value)}
                >
                  <option>Weapons Expert</option>
                  <option>Demolitions Expert</option>
                  <option>Driver</option>
                </select>
              </div>
              <div className="col-4">
                <button className="btn btn-primary w-100" onClick={handleInvite}>
                  Invite
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <button className="btn btn-success w-100" onClick={handleDoOC}>
                  Do Organised Crime
                </button>
              </div>
              <div className="col-6">
                <button className="btn btn-danger w-100" onClick={() => setShowDisbandModal(true)}>
                  Disband OC
                </button>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <DisbandModal 
        show={showDisbandModal}
        onClose={() => setShowDisbandModal(false)}
        onConfirm={handleDisband}
      />
    </div>
  );
};
function OrganisedCrime() {
  const [activeTab, setActiveTab] = useState('start');
  const { data: membershipData } = useOCCheckQuery();

  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };

  if (membershipData?.inOC) {
    return <ActiveOC />;
  }

  return (
    <div className="family-tabs-container">
      <div className="tabs-wrapper">
        <ul className="nav nav-tabs flex-nowrap">
          <li className="nav-item">
            <a
              href="#start-tab"
              onClick={() => handleTabClick('start')}
              className={`nav-link ${activeTab === 'start' ? 'active' : ''}`}
            >
              Start OC
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#invites-tab"
              onClick={() => handleTabClick('invites')}
              className={`nav-link ${activeTab === 'invites' ? 'active' : ''}`}
            >
              Current Invites
            </a>
          </li>
        </ul>
      </div>

      <div className="tab-content panel rounded-0 p-3 m-0">
        <div
          className={`tab-pane fade ${activeTab === 'start' ? 'active show' : ''}`}
          id="start-tab"
        >
          {activeTab === 'start' && <StartOC />}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'invites' ? 'active show' : ''}`}
          id="invites-tab"
        >
          {activeTab === 'invites' && <CurrentInvites />}
        </div>
      </div>
    </div>
  );
}

function WeaponsControl() {
  const { data: membershipData, refetch: refetchMembership } = useOCCheckQuery();
  const [selectedWeapon, setSelectedWeapon] = useState('');
  const [amount, setAmount] = useState(1);
  const [submitWeapons] = useOCWeaponsBuyMutation();
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const isReady = membershipData?.ocData?.weaponsReady;

  const formatEquipmentName = type => {
    const equipmentMap = {
      assault_rifle: 'Assault Rifles',
      baseball_bat: 'Baseball Bats',
      bb_gun: 'BB Guns',
      pistol: 'Pistols',
      fireworks: 'Fireworks',
      blast_charge: 'Blast Charges',
      semtex: 'Semtex',
      c4: 'C4',
    };
    return equipmentMap[type] || type;
  };

  const weaponCosts = {
    baseball_bat: 10000,
    bb_gun: 17500,
    pistol: 24000,
    assault_rifle: 100000
  };

  const handleSubmit = async () => {
    try {
      const result = await submitWeapons({
        weaponType: selectedWeapon,
        amount,
      }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      if (result.type === 'success') {
        refetchMembership();
      }
    } catch (error) {
      setAlertData({
        message: error.data?.message || 'Error submitting weapons',
        type: 'error',
      });
    }
  };

  return (
    <div className="card mt-3">
      <div className="card-header">Weapons Expert Control</div>
      <div className="card-body">
        <Response message={alertData.message} type={alertData.type} />
        {isReady ? (
          <div className="text-center">
            <h5 className="text-success">Ready for OC</h5>
            <p>Selected: {membershipData.ocData.weaponsAmount} {formatEquipmentName(membershipData.ocData.weaponsType)}</p>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-6">
                <select
                  className="form-control"
                  value={selectedWeapon}
                  onChange={e => setSelectedWeapon(e.target.value)}
                >
                  <option value="">Select Weapon</option>
                  <option value="baseball_bat">Baseball Bat (£10,000)</option>
                  <option value="bb_gun">BB Gun (£17,500)</option>
                  <option value="pistol">Pistol (£24,000)</option>
                  <option value="assault_rifle">Assault Rifle (£100,000)</option>
                </select>
              </div>
              <div className="col-6">
                <select
                  className="form-control"
                  value={amount}
                  onChange={e => setAmount(parseInt(e.target.value))}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
              </div>
            </div>
            {selectedWeapon && (
              <p className="text-center mt-2">
                Total Cost: £{(weaponCosts[selectedWeapon] * amount).toLocaleString()}
              </p>
            )}
            <button
              className="btn btn-primary w-100 mt-3"
              onClick={handleSubmit}
              disabled={!selectedWeapon}
            >
              Submit Weapons
            </button>
          </>
        )}
      </div>
    </div>
  );
}

function ExplosivesControl() {
  const { data: membershipData, refetch: refetchMembership } = useOCCheckQuery();
  const [selectedExplosive, setSelectedExplosive] = useState('');
  const [amount, setAmount] = useState(1);
  const [submitDemolitions] = useOCDemolitionsBuyMutation();
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const isReady = membershipData?.ocData?.demolitionsReady;

  const formatEquipmentName = type => {
    const equipmentMap = {
      assault_rifle: 'Assault Rifles',
      baseball_bat: 'Baseball Bats',
      bb_gun: 'BB Guns',
      pistol: 'Pistols',
      fireworks: 'Fireworks',
      blast_charge: 'Blast Charges',
      semtex: 'Semtex',
      c4: 'C4',
    };
    return equipmentMap[type] || type;
  };

  const handleSubmit = async () => {
    try {
      const result = await submitDemolitions({
        explosiveType: selectedExplosive,
        amount,
      }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      if (result.type === 'success') {
        refetchMembership();
      }
    } catch (error) {
      setAlertData({
        message: error.data?.message || 'Error submitting explosives',
        type: 'error',
      });
    }
  };

  const explosiveCosts = {
    fireworks: 10000,
    blast_charge: 17500,
    semtex: 24000,
    c4: 100000
  };

  return (
    <div className="card mt-3">
      <div className="card-header">Explosives Expert Control</div>
      <div className="card-body">
        <Response message={alertData.message} type={alertData.type} />
        {isReady ? (
          <div className="text-center">
            <h5 className="text-success">Ready for OC</h5>
            <p>Selected: {membershipData.ocData.demolitionsAmount} {formatEquipmentName(membershipData.ocData.demolitionsType)}</p>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-6">
                <select
                  className="form-control"
                  value={selectedExplosive}
                  onChange={e => setSelectedExplosive(e.target.value)}
                >
                  <option value="">Select Explosive</option>
                  <option value="fireworks">Fireworks (£10,000)</option>
                  <option value="blast_charge">Blast Charge (£17,500)</option>
                  <option value="semtex">Semtex (£24,000)</option>
                  <option value="c4">C4 (£100,000)</option>
                </select>
              </div>
              <div className="col-6">
                <select
                  className="form-control"
                  value={amount}
                  onChange={e => setAmount(parseInt(e.target.value))}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
              </div>
            </div>
            {selectedExplosive && (
              <p className="text-center mt-2">
                Total Cost: £{(explosiveCosts[selectedExplosive] * amount).toLocaleString()}
              </p>
            )}
            <button
              className="btn btn-primary w-100 mt-3"
              onClick={handleSubmit}
              disabled={!selectedExplosive}
            >
              Submit Explosives
            </button>
          </>
        )}
      </div>
    </div>
  );
}

function DriverControl() {
  const { data: membershipData, refetch: refetchMembership } = useOCCheckQuery();
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const { data: vehicles } = useOCGetVehiclesQuery();
  const [submitVehicle] = useOCVehicleSubmitMutation();
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const isReady = membershipData?.ocData?.driverReady;

  // Convert vehicles object to array if needed
  const vehiclesArray = vehicles ? Object.values(vehicles).filter(v => v.uuid) : [];

  const handleSubmit = async () => {
    try {
      const result = await submitVehicle({
        vehicleUuid: selectedVehicle,
      }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      if (result.type === 'success') {
        refetchMembership();
      }
    } catch (error) {
      setAlertData({
        message: error.data?.message || 'Error submitting vehicle',
        type: 'error',
      });
    }
  };

  return (    <div className="card mt-3">
      <div className="card-header">Driver Control</div>
      <div className="card-body">
        <Response message={alertData.message} type={alertData.type} />
        {isReady ? (
          <div className="text-center">
            <h5 className="text-success">Ready for OC</h5>
            <p>Vehicle Selected</p>
          </div>
        ) : (
          <>
            <select
              className="form-control"
              value={selectedVehicle}
              onChange={e => setSelectedVehicle(e.target.value)}
            >
              <option value="">Select Vehicle</option>
              {vehiclesArray.map(vehicle => (
  <option key={vehicle.uuid} value={vehicle.uuid}>
    {vehicle.name} - {vehicle.condition}% damaged
  </option>
))}

            </select>
            <button
              className="btn btn-primary w-100 mt-3"
              onClick={handleSubmit}
              disabled={!selectedVehicle}
            >
              Submit Vehicle
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default OrganisedCrime;