import React, { useState, useEffect, useRef } from 'react';
import {
  useInboxListQuery,
  useInboxConversationsQuery,
  useInboxSendMutation,
  useInboxDeleteMessagesMutation,
  useLeaveConversationMutation
} from '../../api/game';
import Response from '../../shared-components/response';
import { useGlobalState } from '../../auth/globalState';

const ConversationModal = ({ subject, onClose }) => {
  const [message, setMessage] = useState('');
  const [sendMessage] = useInboxSendMutation();
  const {
    data: messages,
    isLoading,
    refetch,
  } = useInboxConversationsQuery(subject);
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  const handleMessageSubmit = async () => {
    if (!message.trim()) {
      setAlertData({ message: 'Message cannot be empty', type: 'error' });
      return;
    }

    try {
      await sendMessage({ subject, content: message.trim(), isReply: true });
      setMessage('');
      refetch();
      setAlertData({ message: 'Message sent successfully!', type: 'success' });
    } catch (error) {
      setAlertData({
        message: 'Failed to send message. Please try again.',
        type: 'error',
      });
    }
  };



  return (
    <div
      className="modal"
      style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{subject}</h4>
            <button type="button" className="close" onClick={onClose}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Response message={alertData.message} type={alertData.type} />
            {isLoading ? (
              <p>Loading messages...</p>
            ) : (
              messages &&
              messages.map(msg => (
                <div key={msg.uuid} className="card mt-2">
                  <div className="card-body">
                    <p>{msg.content}</p>
                    <small>
                      From: {msg.sender.username} To: {msg.receiver.username}
                    </small>
                  </div>
                </div>
              ))
            )}
            <div className="mt-4">
              <textarea
                className="form-control"
                value={message}
                onChange={e => setMessage(e.target.value)}
                placeholder="Write a message..."
              />
              <button
                className="btn btn-primary mt-2"
                onClick={handleMessageSubmit}
              >
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ConversationList = ({ onConversationClick }) => {
  const { globalState } = useGlobalState();
  const { data: chains, isLoading } = useInboxListQuery();

  const chainsArray = chains ? 
    Object.entries(chains)
      .filter(([key]) => key !== 'inPrison')
      .map(([_, value]) => value)
      .sort((a, b) => new Date(b.lastMessage?.createdAt) - new Date(a.lastMessage?.createdAt)) : 
    [];

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Subject</th>
          <th>Last Message</th>
          <th>Members</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {chainsArray.map(chain => (
          <tr 
            key={chain.uuid} 
            className={(!chain.lastMessage?.read && chain.lastMessage?.senderUuid !== globalState.character_uuid) ? 'table-primary' : ''}
          >
            <td onClick={() => onConversationClick(chain.uuid)}>
              {(!chain.lastMessage?.read && chain.lastMessage?.senderUuid !== globalState.character_uuid) && 
                <span className="badge badge-primary mr-2">New</span>
              }
              {chain.subject}
            </td>
            <td onClick={() => onConversationClick(chain.uuid)}>
              {chain.lastMessage?.content.substring(0, 20)}...
            </td>
            <td onClick={() => onConversationClick(chain.uuid)}>
              {chain.memberNames.join(', ')}
            </td>
            <td onClick={() => onConversationClick(chain.uuid)}>
              {new Date(chain.lastMessage?.createdAt).toLocaleString()}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const ConversationView = ({ subject, onBack }) => {
  const { globalState } = useGlobalState();
  const [message, setMessage] = useState('');
  const [sendMessage] = useInboxSendMutation();
  const [leaveConversation] = useLeaveConversationMutation();
  const { data: conversationData, isLoading, refetch } = useInboxConversationsQuery(subject);
  const messagesEndRef = useRef(null);
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  const messages = conversationData 
    ? Object.entries(conversationData)
      .filter(([key]) => key !== 'inPrison')
      .map(([_, value]) => value)
    : [];

  // Get chain subject from the first message
  const chainSubject = messages[0]?.chainSubject || 'Conversation';

  const handleMessageSubmit = async () => {
    try {
      await sendMessage({ subject, content: message });
      setMessage('');
      refetch();
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  const handleLeaveConversation = async () => {
    try {
      const result = await leaveConversation({ chainUuid: subject });
      
      if (result.data?.type === 'success') {
        onBack();
      } else {
        setAlertData({
          message: result.data?.message || 'Failed to leave conversation',
          type: 'error'
        });
      }
    } catch (error) {
      setAlertData({
        message: 'Failed to leave conversation',
        type: 'error'
      });
    }
  };

  const prevMessagesLength = useRef(messages.length);

  useEffect(() => {
    // Only scroll if new messages were added
    if (messages.length > prevMessagesLength.current) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    prevMessagesLength.current = messages.length;
  }, [messages]);

  return (
    <div>
      <Response message={alertData.message} type={alertData.type} />
      <div className="d-flex justify-content-between mb-3">
        <button className="btn btn-secondary" onClick={onBack}>Back to Inbox</button>
        <button className="btn btn-danger" onClick={handleLeaveConversation}>
          Leave Conversation
        </button>
      </div>

      <div className="card">
        <div className="card-header bg-dark text-white">
          <h5 className="mb-0">{messages[0]?.message_chain?.subject || 'Conversation'}</h5>
        </div>
        <div className="card-body" style={{ height: '500px', overflowY: 'auto' }}>
        {messages.map(msg => (
          <div 
            key={msg.uuid} 
            className="d-flex mb-3"
            style={{ 
              justifyContent: msg.senderUuid === globalState.character_uuid ? 'flex-start' : 'flex-end'
            }}
          >
            <div 
              className="bg-white p-3 rounded shadow-sm" 
              style={{ width: '60%' }}
            >
              <div className="border-bottom pb-2 mb-2">
                {msg.senderUuid === globalState.character_uuid ? (
                  <div className="text-start">
                    <strong>{msg.sender?.username}</strong>
                    <span className="mx-2">•</span>
                    {new Date(msg.createdAt).toLocaleString()}
                  </div>
                ) : (
                  <div className="text-end">
                    {new Date(msg.createdAt).toLocaleString()}
                    <span className="mx-2">•</span>
                    <strong>{msg.sender?.username}</strong>
                  </div>
                )}
              </div>
              <p className="mb-0">{msg.content}</p>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
        </div>
        <div className="card-footer">
          <textarea
            className="form-control mb-2"
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder="Write a message..."
          />
          <div className="d-flex justify-content-end">
            <button className="btn btn-primary" onClick={handleMessageSubmit}>
              Send Message
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const NewConversation = ({ setAlertData }) => {
  const [username, setUsername] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [sendMessage] = useInboxSendMutation();

  const handleSubmit = async e => {
    e.preventDefault();

    const result = await sendMessage({
      receiverUsername: username.trim(),
      subject: subject.trim(),
      content: content.trim(),
    });

    if (result.error) {
      setAlertData({
        message: result.error.data.message || 'Failed to send message',
        type: 'error'
      });
      return;
    }

    if (result.data.type === 'error') {
      setAlertData({
        message: result.data.message,
        type: 'error'
      });
      return;
    }

    setAlertData({
      message: 'New conversation started successfully!',
      type: 'success'
    });
    setUsername('');
    setSubject('');
    setContent('');
  };

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Start New Conversation</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">To Username:</label>
            <input
              type="text"
              className="form-control"
              id="username"
              value={username}
              onChange={e => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="subject">Subject:</label>
            <input
              type="text"
              className="form-control"
              id="subject"
              value={subject}
              onChange={e => setSubject(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="content">Message:</label>
            <textarea
              className="form-control"
              id="content"
              value={content}
              onChange={e => setContent(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Start Conversation
          </button>
        </form>
      </div>
    </div>
  );
};

const Inbox = () => {
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [selectedConversation, setSelectedConversation] = useState(null);

  const handleConversationClick = subject => {
    console.log('Setting selected conversation:', subject);

    setSelectedConversation(subject);
  };

  const handleBackToInbox = () => {
    setSelectedConversation(null);
  };

  return (
    <div>
      {!selectedConversation ? (
        <>
          <div className="panel rounded-0 p-3 m-0">
            <ConversationList onConversationClick={handleConversationClick} />
          </div>
          <div className="mt-4">
            <Response message={alertData.message} type={alertData.type} />
            <NewConversation setAlertData={setAlertData} />
          </div>
        </>
      ) : (
        <ConversationView
          subject={selectedConversation}
          onBack={handleBackToInbox}
          setAlertData={setAlertData}
        />
      )}
    </div>
  );
};


export default Inbox;
