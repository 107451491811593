import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: null,
    inPrison: false,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { user, access_token } = action.payload;
      state.user = user;
      state.token = access_token;
    },
    setPrisonStatus: (state, action) => {
      state.inPrison = action.payload;
    },
    logOut: (state, action) => {
      state.user = null;
      state.token = null;
      state.inPrison = false;
    },
  },
});

export const { setCredentials, logOut, setPrisonStatus } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = state => state.auth.user;
export const selectCurrentToken = state => state.auth.token;
export const selectInPrison = state => state.auth.inPrison;
