import React, { useState } from 'react';
import { useUserProfileQuery } from '../../api/game';
import { useParams } from 'react-router-dom';
import './userProfile.css';
import { Link } from 'react-router-dom';

const UserProfile = () => {
  const { username } = useParams();
  const [activeTab, setActiveTab] = useState('quote');
  const { data: userProfile, isLoading } = useUserProfileQuery(username);


  const isUserActive = (onlineTimestamp) => {
    if (!onlineTimestamp) return false;
    const fifteenMinutesAgo = new Date(Date.now() - 15 * 60 * 1000);
    return new Date(onlineTimestamp) > fifteenMinutesAgo;
  };



  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };

  if (isLoading) return <div>Loading...</div>;
  return (
      <div className="row">
        <div className="col-md-12 mb-4">
          <div className="card">
          <div className="card-header">
          <h3 className="m-0 text-center">
          {userProfile.name} - [
    <span style={{ 
      color: isUserActive(userProfile.online) ? '#00ff00' : '#ff0000'
    }}>
      {isUserActive(userProfile.online) ? 'Active' : 'Inactive'}
    </span>]
  </h3>
</div>
            <div className="card-body" style={{ backgroundColor: '#3f3f3f' }}>
            <div className="row">
                <div className="col-md-4">
                  <table className="table">
                    <tbody>
                    <tr>
                    <td colSpan={2}>
                    <img 
                          src={userProfile.profile_image || '/assets/img/default_profileimage.png'}
                          alt={userProfile.name}
                          style={{ 
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            display: 'block'
                          }}
                        />
                      </td>
                    </tr>
                    <br />
                      <tr>
                        <td className="tablesubheader">Rank:</td>
                        <td>{userProfile.rankName}({userProfile.rankNumber})</td>
                      </tr>
                      
                      <tr>
                        <td className="tablesubheader">Status:</td>
                        <td>{userProfile.status}</td>
                      </tr>
                      <tr>
                        <td className="tablesubheader">Family:</td>
                        <td>{userProfile.familyName}</td>
                      </tr>
                      <tr>
                        <td className="tablesubheader">Specialty:</td>
                        <td>{userProfile.trait}</td>
                      </tr>
                      <tr>
                        <td className="tablesubheader">Kill Rank:</td>
                        <td>{userProfile.killRank}</td>
                      </tr>
                      <tr>
                        <td className="tablesubheader">Gender:</td>
                        <td>{userProfile.gender}</td>
                      </tr>


<tr>
  <td colSpan={2} className="text-center">
    {userProfile.familyName === 'No Family' ? (
      'No Family'
    ) : (
      <Link to={`/game/family/famprofile/${userProfile.familyName}`}>
        {userProfile.familyName}
      </Link>
    )}
  </td>
</tr>

                    </tbody>
                  </table>
                </div>

                <div className="col-md-8">
                  <div className="tabs-wrapper">
                    <ul className="nav nav-tabs flex-nowrap">
                      <li className="nav-item">
                        <a
                          href="#quote-tab"
                          onClick={() => handleTabClick('quote')}
                          className={`nav-link ${activeTab === 'quote' ? 'active' : ''}`}
                        >
                          Profile Quote
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#kills-tab"
                          onClick={() => handleTabClick('kills')}
                          className={`nav-link ${activeTab === 'kills' ? 'active' : ''}`}
                        >
                          Public Kills
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="tab-content panel rounded-0 p-3 m-0">
                    <div className={`tab-pane fade ${activeTab === 'quote' ? 'active show' : ''}`}>
                      <table className="table">
                        <tbody>
                          <tr>
                            <td align="center" className="tablesubheader">
                              {userProfile.bio || 'No Quote Set!'}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className={`tab-pane fade ${activeTab === 'kills' ? 'active show' : ''}`}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Target</th>
                            <th>Bullets</th>
                            <th>Outcome</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userProfile.kills?.length > 0 ? (
                            userProfile.kills.map((kill, index) => (
                              <tr key={index}>
                                <td>{kill.target}</td>
                                <td>{kill.bullets}</td>
                                <td>{kill.outcome}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4" align="center">No public kills to display!</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
export default UserProfile;
