import React from 'react';
import { Link } from 'react-router-dom';
import DropdownNotification from './dropdown/notification.jsx';
import DropdownLanguage from './dropdown/language.jsx';
import DropdownProfile from './dropdown/profile.jsx';
import DropdownMegaMenu from './dropdown/mega.jsx';

import { AppSettings } from '../../config/app-settings.js';

function Header() {
  return (
    <AppSettings.Consumer>
      {({
        toggleAppSidebarMobile,
        toggleAppSidebarEnd,
        toggleAppSidebarEndMobile,
        toggleAppTopMenuMobile,
        appHeaderLanguageBar,
        appHeaderMegaMenu,
        appHeaderInverse,
        appSidebarTwo,
        appTopMenu,
        appSidebarNone,
        toggleChatArea,
      }) => (
        <div
          id="header"
          className="app-header header-mobile"
          data-bs-theme={appHeaderInverse ? 'dark' : ''}
        >
          <div className="navbar-brand" onClick={toggleAppSidebarMobile}></div>

          {appHeaderMegaMenu && <DropdownMegaMenu />}

          <div className="navbar-nav">
            <DropdownNotification />

            <i className="fa fa-comments fa-2x" onClick={toggleChatArea}></i>

            {appHeaderLanguageBar && <DropdownLanguage />}

            <DropdownProfile />

            {appSidebarTwo && (
              <div className="navbar-divider d-none d-md-block"></div>
            )}

            {appSidebarTwo && (
              <div className="navbar-item d-none d-md-block">
                <Link
                  to="/"
                  onClick={toggleAppSidebarEnd}
                  className="navbar-link icon"
                >
                  <i className="fa fa-th"></i>
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </AppSettings.Consumer>
  );
}

export default Header;
