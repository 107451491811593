import React from 'react';
import { AppSettings } from './config/app-settings.js';
import { useAppConfig } from './config/app-config.js';
import { GlobalStateProvider } from './auth/globalState.js';


import Header from './components/header/header.jsx';
import Sidebar from './components/sidebar/sidebar.jsx';
import SidebarRight from './components/sidebar-right/sidebar-right.jsx';
import TopMenu from './components/top-menu/top-menu.jsx';
import Content from './components/content/content.jsx';
import Timers from './components/timers/timers.jsx';
import ChatArea from './components/chatarea/chatArea.jsx';

function App() {
  const config = useAppConfig();

  return (
	<GlobalStateProvider>
		<AppSettings.Provider value={config}>
		<div
			className={
			'app ' +
			(config.appGradientEnabled ? 'app-gradient-enabled ' : '') +
			(config.appHeaderNone ? 'app-without-header ' : '') +
			(config.appHeaderFixed && !config.appHeaderNone ? 'app-header-fixed ' : '') +
			(config.appSidebarFixed ? 'app-sidebar-fixed ' : '') +
			(config.appSidebarNone ? 'app-without-sidebar ' : '') +
			(config.appSidebarEnd ? 'app-with-end-sidebar ' : '') +
			(config.appSidebarWide ? 'app-with-wide-sidebar ' : '') +
			(config.appSidebarMinify ? 'app-sidebar-minified ' : '') +
			(config.appSidebarMobileToggled ? 'app-sidebar-mobile-toggled ' : '') +
			(config.appTopMenu ? 'app-with-top-menu ' : '') +
			(config.appContentFullHeight ? 'app-content-full-height ' : '') +
			(config.appSidebarTwo ? 'app-with-two-sidebar ' : '') +
			(config.appSidebarEndToggled ? 'app-sidebar-end-toggled ' : '') +
			(config.appSidebarEndMobileToggled ? 'app-sidebar-end-mobile-toggled ' : '') +
			(config.hasScroll ? 'has-scroll ' : '')			}
		>
			{!config.appHeaderNone && <Header />}
			<div
            onClick={() => config.isChatOpen && config.toggleChatArea()}
          >
			{!config.appSidebarNone && <Sidebar />}
			{config.appSidebarTwo && <SidebarRight />}
			{config.appTopMenu && <TopMenu />}
            {!config.appContentNone && (
              <>
                <Timers />
                <Content />
              </>
            )}
		</div>
		<ChatArea isOpen={config.isChatOpen} />
        </div>
		</AppSettings.Provider>
	</GlobalStateProvider>
  );
}

export default App;