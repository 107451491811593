import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalState } from '../../auth/globalState';
import { AppSettings } from '../../config/app-settings';

const TimerButton = ({ action, label, to, icon, remainingTime }) => {
  const { globalState } = useGlobalState();
  const { timers } = globalState;
  const [isPhoneScreen, setIsPhoneScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsPhoneScreen(window.innerWidth <= 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const buttonContent =
    timers[action] && remainingTime > 0 ? (
      <span style={{ color: 'red' }}>{remainingTime}s</span>
    ) : (
      <span style={{ color: 'green' }}>NOW</span>
    );

  return (
    <Link to={to} style={{ textDecoration: 'none', marginBottom: '4px' }}>
      <button
        type="button"
        className="btn btn-default"
        style={{
          marginRight: '4px',
          marginBottom: isPhoneScreen ? '4px' : 0,
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#15344f',
        }}
      >
        <i className={`fas ${icon} me-1`}></i>
        {buttonContent}
        {!isPhoneScreen && <span style={{ marginLeft: '4px' }}>{label}</span>}
      </button>
    </Link>
  );
};

const Timers = () => {
  const [isPhoneScreen, setIsPhoneScreen] = useState(false);
  const { globalState, updateTimer } = useGlobalState();
  const { timers } = globalState;
  const intervalRef = useRef(null);
  const [remainingTimes, setRemainingTimes] = useState({});

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const newRemainingTimes = {};
      Object.keys(timers).forEach(key => {
        if (timers[key] instanceof Date) {
          const remaining = Math.max(0, Math.floor((timers[key] - now) / 1000));
          newRemainingTimes[key] = remaining;
        }
      });
      setRemainingTimes(newRemainingTimes);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timers]);

  useEffect(() => {
    const handleResize = () => setIsPhoneScreen(window.innerWidth <= 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const buttonGroups = [
    [
      {
        action: 'crime',
        label: 'Petty Crimes',
        to: '/game/crime/crimes',
        icon: 'fa-skull-crossbones',
      },
      {
        action: 'advCrime',
        label: 'Advanced crimes',
        to: '/game/crime/adv-crime',
        icon: 'fa-mask',
      },
      {
        action: 'gta',
        label: 'Grand Theft Auto',
        to: '/game/crime/gta',
        icon: 'fa-car',
      },
      {
        action: 'bta',
        label: 'Boat Theft',
        to: '/game/crime/bta',
        icon: 'fa-bus',
      },
      {
        action: 'chase',
        label: 'Police Chase',
        to: '/game/crime/chase',
        icon: 'fa-running',
      },
      {
        action: 'extortion',
        label: 'Extortion',
        to: '/game/crime/extortion',
        icon: 'fa-hand-holding-usd',
      },
      {
        action: 'boxing',
        label: 'Boxing',
        to: '/game/casino/boxing',
        icon: 'fa-hand-back-fist',
      },
      {
        action: 'scratchcard',
        label: 'Scratchcards',
        to: '/game/casino/scratchcards',
        icon: 'fa-ticket-alt',
      },
    ],
  ];

  return (
    <div className="timerBox">
      {buttonGroups.map((group, index) => (
        <div key={index} className="timerButtons">
          {group.map(button => (
            <TimerButton
              key={button.action}
              {...button}
              remainingTime={remainingTimes[button.action]}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

const TimersWrapper = () => {
  const { globalState } = useGlobalState();
  const { character_uuid } = globalState;

  return (
    <AppSettings.Consumer>
      {({ appContentClass }) =>
        character_uuid ? (
          <div className={'app-timers ' + appContentClass}>
            <Timers />
          </div>
        ) : null
      }
    </AppSettings.Consumer>
  );
};

export default TimersWrapper;
