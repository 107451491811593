import React, { useState } from 'react';
import {
  useFamilyPageQuery,
  useFamilyCreateFamilyMutation,
  useFamilyListInvitesQuery,
  useFamilyRespondInviteMutation,
  useFamilyInviteMutation,
  useFamilyKickMutation,
  useFamilyLeaveMutation,
  useFamilyListBusinessesQuery,
  useFamilyBuyBusinessMutation,
  useFamilySellBusinessMutation,
  useFamilyRecruitFollowersMutation,
  useFamilyShowFollowersQuery,
  useFamilyShowBarQuery,
  useFamilyBuyDrinkMutation,
  useFamilyBuildBuildingMutation,
  useFamilyMassMessageMutation,
} from '../../api/game';

import Response from '../../shared-components/response';
import { formatNumber } from '../../shared-components/numberFormat';

const CreateFamilyTab = ({ familyName, setFamilyName, handleCreateFamily }) => (
  <div className="card mb-3">
    <div className="card-body">
      <h3 className="card-title">Create a Family</h3>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Enter family name"
          value={familyName}
          onChange={e => setFamilyName(e.target.value)}
        />
        <button className="btn btn-primary" onClick={handleCreateFamily}>
          Create Family
        </button>
      </div>
    </div>
  </div>
);

const PendingInvitesTab = ({ invites }) => {
  const [respondToInvite] = useFamilyRespondInviteMutation();
  const [responseMessage, setResponseMessage] = useState({
    message: '',
    type: '',
  });

  const handleInviteResponse = async (inviteId, accept) => {
    try {
      const response = await respondToInvite({ inviteId, accept }).unwrap();
      setResponseMessage({ message: response.message, type: response.type });
    } catch (error) {
      setResponseMessage({
        message: 'Failed to respond to invite',
        type: 'error',
      });
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Pending Invites</h3>
        <Response
          message={responseMessage.message}
          type={responseMessage.type}
        />
        {invites && invites.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                <th>Family Name</th>
                <th>Invited By</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {invites.map(invite => (
                <tr key={invite.id}>
                  <td>{invite.familyName}</td>
                  <td>{invite.invitedBy}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-success me-2"
                      onClick={() => handleInviteResponse(invite.id, true)}
                    >
                      Accept
                    </button>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => handleInviteResponse(invite.id, false)}
                    >
                      Decline
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No pending invites.</p>
        )}
      </div>
    </div>
  );
};

const HomeTab = ({ familyInfo }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [leaveFamily] = useFamilyLeaveMutation();
  const [response, setResponse] = useState({ message: '', type: '' });
  const { refetch } = useFamilyPageQuery();

  if (!familyInfo) {
    return <div>Loading...</div>;
  }

  const handleLeaveFamily = async () => {
    try {
      const result = await leaveFamily().unwrap();
      setResponse({ message: result.message, type: result.type });
      if (result.type === 'success') {
        setShowConfirmModal(false);
        refetch();
      }
    } catch (error) {
      setResponse({ message: 'Failed to leave family', type: 'error' });
    }
  };

  return (
    <div className="family-home">
      <div className="card mb-4">
        <div className="card-body">
          <h2 className="card-title text-center">{familyInfo.familyName}</h2>
          <hr className="my-4" />
          <p className="card-text">{familyInfo.familyMessage}</p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Leadership</h4>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  Boss: {familyInfo.leaderName}
                </li>
                <li className="list-group-item">RHM: {familyInfo.rhmName}</li>
                <li className="list-group-item">LHM: {familyInfo.lhmName}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Information</h4>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  Total Members: {familyInfo.totalMembers}
                </li>
                <li className="list-group-item">
                  Daily Payout: £{formatNumber(familyInfo.dailyPayout)} per
                  member
                </li>
                <li className="list-group-item">
                  Total Businesses: {familyInfo.businessCount}
                </li>
                <li className="list-group-item">Tax Rate: {familyInfo.tax}%</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body text-center">
          <p>It will cost £{formatNumber(1000000)} to leave your family.</p>

          <hr className="my-3" />
          <button
            className="btn btn-danger"
            onClick={() => setShowConfirmModal(true)}
          >
            Leave Family
          </button>
        </div>
      </div>

      {showConfirmModal && (
        <div
          className="modal"
          style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Leave Family</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowConfirmModal(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to leave the family? This will cost
                  £1,000,000.
                </p>
                {response.message && (
                  <Response message={response.message} type={response.type} />
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowConfirmModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleLeaveFamily}
                >
                  Leave Family
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const BusinessTab = ({ familyInfo }) => {
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const { data: businesses, isLoading } = useFamilyListBusinessesQuery();
  const [buyBusiness] = useFamilyBuyBusinessMutation();
  const [response, setResponse] = useState({ message: '', type: '' });
  const businessesArray = businesses ? Object.values(businesses).filter(item => item.uuid) : [];

  const handleBuyBusiness = async () => {
    if (!selectedBusiness) return;
    try {
      const result = await buyBusiness({
        businessUuid: selectedBusiness,
      }).unwrap();
      setResponse({ message: result.message, type: result.type });
    } catch (error) {
      setResponse({ message: 'Failed to buy business', type: 'error' });
    }
  };

  const [sellBusiness] = useFamilySellBusinessMutation();

  const handleSellBusiness = async () => {
    if (!selectedBusiness) return;
    try {
      const result = await sellBusiness({
        businessUuid: selectedBusiness,
      }).unwrap();
      setResponse({ message: result.message, type: result.type });
    } catch (error) {
      setResponse({ message: 'Failed to sell business', type: 'error' });
    }
  };

  const [showSellModal, setShowSellModal] = useState(false);
  const [selectedBusinessDetails, setSelectedBusinessDetails] = useState(null);

  const handleSellClick = () => {
    const business = businessesArray.find(b => b.uuid === selectedBusiness);
    setSelectedBusinessDetails(business);
    setShowSellModal(true);
  };
  

  if (isLoading) return <div>Loading businesses...</div>;

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Family Businesses</h3>
        <Response message={response.message} type={response.type} />
        <table className="table">
          <thead>
            <tr>
              <th>Select</th>
              <th>Business Name</th>
              <th>Price</th>
              <th>Owned</th>
            </tr>
          </thead>
          <tbody>
          {businessesArray.map(business => (
              <tr key={business.uuid}>
                <td>
                  <input
                    type="radio"
                    name="businessSelect"
                    value={business.uuid}
                    checked={selectedBusiness === business.uuid}
                    onChange={() => setSelectedBusiness(business.uuid)}
                  />
                </td>
                <td>{business.title}</td>
                <td>£{formatNumber(business.price)}</td>

                <td>{business.owned}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          className="btn btn-primary"
          onClick={handleBuyBusiness}
          disabled={!selectedBusiness}
        >
          Buy Selected Business
        </button>
        {(familyInfo.userRole === 'leader' ||
          familyInfo.userRole === 'rhm' ||
          familyInfo.userRole === 'lhm') && (
          <button
            className="btn btn-danger ml-2"
            onClick={handleSellClick}
            disabled={!selectedBusiness}
          >
            Sell Selected Business
          </button>
        )}
        {showSellModal && selectedBusinessDetails && (
          <div
            className="modal"
            style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirm Sale</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowSellModal(false)}
                  >
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    Are you sure you want to sell{' '}
                    {selectedBusinessDetails.title}?
                  </p>
                  <p>
                    You will receive £
                    {formatNumber(selectedBusinessDetails.price / 2)} back.
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowSellModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleSellBusiness}
                  >
                    Sell Business
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const FollowersTab = ({ familyInfo }) => {
  const { data: followers } = useFamilyShowFollowersQuery();
  const [recruitFollower] = useFamilyRecruitFollowersMutation();
  const [buildBuilding] = useFamilyBuildBuildingMutation();

  const [response, setResponse] = useState({ message: '', type: '' });

  const followerTypes = ['workers', 'soldiers', 'guards'];

  const getBuildingIcon = buildingName => {
    const icons = {
      Stats: 'fa-chart-bar',
      OC: 'fa-users',
      Hospital: 'fa-hospital',
      'Bullet Factory': 'fa-bullseye',
      'Kevlar Factory': 'fa-shield-alt',
      Armory: 'fa-warehouse',
    };
    return icons[buildingName] || 'fa-building';
  };

  const handleBuildingClick = async buildingName => {
    try {
      const result = await buildBuilding(buildingName).unwrap();
      setResponse({ message: result.message, type: result.type });
    } catch (error) {
      setResponse({
        message: 'Failed to start building construction',
        type: 'error',
      });
    }
  };

  const handleRecruitFollower = async type => {
    try {
      const result = await recruitFollower({ type }).unwrap();
      setResponse({ message: result.message, type: result.type });
    } catch (error) {
      setResponse({
        message: error.data?.message || 'Failed to recruit follower',
        type: 'error',
      });
    }
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <Response message={response.message} type={response.type} />
            <div className="d-flex justify-content-between">
              {followerTypes.map(type => (
                <div
                  key={type}
                  className="position-relative"
                  style={{ width: '30%' }}
                >
                  <img
                    src={`https://placehold.co/600x400`}
                    alt={type}
                    style={{ width: '100%', cursor: 'pointer' }}
                    onDoubleClick={() => handleRecruitFollower(type)}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      background: 'rgba(0,0,0,0.7)',
                      color: 'white',
                      padding: '5px',
                      textAlign: 'center',
                    }}
                  >
                    {type.charAt(0).toUpperCase() + type.slice(1)}:{' '}
                    {followers?.[type] || 0}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {(familyInfo.userRole === 'leader' ||
        familyInfo.userRole === 'rhm' ||
        familyInfo.userRole === 'lhm') && (
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <div className="row">
                {followers?.buildings?.map((building, index) => {
                  const cost = [
                    'Stats',
                    'OC',
                    'Hospital',
                    'Item Factory',
                    'Recon Units',
                  ].includes(building.name)
                    ? 1000
                    : 2500;
                  const isUnderConstruction =
                    followers.currentlyBuilding === building.name;
                  return (
                    <div key={index} className="col-6 mb-3">
                      <button
                        className={`btn btn-block ${building.unlocked ? 'btn-success' : isUnderConstruction ? 'btn-warning' : 'btn-danger'}`}
                        onClick={() => handleBuildingClick(building.name)}
                        disabled={building.unlocked || isUnderConstruction}
                        style={{
                          width: '100%',
                          height: '60px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <span>
                          <i
                            className={`fas ${getBuildingIcon(building.name)} mr-2`}
                          ></i>
                          {building.name}
                        </span>
                        {!building.unlocked && !isUnderConstruction && (
                          <small style={{ fontSize: '0.8em' }}>
                            Build for {formatNumber(cost)} workers
                          </small>
                        )}
                        {isUnderConstruction && (
                          <small style={{ fontSize: '0.8em' }}>
                            Under Construction
                          </small>
                        )}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const FamilyBarTab = () => {
  const [selectedDrink, setSelectedDrink] = useState(null);
  const [username, setUsername] = useState('');
  const [response, setResponse] = useState({ message: '', type: '' });
  const { data: drinks, isLoading } = useFamilyShowBarQuery();
  const [sendDrink] = useFamilyBuyDrinkMutation();

  const handleSendDrink = async () => {
    if (selectedDrink && username) {
      try {
        const result = await sendDrink({
          drinkUuid: selectedDrink,
          username,
        }).unwrap();
        setResponse({ message: result.message, type: result.type });
      } catch (error) {
        setResponse({ message: 'Failed to send drink', type: 'error' });
      }
    }
  };

  if (isLoading) return <div>Loading drinks...</div>;

  // Convert the object to array if needed
  const drinksArray = drinks ? Object.values(drinks).filter(item => item.uuid) : [];

  return (
    <div className="card">
      <div className="card-body">
        <Response message={response.message} type={response.type} />
        {drinksArray.length > 0 ? (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>Drink Name</th>
                  <th>Select</th>
                </tr>
              </thead>
              <tbody>
                {drinksArray.map(drink => (
                  <tr key={drink.uuid}>
                    <td>{drink.drinkName}</td>
                    <td>
                      <input
                        type="radio"
                        name="drinkSelect"
                        value={drink.uuid}
                        checked={selectedDrink === drink.uuid}
                        onChange={() => setSelectedDrink(drink.uuid)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-3">
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Enter username"
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
              <button
                className="btn btn-primary"
                onClick={handleSendDrink}
                disabled={!selectedDrink || !username}
              >
                Send Drink
              </button>
            </div>
          </>
        ) : (
          <p>No drinks available in the family bar.</p>
        )}
      </div>
    </div>
  );
};


const FamilyTabs = ({ familyInfo, activeTab, handleTabClick }) => (
  <div className="family-tabs-container">
    <div className="tabs-wrapper">
      <ul className="nav nav-tabs flex-nowrap">
        {familyInfo.visibleTabs.map(
          tab =>
            tab.visible && (
              <li className="nav-item" key={tab.id}>
                <a
                  href={`#${tab.name.toLowerCase().replace(' ', '-')}-tab`}
                  onClick={() =>
                    handleTabClick(tab.name.toLowerCase().replace(' ', '-'))
                  }
                  className={`nav-link ${activeTab === tab.name.toLowerCase().replace(' ', '-') ? 'active' : ''}`}
                >
                  {tab.name}
                </a>
              </li>
            )
        )}
      </ul>
    </div>
    <div className="tab-content panel rounded-0 p-3 m-0">
      <div
        className={`tab-pane fade ${activeTab === 'home' ? 'active show' : ''}`}
        id="home-tab"
      >
        <HomeTab familyInfo={familyInfo} />
      </div>
      <div
        className={`tab-pane fade ${activeTab === 'business' ? 'active show' : ''}`}
        id="business-tab"
      >
        <BusinessTab familyInfo={familyInfo} />
      </div>
      <div
        className={`tab-pane fade ${activeTab === 'followers' ? 'active show' : ''}`}
        id="followers-tab"
      >
        <FollowersTab familyInfo={familyInfo} />
      </div>
      <div
        className={`tab-pane fade ${activeTab === 'family-bar' ? 'active show' : ''}`}
        id="family-bar-tab"
      >
        <FamilyBarTab />
      </div>
      <div
        className={`tab-pane fade ${activeTab === 'mass-message' ? 'active show' : ''}`}
        id="mass-message-tab"
      >
        <MassMessageTab />
      </div>
      <div
        className={`tab-pane fade ${activeTab === 'profile' ? 'active show' : ''}`}
        id="profile-tab"
      >
        <ProfileTab />
      </div>
      <div
        className={`tab-pane fade ${activeTab === 'management' ? 'active show' : ''}`}
        id="management-tab"
      >
        <ManagementTab />
      </div>
    </div>
  </div>
);

const NoFamilySection = ({
  familyName,
  setFamilyName,
  handleCreateFamily,
  invites,
  alertData,
}) => (
  <div className="container">
    <Response message={alertData.message} type={alertData.type} />
    <CreateFamilyTab
      familyName={familyName}
      setFamilyName={setFamilyName}
      handleCreateFamily={handleCreateFamily}
    />
    <PendingInvitesTab invites={invites} />
  </div>
);

const ManagementTab = () => {
  const [inviteUsername, setInviteUsername] = useState('');
  const [kickUsername, setKickUsername] = useState('');
  const [inviteResponse, setInviteResponse] = useState({
    message: '',
    type: '',
  });
  const [kickResponse, setKickResponse] = useState({ message: '', type: '' });
  const [inviteUser] = useFamilyInviteMutation();
  const [kickUser] = useFamilyKickMutation();

  const handleInvite = async () => {
    try {
      const response = await inviteUser({ username: inviteUsername }).unwrap();
      setInviteResponse({ message: response.message, type: response.type });
      setInviteUsername('');
    } catch (error) {
      setInviteResponse({ message: 'Failed to invite user', type: 'error' });
    }
  };

  const handleKick = async () => {
    try {
      const response = await kickUser({ username: kickUsername }).unwrap();
      setKickResponse({ message: response.message, type: response.type });
      setKickUsername('');
    } catch (error) {
      setKickResponse({ message: 'Failed to kick user', type: 'error' });
    }
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <h3>Invite User to Family</h3>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter username"
                value={inviteUsername}
                onChange={e => setInviteUsername(e.target.value)}
              />
              <button className="btn btn-primary" onClick={handleInvite}>
                Invite
              </button>
            </div>
            <Response
              message={inviteResponse.message}
              type={inviteResponse.type}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <h3>Kick User from Family</h3>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter username"
                value={kickUsername}
                onChange={e => setKickUsername(e.target.value)}
              />
              <button className="btn btn-danger" onClick={handleKick}>
                Kick
              </button>
            </div>
            <Response message={kickResponse.message} type={kickResponse.type} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Family = () => {
  const [familyName, setFamilyName] = useState('');
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [activeTab, setActiveTab] = useState('home');

  const {
    data: familyInfo,
    isLoading,
    refetch: refetchFamilyInfo,
  } = useFamilyPageQuery();
  const [createFamily] = useFamilyCreateFamilyMutation();
  const { data: invites, refetch: refetchInvites } = useFamilyListInvitesQuery();
  const { refetch: refetchBusinesses } = useFamilyListBusinessesQuery();
  const { refetch: refetchFollowers } = useFamilyShowFollowersQuery();
  const { refetch: refetchFamilyDrinks } = useFamilyShowBarQuery();

  const handleCreateFamily = async () => {
    try {
      const response = await createFamily({ name: familyName }).unwrap();
      setAlertData({ message: response.message, type: response.type });
      setFamilyName('');
    } catch (error) {
      setAlertData({ message: 'Failed to create family', type: 'error' });
    }
  };
  const handleTabClick = tabName => {
    setActiveTab(tabName);
    refetchFamilyInfo();

    // Refetch data based on the selected tab
    switch (tabName) {
      case 'home':
        refetchFamilyInfo();
        break;
      case 'business':
        refetchBusinesses();
        break;
      case 'followers':
        refetchFollowers();
        break;
      case 'family-bar':
        refetchFamilyDrinks();
        break;
      // Add more cases for other tabs as needed
    }
  };

  if (isLoading) return <div>Loading...</div>;

  if (familyInfo.family === 'none') {
    return (
      <NoFamilySection
        familyName={familyName}
        setFamilyName={setFamilyName}
        handleCreateFamily={handleCreateFamily}
        invites={invites}
        alertData={alertData}
      />
    );
  }

  return (
    <FamilyTabs
      familyInfo={familyInfo}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      handleTabClick={handleTabClick}
    />
  );
};

const MassMessageTab = () => {
  const [message, setMessage] = useState('');
  const [sendMassMessage] = useFamilyMassMessageMutation();
  const [response, setResponse] = useState({ message: '', type: '' });

  const handleSendMessage = async () => {
    if (!message.trim()) {
      setResponse({ message: 'Message cannot be empty', type: 'error' });
      return;
    }
  
    try {
      const result = await sendMassMessage({ message }).unwrap();
      setResponse({ message: result.message, type: result.type });
      setMessage('');
    } catch (error) {
      setResponse({ message: 'Failed to send mass message', type: 'error' });
    }
  };
  

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Mass Message</h3>
        <Response message={response.message} type={response.type} />
        <div className="form-group">
          <textarea
            className="form-control"
            rows="4"
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder="Type your message here..."
          ></textarea>
        </div>
        <button className="btn btn-primary" onClick={handleSendMessage}>
          Send Mass Message
        </button>
      </div>
    </div>
  );
};

const ProfileTab = () => <div>Content for Profile Tab</div>;

export default Family;
