import React, { useState } from 'react';
import { usePlayHeadsTailsMutation } from '../../api/game';
import Response from '../../shared-components/response';
import { formatNumber } from '../../shared-components/numberFormat';

const HeadsTails = () => {
  const [bet, setBet] = useState('');
  const [choice, setChoice] = useState(null);
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [playHeadsTails, { data: headsTailsResult }] =
    usePlayHeadsTailsMutation();

  const MAX_BET = 1000000;

  const handleSubmit = async () => {
    if (!choice) {
      setAlertData({
        message: 'Please select heads or tails',
        type: 'warning',
      });
      return;
    }
    if (bet > MAX_BET) {
      setAlertData({
        message: `Maximum bet is £${MAX_BET.toLocaleString()}`,
        type: 'warning',
      });
      return;
    }
    try {
      const result = await playHeadsTails({ bet, choice }).unwrap();
      setAlertData({ message: result.message, type: result.type });
    } catch (error) {
      console.error('Error playing heads or tails:', error);
      setAlertData({ message: 'Error playing heads or tails', type: 'danger' });
    }
  };

  return (
    <div>
      <Response message={alertData.message} type={alertData.type} />

      <div className="row">
        <div className="col-lg-4"></div>
        <div className="col-lg-4">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title text-center">Heads or Tails</h3>
              <div className="d-flex mb-3">
                <input
                  type="number"
                  className="form-control mr-2"
                  value={bet}
                  onChange={e => setBet(e.target.value)}
                  placeholder="Enter bet amount"
                />
              </div>
              <div className="d-flex justify-content-between mb-3">
                <button
                  onClick={() => setChoice('heads')}
                  className={`btn btn-lg ${choice === 'heads' ? 'btn-primary' : 'btn-outline-primary'}`}
                >
                  Heads
                </button>
                <button
                  onClick={() => setChoice('tails')}
                  className={`btn btn-lg ${choice === 'tails' ? 'btn-primary' : 'btn-outline-primary'}`}
                >
                  Tails
                </button>
              </div>
              <button
                onClick={handleSubmit}
                className="btn btn-primary btn-block"
              >
                Flip Coin
              </button>
              <hr />
              <p>Max bet: £{formatNumber(MAX_BET)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadsTails;
