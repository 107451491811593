import React, { useState } from 'react';
import {
  useBoxingDataQuery,
  useBoxingTrainingMutation,
  useBoxingFightCreateMutation,
  useBoxingFightAcceptMutation,
  useBoxingFightsListQuery
} from '../../api/game';
import Response from '../../shared-components/response';
import { useGlobalState } from '../../auth/globalState';

const TrainingOptions = ({ trainingOptions, onTrainingResponse }) => {
  const [lastTap, setLastTap] = useState(0);
  const [submitTraining] = useBoxingTrainingMutation();
  const { updateTimer } = useGlobalState();

  const handleDoubleClick = async trainingType => {
    try {
      const result = await submitTraining({ trainingType });
      if (result.data) {
        onTrainingResponse(result.data.message, result.data.type);
      }
      if (result.data.data?.time) {
        updateTimer('boxing', result.data.data.time);
      }
      
    } catch (error) {
      onTrainingResponse('An error occurred during training', 'error');
    }
  };

  const handleTouch = (trainingType, event) => {
    event.preventDefault();
    const currentTime = new Date().getTime();
    const tapLength = currentTime - lastTap;
    if (tapLength < 300 && tapLength > 0) {
      handleDoubleClick(trainingType);
    }
    setLastTap(currentTime);
  };

  const formatWaitTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  };

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Training Options</h5>
        <div className="row">
          {trainingOptions.map((option, index) => (
            <div key={index} className="col-6 mb-3">
              <div className="position-relative">
                <div className="position-absolute top-0 start-0 end-0 bg-black text-white p-1 text-center">
                  {formatWaitTime(option.waitTime)}
                </div>
                <img
                  src={'https://placehold.co/600x400'}
                  alt={option.name}
                  className="img-fluid"
                  onDoubleClick={() => handleDoubleClick(option.type)}
                  onTouchStart={(e) => handleTouch(option.type, e)}
                  style={{ cursor: 'pointer' }}
                />
                <div className="position-absolute bottom-0 start-0 end-0 bg-black text-white p-1 text-center">
                  Gain: {option.percentage}%
                </div>
              </div>
              <p className="mt-2">{option.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
const BoxingStats = ({ stats }) => {
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Your Stats</h5>
        <div className="row align-items-center mb-3">
          <div className="col-md-3">
            <h6>Boxing Level: {stats.level}</h6>
          </div>
          <div className="col-md-9">
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${stats.percentage}%` }}
                aria-valuenow={stats.percentage}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {stats.percentage}%
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <h6>Wins: {stats.wins}</h6>
          </div>
          <div className="col-md-4">
            <h6>Losses: {stats.losses}</h6>
          </div>
          <div className="col-md-4">
            <h6>Draws: {stats.draws}</h6>
          </div>
        </div>
      </div>
    </div>
  );
};
const BoxingFights = () => {
  const [createFight] = useBoxingFightCreateMutation();
  const [acceptFight] = useBoxingFightAcceptMutation();
  const { data: fights } = useBoxingFightsListQuery();
  const [betAmount, setBetAmount] = useState('');
  const [response, setResponse] = useState({ message: '', type: '' });

  const handleCreateFight = async () => {
    const result = await createFight({ bet_amount: parseInt(betAmount) });
    setResponse({ 
      message: result.data.message, 
      type: result.data.type 
    });
    if (result.data.type === 'success') {
      setBetAmount('');
    }
  };

  const handleAcceptFight = async (fightUuid) => {
    const result = await acceptFight({ fight_uuid: fightUuid });
    setResponse({ 
      message: result.data.message, 
      type: result.data.type 
    });
  };

  return (
    <div className="card">
      <div className="card-body">
        <Response message={response.message} type={response.type} />
        <h5 className="card-title">Boxing Fights</h5>
        
        {/* Create Fight Form */}
        <div className="mb-3">
          <div className="input-group">
            <input 
              type="text" 
              className="form-control"
              placeholder="Bet Amount (min £10,000)"
              value={betAmount}
              onChange={(e) => setBetAmount(e.target.value)}
            />
            <button 
              className="btn btn-primary" 
              onClick={handleCreateFight}
            >
              Create Fight
            </button>
          </div>
        </div>

        {/* Available Fights List */}
        {fights ? (
          <div className="table-responsive">
            <table className="table">
              <tbody>
                {fights?.data?.map((fight) => (
                  <tr key={fight.uuid}>
                    <td>{fight.creator}</td>
                    <td>£{new Intl.NumberFormat().format(fight.bet_amount)}</td>
                    <td>
                      <button 
                        className="btn btn-sm btn-success"
                        onClick={() => handleAcceptFight(fight.uuid)}
                      >
                        Accept Challenge
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>Loading fights...</p>
        )}
      </div>
    </div>
  );
};
const Boxing = () => {
  const { data: boxingData, isLoading, isError } = useBoxingDataQuery();
  const [response, setResponse] = useState({ message: '', type: '' });

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error loading boxing data</p>;
  if (!boxingData?.data) return null;

  const handleTrainingResponse = (message, type) => {
    setResponse({ message, type });
  };

  return (
    <div>
      <Response message={response.message} type={response.type} />
      <div className="row">
        <div className="col-md-6">
          <TrainingOptions
            trainingOptions={boxingData.data.trainingOptions}
            onTrainingResponse={handleTrainingResponse}
          />
          <br />
        </div>
        <div className="col-md-6">
          <BoxingStats stats={boxingData.data.stats} />
          <br />
          <BoxingFights />
        </div>
      </div>
    </div>
  );
};

export default Boxing;