import { useState, useEffect } from 'react';

const useIP = () => {
  const [ip, setIp] = useState();
  const [locateInfo, setLocateInfo] = useState();

  const getIp = async () => {
    try {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      setIp(data.ip);
      setLocateInfo(data);
    } catch (error) {
      console.error('Error fetching IP:', error);
    }
  };

  useEffect(() => {
    getIp();
  }, []);

  return { ip, locateInfo };
};

export default useIP;
