import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useForumListTopicsQuery,
  useForumCreateTopicMutation,
  useForumFetchTopicQuery,
  useForumPostCommentMutation,
  useForumTopicActionMutation // Add this new mutation
} from '../../api/game';

import Response from '../../shared-components/response';
import './forums.css';
import { useGlobalState } from '../../auth/globalState';
import { RoleGuard, ROLES } from '../../components/RoleGuard';






const ForumList = ({ topics, onTopicClick, handleTopicAction }) => {
  const { globalState } = useGlobalState();
const currentCharacterUuid = globalState.character_uuid;



  const topicArray = topics ? Object.values(topics).filter(item => item.uuid) : [];
  
  return (
    <div className="list-group">
      {topicArray.map(topic => (
        <div key={topic.uuid} className="list-group-item">
          <div className="d-flex justify-content-between align-items-center">
            <div 
              onClick={() => onTopicClick(topic.uuid)}
              style={{ cursor: 'pointer' }}
            >
              <h5 className={`mb-1 ${topic.sticky ? 'fw-bold' : ''}`}>{topic.title}</h5>
              <small>By {topic.author.username}</small>
            </div>
            <div className="d-flex gap-2">
              {/* Show delete button for topic creator */}
              {topic.characterUuid === currentCharacterUuid && (
                <button 
                  className="btn btn-danger btn-sm"
                  onClick={() => handleTopicAction(topic.uuid, 'delete')}
                >
                  Delete
                </button>
              )}
              
              {/* Admin/Editor controls */}
              <RoleGuard roles={[ROLES.Admin, ROLES.Editor]}>
                <div className="btn-group">
                  <button 
                    className="btn btn-warning btn-sm"
                    onClick={() => handleTopicAction(topic.uuid, 'delete')}
                  >
                    Delete
                  </button>
                  <button 
                    className={`btn btn-${topic.sticky ? 'secondary' : 'primary'} btn-sm`}
                    onClick={() => handleTopicAction(topic.uuid, topic.sticky ? 'unstick' : 'stick')}
                  >
                    {topic.sticky ? 'Unstick' : 'Stick'}
                  </button>
                  <button 
                    className={`btn btn-${topic.locked ? 'success' : 'danger'} btn-sm`}
                    onClick={() => handleTopicAction(topic.uuid, topic.locked ? 'unlock' : 'lock')}
                  >
                    {topic.locked ? 'Unlock' : 'Lock'}
                  </button>
                </div>
              </RoleGuard>
            </div>
          </div>
          <small className="text-muted">
            {new Date(topic.createdAt).toLocaleString()}
            {topic.locked && <span className="ms-2 text-danger">(Locked)</span>}
          </small>
        </div>
      ))}
    </div>
  );
};
const CreateTopicModal = ({ onClose, onSuccess, forumType }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [createTopic] = useForumCreateTopicMutation();
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await createTopic({ title, content, forumType }).unwrap();
      setAlertData({ 
        message: response.message, 
        type: response.type 
      });
      if (response.type === 'success') {
        onSuccess();
      }
    } catch (error) {
      setAlertData({
        message: error.data?.message || 'Failed to create topic',
        type: 'error',
      });
    }
  };

  return (
    <div
      className="modal"
      style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create New Topic</h5>
            <button type="button" className="close" onClick={onClose}>
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Response message={alertData.message} type={alertData.type} />
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="content">Content</label>
                <textarea
                  className="form-control"
                  id="content"
                  rows="3"
                  value={content}
                  onChange={e => setContent(e.target.value)}
                  required
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary">
                Create Topic
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

// Add this component before the Forums component

const TopicView = ({ topicId }) => {
  const { data: topic, isLoading, refetch } = useForumFetchTopicQuery(topicId);
  const [comment, setComment] = useState('');
  const [createComment] = useForumPostCommentMutation();
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  const handleCommentSubmit = async e => {
    e.preventDefault();
    try {
      const response = await createComment({ 
        topicId, 
        content: comment,
        forumType: topic.forumType 
      }).unwrap();
      setAlertData({
        message: response.message,
        type: response.type
      });
      if (response.type === 'success') {
        setComment('');
        refetch();
      }
    } catch (error) {
      setAlertData({
        message: error.data?.message || 'Failed to post comment',
        type: 'error'
      });
    }
  };

  if (isLoading) return <p>Loading topic...</p>;
  if (!topic) return <p>Topic not found</p>;

  const comments = topic.comments || [];

  return (
    <>
      <Response message={alertData.message} type={alertData.type} />
      <div>
        <div className="card mb-4">
          <div className="card-body p-4">
            <h3>{topic.title}</h3>
            <p>{topic.content}</p>
            <div className="d-flex justify-content-end">
              <small>
                Posted by {topic.author.username} on {new Date(topic.createdAt).toLocaleDateString()} at {new Date(topic.createdAt).toLocaleTimeString()}
              </small>
            </div>
          </div>
        </div>
        
        <h4>Comments ({comments.length})</h4>
        {comments.map(comment => (
          <div key={comment.uuid} className="card mb-2">
            <div className="card-body p-4">
              <p>{comment.content}</p>
              <div className="d-flex justify-content-end">
                <small>
                  Posted by {comment.author.username} on {new Date(comment.createdAt).toLocaleDateString()} at {new Date(comment.createdAt).toLocaleTimeString()}
                </small>
              </div>
            </div>
          </div>
        ))}
        {!topic.locked ? (
          <form onSubmit={handleCommentSubmit}>
            <div className="form-group">
              <label htmlFor="comment">Add a comment</label>
              <textarea
                className="form-control"
                id="comment"
                rows="2"
                value={comment}
                onChange={e => setComment(e.target.value)}
                required
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary">
              Post Comment
            </button>
          </form>
        ) : (
          <div className="alert alert-warning">
            This topic is locked. New comments are not allowed.
          </div>
        )}
      </div>
    </>
  );
};


const Forums = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [forumType, setForumType] = useState('main');
  const { data: topics, isLoading, refetch } = useForumListTopicsQuery(forumType);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedTopicId, setSelectedTopicId] = useState(null);
  const [alertData, setAlertData] = useState(null);
  const [handleForumTopicAction] = useForumTopicActionMutation();


  const { data: currentTopic } = useForumFetchTopicQuery(selectedTopicId, {
    skip: !selectedTopicId,
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const forum = params.get('forum');
    if (forum === 'main' || forum === 'family') {
      setForumType(forum);
    } else {
      navigate('?forum=main');
    }
  }, [location, navigate]);

  const handleTopicAction = async (topicId, action) => {
    try {
      const response = await handleForumTopicAction({ topicId, action }).unwrap();
      setAlertData({ 
        message: response.message, 
        type: response.type 
      });
      refetch();
    } catch (error) {
      setAlertData({
        message: error.data?.message || `Failed to ${action} topic`,
        type: 'error'
      });
    }
  };

  return (
    <div className="container mt-4">
      <div className="panel panel-inverse">
        <div className="panel-heading">
        <div className="panel-heading-btn">
            {selectedTopicId ? (
              <button
                className="btn btn-secondary"
                onClick={() => setSelectedTopicId(null)}
              >
                Back to Topics
              </button>
            ) : (
              <button
                className="btn btn-primary"
                onClick={() => setShowCreateModal(true)}
              >
                Create New Topic
              </button>
            )}
          </div>
          <h4 className="panel-title text-center">
            {selectedTopicId ? currentTopic?.title : 'Forums'}
          </h4>
        </div>
        <div className="panel-body p-0">
          <div className="table-responsive">
            {isLoading ? (
              <p>Loading topics...</p>
            ) : selectedTopicId ? (
              <TopicView topicId={selectedTopicId} />
            ) : (
              <ForumList
                topics={topics}
                onTopicClick={topicId => setSelectedTopicId(topicId)}
                handleTopicAction={handleTopicAction}
              />
            )}
          </div>
        </div>
      </div>
      {showCreateModal && (
        <CreateTopicModal
          onClose={() => setShowCreateModal(false)}
          forumType={forumType}
          onSuccess={() => {
            refetch();
            setShowCreateModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Forums;