import React, { useState } from 'react';
import {
  useAdvancedCrimesListQuery,
  useAdvancedCrimesCommitMutation,
} from '../../api/game';
import Response from '../../shared-components/response';
import { useGlobalState } from '../../auth/globalState';

const AdvancedCrimes = () => {
  const { data, isLoading, error } = useAdvancedCrimesListQuery();
  const crimes = data ? Object.values(data).filter(item => item.uuid) : [];
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [selectedCrime, setSelectedCrime] = useState(null);
  const [commitAdvancedCrime] = useAdvancedCrimesCommitMutation();
  const { updateTimer } = useGlobalState();

  const handleCrimeSelection = crimeUuid => {
    setSelectedCrime(crimeUuid);
  };

  const commitCrime = async crime => {
    try {
      const response = await commitAdvancedCrime({ uuid: crime.uuid }).unwrap();
      setAlertData({ message: response.message, type: response.type });
      if (response.time) {
        updateTimer('advCrime', response.time);
      }
    } catch (error) {
      setAlertData({ message: 'An error occurred', type: 'error' });
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="row">
    <div className="col-lg-4"></div>
    <div className="col-lg-4">
      <Response message={alertData.message} type={alertData.type} />

      <div className="card">
        <div className="card-body">
          <h3 className="card-title text-center">Advanced Crimes</h3>
          <h5 className="card-subtitle mb-2 text-muted text-center">
            Select an advanced crime to commit
          </h5>
          <div className="d-flex flex-column align-items-center">
            {crimes.map(crime => (
              <div key={crime.uuid} className="mb-3 position-relative">
                <img
                  src={`/assets/img/advancedCrimes/${crime.imageTitle}.png`}
                  alt={crime.description}
                  onClick={() => handleCrimeSelection(crime.uuid)}
                  onDoubleClick={() => commitCrime(crime)}
                  className={`clickable-image ${selectedCrime === crime.uuid ? 'border-4 border-blue-500' : ''}`}
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
                <span className="position-absolute top-0 end-0 badge bg-info">
                  {crime.minimumRank}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-4"></div>
  </div>
  );
};

export default AdvancedCrimes;
