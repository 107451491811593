import React, { useState, useEffect } from 'react';
import {
  useBlackMarketItemsListQuery,
  useBlackMarketItemBuyMutation,
  useBlackMarketItemSellMutation,
  useBlackMarketUserItemsQuery,

  useBlackMarketPropertyListQuery,
  useBlackMarketPropertyBuyMutation,
  useBlackMarketPropertySellMutation,
  useBlackMarketUserPropertiesQuery,

  useBlackMarketInsuranceListQuery,
  useBlackMarketInsuranceBuyMutation,
  useBlackMarketInsuranceSellMutation,
  useBlackMarketUserInsuranceQuery,

  useBlackMarketPointsListQuery,
  useBlackMarketPointsBuyMutation,
  useBlackMarketPointsSellMutation,
  //useBlackMarketUserPointsQuery,

  useBlackMarketLockpicksListQuery,
  useBlackMarketLockpicksBuyMutation,
  useBlackMarketLockpicksSellMutation,
  //useBlackMarketUserLockpicksQuery,
} from '../../api/game';

import Response from '../../shared-components/response';


const Items = ({ setAlertData }) => {
  const [selectedItem, setSelectedItem] = useState('');
  const [price, setPrice] = useState('');
  const { data: listings, refetch } = useBlackMarketItemsListQuery();
  const { data: userItems, refetch: refetchUserItems } = useBlackMarketUserItemsQuery();
  const [addListing] = useBlackMarketItemSellMutation();
  const [buyListing] = useBlackMarketItemBuyMutation();
  const [anonymous, setAnonymous] = useState(false);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await addListing({
        itemUuid: selectedItem,
        price: Number(price),
        anonymous: anonymous,
      }).unwrap();
      setAlertData({ 
        message: response.message || 'Item listed successfully', 
        type: response.type || 'success' 
      });
      setSelectedItem('');
      setPrice('');
      refetch();
      refetchUserItems();

    } catch (error) {
      setAlertData({ message: 'Failed to list item', type: 'danger' });
    }
  };
  

  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <h3 className="card-title">Item Listings</h3>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Price</th>
                  <th>Seller</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(listings || {})
                  .filter(key => !isNaN(key))
                  .map(key => {
                    const item = listings[key];
                    return (
                      <tr key={item.id}>
                        <td>{item.title}</td>
                        <td>${item.price}</td>
                        <td>{item.seller}</td>
                        <td>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => {
                            buyListing({ listingId: item.uuid })
                              .unwrap()
                              .then((response) => {
                                setAlertData({
                                  message: response.message || 'Purchase successful',
                                  type: response.type || 'success',
                                });
                                refetch();
                                refetchUserItems();

                              })
                              .catch(error => {
                                setAlertData({
                                  message: error.data?.message || 'Purchase failed',
                                  type: error.data?.type || 'danger',
                                });
                              });
                          }}
                        >
                          Buy
                        </button>
                          {' '}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <h3 className="card-title">List an Item</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <select
                value={selectedItem}
                onChange={e => setSelectedItem(e.target.value)}
                className="form-select"
              >
                <option value="">Select Item</option>
                {Object.keys(userItems || {})
                  .filter(key => !isNaN(key))
                  .map(key => {
                    const item = userItems[key];
                    return (
                      <option key={item.uuid} value={item.uuid}>
                        {item.title}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="mb-3">
              <input
                type="number"
                value={price}
                onChange={e => setPrice(e.target.value)}
                className="form-control"
                placeholder="Price"
                min="10000"
              />
            </div>
            <div className="mb-3">
  <div className="form-check">
    <input
      type="checkbox"
      className="form-check-input"
      id="anonymousItem"
      checked={anonymous}
      onChange={e => setAnonymous(e.target.checked)}
    />
    <label className="form-check-label" htmlFor="anonymousItem">
      List Anonymously
    </label>
  </div>
</div>
            <button type="submit" className="btn btn-primary">
              List Item
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

const Properties = ({ setAlertData }) => {
  const [selectedProperty, setSelectedProperty] = useState('');
  const [price, setPrice] = useState('');
  const { data: listings, refetch } = useBlackMarketPropertyListQuery();
  const { data: userProperties, refetch: refetchUserProperties } = useBlackMarketUserPropertiesQuery();
  const [addListing] = useBlackMarketPropertySellMutation();
  const [buyListing] = useBlackMarketPropertyBuyMutation();
  const [anonymous, setAnonymous] = useState(false);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleSubmit = async e => {
    e.preventDefault();
    const [propertyType, propertyUuid] = selectedProperty.split('|');
    try {
      const response = await addListing({
        propertyUuid: propertyUuid,
        propertyType: propertyType,
        price: Number(price),
        anonymous: anonymous,
      }).unwrap();
      
      setAlertData({
        message: response.message || 'Property listed successfully',
        type: response.type || 'success',
      });
      
      setSelectedProperty('');
      setPrice('');
      refetch();
      refetchUserProperties();
      
    } catch (error) {
      setAlertData({ 
        message: error.data?.message || 'Failed to list property', 
        type: error.data?.type || 'danger' 
      });
    }
  };


  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <h3 className="card-title">Property Listings</h3>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Property</th>
                  <th>Location</th>
                  <th>Price</th>
                  <th>Seller</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(listings || {})
                  .filter(key => !isNaN(key))
                  .map(key => {
                    const property = listings[key];
                    return (
                      <tr key={property.uuid}>
                        <td>{property.propertyType}</td>
                        <td>{property.location}</td>
                        <td>${property.price.toLocaleString()}</td>
                        <td>{property.seller}</td>
                        <td>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => {
                            buyListing({ listingId: property.uuid })
                              .unwrap()
                              .then((response) => {
                                setAlertData({
                                  message: response.message || 'Purchase successful',
                                  type: response.type || 'success',
                                });
                                refetch();
                                refetchUserProperties();

                              })
                              .catch(error => {
                                setAlertData({
                                  message: error.data?.message || 'Purchase failed',
                                  type: error.data?.type || 'danger',
                                });
                              });
                          }}
                        >
                          Buy
                        </button>
{' '}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <h3 className="card-title">List a Property</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <select
                value={selectedProperty}
                onChange={e => setSelectedProperty(e.target.value)}
                className="form-select"
              >
                <option value="">Select Property</option>
                {userProperties && Object.keys(userProperties)
                  .filter(key => !isNaN(key))
                  .map(key => {
                    const property = userProperties[key];
                    const formattedType = property.type
                      .replace('bulletFactory', 'Bullet Factory')
                      .replace('kevlarFactory', 'Kevlar Factory')
                      .replace(/^[a-z]/, letter => letter.toUpperCase());
                    return (
                      <option key={property.uuid} value={`${property.type}|${property.uuid}`}>
                        {formattedType} - {property.city}
                      </option>
                    );
                  })}

              </select>
            </div>
            <div className="mb-3">
              <input
                type="number"
                value={price}
                onChange={e => setPrice(e.target.value)}
                className="form-control"
                placeholder="Price"
                min="10000"
              />
            </div>
            <div className="mb-3">
  <div className="form-check">
    <input
      type="checkbox"
      className="form-check-input"
      id="anonymousProperty"
      checked={anonymous}
      onChange={e => setAnonymous(e.target.checked)}
    />
    <label className="form-check-label" htmlFor="anonymousProperty">
      List Anonymously
    </label>
  </div>
</div>
            <button type="submit" className="btn btn-primary">
              List Property
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

const Insurance = ({ setAlertData }) => {
  const [selectedInsurance, setSelectedInsurance] = useState('');
  const [price, setPrice] = useState('');
  const { data: listings, refetch } = useBlackMarketInsuranceListQuery();
  const { data: userPolicies, refetch: refetchUserPolicies } = useBlackMarketUserInsuranceQuery();
  const [addListing] = useBlackMarketInsuranceSellMutation();
  const [buyListing] = useBlackMarketInsuranceBuyMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await addListing({
        insuranceUuid: selectedInsurance,
        price: Number(price),
      }).unwrap();
      setAlertData({
        message: response.message || 'Insurance listed successfully',
        type: response.type || 'success',
      });
      setSelectedInsurance('');
      setPrice('');
      refetch();
      refetchUserPolicies();
    } catch (error) {
      setAlertData({ 
        message: error.data?.message || 'Failed to list insurance', 
        type: error.data?.type || 'danger' 
      });
    }
  };

  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <h3 className="card-title">Insurance Listings</h3>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Policy Type</th>
                  <th>Insured</th>
                  <th>Price</th>
                  <th>Seller</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(listings || {})
                  .filter(key => !isNaN(key))
                  .map(key => {
                    const insurance = listings[key];
                    return (
                      <tr key={insurance.uuid}>
                        <td>{insurance.policyType}</td>
                        <td>{insurance.insured}</td>
                        <td>£{insurance.price.toLocaleString()}</td>
                        <td>{insurance.seller}</td>
                        <td>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => {
                            buyListing({ listingId: insurance.uuid })
                              .unwrap()
                              .then((response) => {
                                setAlertData({
                                  message: response.message || 'Purchase successful',
                                  type: response.type || 'success',
                                });
                                refetch();
                                refetchUserPolicies();
                              })
                              .catch(error => {
                                setAlertData({
                                  message: error.data?.message || 'Purchase failed',
                                  type: error.data?.type || 'danger',
                                });
                              });
                          }}
                        >
                          Buy
                        </button>{' '}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <h3 className="card-title">List Insurance</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <select
                value={selectedInsurance}
                onChange={e => setSelectedInsurance(e.target.value)}
                className="form-select"
              >
                <option value="">Select Insurance Policy</option>
                {Object.keys(userPolicies || {})
                  .filter(key => !isNaN(key))
                  .map(key => {
                    const policy = userPolicies[key];
                    const policyNames = {
                      1: 'Low Insurance - 15%',
                      2: 'Medium Insurance - 25%',
                      3: 'High Insurance - 35%',
                      4: 'Mega Insurance - 50%',
                    };
                    return (
                      <option key={policy.uuid} value={policy.uuid}>
                        {policy.insured} - {policyNames[policy.policyType]}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="mb-3">
              <input
                type="number"
                value={price}
                onChange={e => setPrice(e.target.value)}
                className="form-control"
                placeholder="Price"
              />
            </div>
            <button type="submit" className="btn btn-primary">
              List Insurance
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
const Points = ({ setAlertData }) => {
  const [amount, setAmount] = useState('');
  const [price, setPrice] = useState('');
  const { data: listings, refetch } = useBlackMarketPointsListQuery();
  const [addListing] = useBlackMarketPointsSellMutation();
  const [buyListing] = useBlackMarketPointsBuyMutation();
  const [anonymous, setAnonymous] = useState(false);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await addListing({
        amount: Number(amount),
        price: Number(price),
        anonymous: anonymous,
      }).unwrap();
      setAlertData({
        message: response.message || 'Points listed successfully',
        type: response.type || 'success',
      });
      setAmount('');
      setPrice('');
      refetch();
    } catch (error) {
      setAlertData({ 
        message: error.data?.message || 'Failed to list points', 
        type: error.data?.type || 'danger' 
      });
    }
  };

  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <h3 className="card-title">Points Listings</h3>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Amount</th>
                  <th>Price</th>
                  <th>Seller</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(listings || {})
                  .filter(key => !isNaN(key))
                  .map(key => {
                    const points = listings[key];
                    return (
                      <tr key={points.id}>
                        <td>{points.amount}</td>
                        <td>${points.price}</td>
                        <td>{points.seller}</td>
                        <td>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              buyListing({ listingId: points.uuid })
  .unwrap()
  .then((response) => {
    setAlertData({
      message: response.message || 'Purchase successful',
      type: response.type || 'success',
    });
    refetch();
  })
  .catch(error => {
    setAlertData({
      message: error.data?.message || 'Purchase failed',
      type: error.data?.type || 'danger',
    });
  });
                            }}
                          >
                            Buy
                          </button>{' '}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <h3 className="card-title">List Points</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <input
                type="number"
                value={amount}
                onChange={e => setAmount(e.target.value)}
                className="form-control"
                placeholder="Amount of Points"
              />
            </div>
            <div className="mb-3">
              <input
                type="number"
                value={price}
                onChange={e => setPrice(e.target.value)}
                className="form-control"
                placeholder="Price"
              />
            </div>
            <div className="mb-3">
  <div className="form-check">
    <input
      type="checkbox"
      className="form-check-input"
      id="anonymousProperty"
      checked={anonymous}
      onChange={e => setAnonymous(e.target.checked)}
    />
    <label className="form-check-label" htmlFor="anonymousProperty">
      List Anonymously
    </label>
  </div>
</div>
            <button type="submit" className="btn btn-primary">
              List Points
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

const Lockpicks = ({ setAlertData }) => {
  const [amount, setAmount] = useState('');
  const [price, setPrice] = useState('');
  const { data: listings, refetch } = useBlackMarketLockpicksListQuery();
  const [addListing] = useBlackMarketLockpicksSellMutation();
  const [buyListing] = useBlackMarketLockpicksBuyMutation();
  const [anonymous, setAnonymous] = useState(false);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await addListing({
        amount: Number(amount),
        price: Number(price),
        anonymous: anonymous,
      }).unwrap();
      setAlertData({
        message: response.message || 'Lockpicks listed successfully',
        type: response.type || 'success',
      });
      setAmount('');
      setPrice('');
      refetch();
    } catch (error) {
      setAlertData({ 
        message: error.data?.message || 'Failed to list lockpicks', 
        type: error.data?.type || 'danger' 
      });
    }
  };

  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <h3 className="card-title">Lockpick Listings</h3>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Quantity</th>
                  <th>Total Price</th>
                  <th>Seller</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(listings || {})
                  .filter(key => !isNaN(key))
                  .map(key => {
                    const lockpick = listings[key];
                    return (
                      <tr key={lockpick.id}>
                        <td>{lockpick.amount}</td>
                        <td>${lockpick.price}</td>
                        <td>{lockpick.seller}</td>
                        <td>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              buyListing({ listingId: lockpick.uuid })
  .unwrap()
  .then((response) => {
    setAlertData({
      message: response.message || 'Purchase successful',
      type: response.type || 'success',
    });
    refetch();
  })
  .catch(error => {
    setAlertData({
      message: error.data?.message || 'Purchase failed',
      type: error.data?.type || 'danger',
    });
  });
                            }}
                          >
                            Buy
                          </button>{' '}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">List Lockpicks</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <input
                type="number"
                value={amount}
                onChange={e => setAmount(e.target.value)}
                className="form-control"
                placeholder="Amount"
              />
            </div>
            <div className="mb-3">
              <input
                type="number"
                value={price}
                onChange={e => setPrice(e.target.value)}
                className="form-control"
                placeholder="Price"
              />
            </div>
            <div className="mb-3">
  <div className="form-check">
    <input
      type="checkbox"
      className="form-check-input"
      id="anonymousProperty"
      checked={anonymous}
      onChange={e => setAnonymous(e.target.checked)}
    />
    <label className="form-check-label" htmlFor="anonymousProperty">
      List Anonymously
    </label>
  </div>
</div>
            <button type="submit" className="btn btn-primary">
              List Lockpicks
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

const BlackMarket = () => {
  const [activeTab, setActiveTab] = useState('items');
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  const handleTabClick = tabName => {
    setActiveTab(tabName);
    setAlertData({ message: '', type: '' });
  };

  return (
    <div>
      <Response message={alertData.message} type={alertData.type} />

      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a
            href="#items-tab"
            onClick={() => handleTabClick('items')}
            className={`nav-link ${activeTab === 'items' ? 'active' : ''}`}
          >
            <span className="d-sm-none">Items</span>
            <span className="d-sm-block d-none">Items</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#properties-tab"
            onClick={() => handleTabClick('properties')}
            className={`nav-link ${activeTab === 'properties' ? 'active' : ''}`}
          >
            <span className="d-sm-none">Properties</span>
            <span className="d-sm-block d-none">Properties</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#insurance-tab"
            onClick={() => handleTabClick('insurance')}
            className={`nav-link ${activeTab === 'insurance' ? 'active' : ''}`}
          >
            <span className="d-sm-none">Insurance</span>
            <span className="d-sm-block d-none">Insurance</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#points-tab"
            onClick={() => handleTabClick('points')}
            className={`nav-link ${activeTab === 'points' ? 'active' : ''}`}
          >
            <span className="d-sm-none">Points</span>
            <span className="d-sm-block d-none">Points</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#lockpicks-tab"
            onClick={() => handleTabClick('lockpicks')}
            className={`nav-link ${activeTab === 'lockpicks' ? 'active' : ''}`}
          >
            <span className="d-sm-none">Lockpicks</span>
            <span className="d-sm-block d-none">Lockpicks</span>
          </a>
        </li>
      </ul>
      <div className="tab-content panel rounded-0 p-3 m-0">
        <div
          className={`tab-pane fade ${activeTab === 'items' ? 'active show' : ''}`}
          id="items-tab"
        >
          <Items setAlertData={setAlertData} />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'properties' ? 'active show' : ''}`}
          id="properties-tab"
        >
          <Properties setAlertData={setAlertData} />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'insurance' ? 'active show' : ''}`}
          id="insurance-tab"
        >
          <Insurance setAlertData={setAlertData} />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'points' ? 'active show' : ''}`}
          id="points-tab"
        >
          <Points setAlertData={setAlertData} />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'lockpicks' ? 'active show' : ''}`}
          id="lockpicks-tab"
        >
          <Lockpicks setAlertData={setAlertData} />
        </div>
      </div>
    </div>
  );
};

export default BlackMarket;
