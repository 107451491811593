import React from 'react';
import { useListLocationsQuery } from '../api/game';

const LocationDropdown = ({ onLocationChange }) => {
  const { data: locations, isLoading } = useListLocationsQuery(undefined, {
    refetchOnMountOrArgChange: true
  });


  
  if (isLoading) {
    return (
      <div className="mb-3">
        <label className="form-label">Location</label>
        <select className="form-control" disabled>
          <option>Loading locations...</option>
        </select>
      </div>
    );
  }

  return (
    <div className="mb-3">
      <label className="form-label">Location</label>
      <select 
        className="form-control" 
        onChange={(e) => onLocationChange(e.target.value)}
        defaultValue=""
      >
        <option value="" disabled>Select a location</option>
        {locations?.locations?.map(location => (
          <option key={location.uuid} value={location.uuid}>
            {location.city}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LocationDropdown;
